/* eslint-disable */
// source: vivacity/config/dynniq_message_s4tc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
goog.exportSymbol('proto.vivacity.config.DynniqMessageClassGrouping', null, global);
goog.exportSymbol('proto.vivacity.config.DynniqMessageZone', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.DynniqMessageClassGrouping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.DynniqMessageClassGrouping.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.DynniqMessageClassGrouping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.DynniqMessageClassGrouping.displayName = 'proto.vivacity.config.DynniqMessageClassGrouping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.DynniqMessageZone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.DynniqMessageZone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.DynniqMessageZone.displayName = 'proto.vivacity.config.DynniqMessageZone';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.DynniqMessageClassGrouping.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.DynniqMessageClassGrouping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.DynniqMessageClassGrouping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.DynniqMessageClassGrouping.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    classGroup1List: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    classGroup2List: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    classGroup3List: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    classGroup4List: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.DynniqMessageClassGrouping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.DynniqMessageClassGrouping;
  return proto.vivacity.config.DynniqMessageClassGrouping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.DynniqMessageClassGrouping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping}
 */
proto.vivacity.config.DynniqMessageClassGrouping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClassGroup1(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClassGroup2(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClassGroup3(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClassGroup4(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.DynniqMessageClassGrouping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.DynniqMessageClassGrouping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.DynniqMessageClassGrouping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClassGroup1List();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getClassGroup2List();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getClassGroup3List();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getClassGroup4List();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated vivacity.core.ClassifyingDetectorClassTypes class_group_1 = 3;
 * @return {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.getClassGroup1List = function() {
  return /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} value
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.setClassGroup1List = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.addClassGroup1 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.clearClassGroup1List = function() {
  return this.setClassGroup1List([]);
};


/**
 * repeated vivacity.core.ClassifyingDetectorClassTypes class_group_2 = 4;
 * @return {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.getClassGroup2List = function() {
  return /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} value
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.setClassGroup2List = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.addClassGroup2 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.clearClassGroup2List = function() {
  return this.setClassGroup2List([]);
};


/**
 * repeated vivacity.core.ClassifyingDetectorClassTypes class_group_3 = 5;
 * @return {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.getClassGroup3List = function() {
  return /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} value
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.setClassGroup3List = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.addClassGroup3 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.clearClassGroup3List = function() {
  return this.setClassGroup3List([]);
};


/**
 * repeated vivacity.core.ClassifyingDetectorClassTypes class_group_4 = 6;
 * @return {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>}
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.getClassGroup4List = function() {
  return /** @type {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassifyingDetectorClassTypes>} value
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.setClassGroup4List = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.addClassGroup4 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.DynniqMessageClassGrouping} returns this
 */
proto.vivacity.config.DynniqMessageClassGrouping.prototype.clearClassGroup4List = function() {
  return this.setClassGroup4List([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.DynniqMessageZone.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.DynniqMessageZone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.DynniqMessageZone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.DynniqMessageZone.toObject = function(includeInstance, msg) {
  var f, obj = {
    controllerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageIndex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isDirectional: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    dynniqClassGroupingId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.DynniqMessageZone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.DynniqMessageZone;
  return proto.vivacity.config.DynniqMessageZone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.DynniqMessageZone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.DynniqMessageZone}
 */
proto.vivacity.config.DynniqMessageZone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMessageIndex(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirectional(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDynniqClassGroupingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.DynniqMessageZone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.DynniqMessageZone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.DynniqMessageZone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.DynniqMessageZone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMessageIndex();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getIsDirectional();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDynniqClassGroupingId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 controller_id = 1;
 * @return {number}
 */
proto.vivacity.config.DynniqMessageZone.prototype.getControllerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.DynniqMessageZone} returns this
 */
proto.vivacity.config.DynniqMessageZone.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 zone_id = 2;
 * @return {number}
 */
proto.vivacity.config.DynniqMessageZone.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.DynniqMessageZone} returns this
 */
proto.vivacity.config.DynniqMessageZone.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 message_index = 3;
 * @return {number}
 */
proto.vivacity.config.DynniqMessageZone.prototype.getMessageIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.DynniqMessageZone} returns this
 */
proto.vivacity.config.DynniqMessageZone.prototype.setMessageIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_directional = 4;
 * @return {boolean}
 */
proto.vivacity.config.DynniqMessageZone.prototype.getIsDirectional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.DynniqMessageZone} returns this
 */
proto.vivacity.config.DynniqMessageZone.prototype.setIsDirectional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional uint32 dynniq_class_grouping_id = 5;
 * @return {number}
 */
proto.vivacity.config.DynniqMessageZone.prototype.getDynniqClassGroupingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.DynniqMessageZone} returns this
 */
proto.vivacity.config.DynniqMessageZone.prototype.setDynniqClassGroupingId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


goog.object.extend(exports, proto.vivacity.config);
