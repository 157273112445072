import _ from "lodash";

export function averageAndScalePoints(polygon: number[], scaleX: number = 1, scaleY: number = 1): [number, number]{
    let sumX: number = 0;
    let sumY: number = 0;

    _.chunk(polygon, 2).forEach(point => {
        sumX += point[0] * scaleX;
        sumY += point[1] * scaleY;
    });

    return [2 * sumX/polygon.length, 2 * sumY/polygon.length];
}
