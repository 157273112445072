/* eslint-disable */
// source: vivacity/config/vision_program_modification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
goog.exportSymbol('proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig', null, global);
goog.exportSymbol('proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramClassCollapse', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramClassConfidenceBoost', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramFields', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramFields.OperationType', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramStatus', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramToHardware', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramToHardwarePosition', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.VisionProgramFields.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.VisionProgramFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramFields.displayName = 'proto.vivacity.config.VisionProgramFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.displayName = 'proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramClassConfidenceBoost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramClassConfidenceBoost.displayName = 'proto.vivacity.config.VisionProgramClassConfidenceBoost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramClassCollapse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramClassCollapse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramClassCollapse.displayName = 'proto.vivacity.config.VisionProgramClassCollapse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramToHardware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramToHardware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramToHardware.displayName = 'proto.vivacity.config.VisionProgramToHardware';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.displayName = 'proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.displayName = 'proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramToHardwarePosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramToHardwarePosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramToHardwarePosition.displayName = 'proto.vivacity.config.VisionProgramToHardwarePosition';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.VisionProgramFields.repeatedFields_ = [19,27,87,88,89,93];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramFields.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supermarioHttpPort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    supermarioImage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    visionProgramImage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cameraNumberIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    cameraNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    kafkaProducerEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    kafkaProducerEnable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    crossingsEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    crossingsEnable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    zonalSpeedInstantaneousEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    zonalSpeedInstantaneousEnable: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    zonalOccupancyInstantaneousEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    zonalOccupancyInstantaneousEnable: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    protoclearEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    protoclearEnable: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    protoclearFieldListList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    udpEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    udpEnable: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    udpProtodumpEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    udpProtodumpEnable: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    udpForceRedialEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    udpForceRedialEnable: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    udpForceRedialInterval: jspb.Message.getFieldWithDefault(msg, 26, 0),
    udpDestinationsList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    automaticityConfigs: jspb.Message.getFieldWithDefault(msg, 28, ""),
    extraValuesJsonMerge: jspb.Message.getFieldWithDefault(msg, 29, ""),
    extraValuesJsonUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    automaticityConfigsMerge: jspb.Message.getFieldWithDefault(msg, 31, ""),
    automaticityConfigsUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    detectionConfidenceThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    detectionConfidenceThresholdIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    classtimatorAlpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    classtimatorAlphaIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    classtimatorIncludeStopped: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    classtimatorIncludeStoppedIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    kalmanTrackerJson: jspb.Message.getFieldWithDefault(msg, 39, ""),
    nvTrackerJson: jspb.Message.getFieldWithDefault(msg, 40, ""),
    enableZonalAlerts: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    enableZonalAlertsIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    enableTurningMovement: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    enableTurningMovementIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 44, false),
    enableAnpr: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
    enableAnprIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
    enablePose3d: jspb.Message.getBooleanFieldWithDefault(msg, 47, false),
    enablePose3dIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 48, false),
    enableTraffic32: jspb.Message.getBooleanFieldWithDefault(msg, 49, false),
    enableTraffic32IsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 50, false),
    enableHalfRes: jspb.Message.getBooleanFieldWithDefault(msg, 51, false),
    enableHalfResIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
    enableUdpRateLimit: jspb.Message.getBooleanFieldWithDefault(msg, 53, false),
    enableUdpRateLimitIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 54, false),
    zonalFeaturesIncludeMissingClasses: jspb.Message.getBooleanFieldWithDefault(msg, 55, false),
    zonalFeaturesIncludeMissingClassesIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 56, false),
    zonalFeaturesExcludeExtraClasses: jspb.Message.getBooleanFieldWithDefault(msg, 57, false),
    zonalFeaturesExcludeExtraClassesIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 58, false),
    zonalFeaturesExcludeExtraZones: jspb.Message.getBooleanFieldWithDefault(msg, 59, false),
    zonalFeaturesExcludeExtraZonesIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 60, false),
    zonalFeaturesDoNotIncludeMissingZones: jspb.Message.getBooleanFieldWithDefault(msg, 61, false),
    zonalFeaturesDoNotIncludeMissingZonesIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 62, false),
    zonalFeaturesDoNotIgnorePredictedTracks: jspb.Message.getBooleanFieldWithDefault(msg, 63, false),
    zonalFeaturesDoNotIgnorePredictedTracksIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 64, false),
    enableDrawingTrackHeads: jspb.Message.getBooleanFieldWithDefault(msg, 65, false),
    enableDrawingTrackHeadsIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 66, false),
    enableDrawingTrackTails: jspb.Message.getBooleanFieldWithDefault(msg, 67, false),
    enableDrawingTrackTailsIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 68, false),
    disableDrawingMasks: jspb.Message.getBooleanFieldWithDefault(msg, 69, false),
    disableDrawingMasksIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 70, false),
    disableDrawingZones: jspb.Message.getBooleanFieldWithDefault(msg, 71, false),
    disableDrawingZonesIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 72, false),
    disableDrawingCountlines: jspb.Message.getBooleanFieldWithDefault(msg, 73, false),
    disableDrawingCountlinesIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 74, false),
    description: jspb.Message.getFieldWithDefault(msg, 75, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 76, ""),
    doNotMonitor: jspb.Message.getBooleanFieldWithDefault(msg, 77, false),
    doNotMonitorIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 78, false),
    doNotMonitorReason: jspb.Message.getFieldWithDefault(msg, 79, ""),
    alertIfUp: jspb.Message.getBooleanFieldWithDefault(msg, 80, false),
    alertIfUpIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 81, false),
    alertIfUpReason: jspb.Message.getFieldWithDefault(msg, 82, ""),
    kalmanTrackerJsonMerge: jspb.Message.getFieldWithDefault(msg, 83, ""),
    kalmanTrackerJsonUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 84, false),
    nvTrackerJsonMerge: jspb.Message.getFieldWithDefault(msg, 85, ""),
    nvTrackerJsonUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 86, false),
    classBasedDetectionConfidenceThresholdsList: jspb.Message.toObjectList(msg.getClassBasedDetectionConfidenceThresholdsList(),
    proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.toObject, includeInstance),
    classConfidenceBoostList: jspb.Message.toObjectList(msg.getClassConfidenceBoostList(),
    proto.vivacity.config.VisionProgramClassConfidenceBoost.toObject, includeInstance),
    classCollapseList: jspb.Message.toObjectList(msg.getClassCollapseList(),
    proto.vivacity.config.VisionProgramClassCollapse.toObject, includeInstance),
    vpWindowedFeaturesEnable: jspb.Message.getBooleanFieldWithDefault(msg, 90, false),
    vpWindowedFeaturesEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 91, false),
    vpWindowedFeaturesTickIntervalMilliseconds: jspb.Message.getFieldWithDefault(msg, 92, 0),
    vpWindowedFeaturesProtoclearFieldListList: (f = jspb.Message.getRepeatedField(msg, 93)) == null ? undefined : f,
    udpDtfDirectionalOccupancySwap: jspb.Message.getBooleanFieldWithDefault(msg, 94, false),
    udpDtfDirectionalOccupancySwapIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 95, false),
    primaryModelId: jspb.Message.getFieldWithDefault(msg, 96, 0),
    primaryPlateModelId: jspb.Message.getFieldWithDefault(msg, 97, 0),
    secondary3dPoseModelId: jspb.Message.getFieldWithDefault(msg, 98, 0),
    secondaryOcrDetectorModelId: jspb.Message.getFieldWithDefault(msg, 99, 0),
    secondaryClassifierHumanoidModelId: jspb.Message.getFieldWithDefault(msg, 100, 0),
    primaryModelResolution: jspb.Message.getFieldWithDefault(msg, 101, ""),
    doNotCollapse: jspb.Message.getBooleanFieldWithDefault(msg, 102, false),
    doNotCollapseIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 103, false),
    presetId: jspb.Message.getFieldWithDefault(msg, 104, 0),
    presetVersion: jspb.Message.getFieldWithDefault(msg, 105, 0),
    deprecatedEscootersEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 106, false),
    deprecatedEscootersEnabledIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 107, false),
    enableTesting: jspb.Message.getBooleanFieldWithDefault(msg, 108, false),
    enableTestingIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 109, false),
    s4tcDynniqControllerPort: jspb.Message.getFieldWithDefault(msg, 110, 0),
    s4tcDynniqControllerPortIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 111, false),
    hardwarePositionId: jspb.Message.getFieldWithDefault(msg, 112, 0),
    status: jspb.Message.getFieldWithDefault(msg, 113, 0),
    nvtrackerV6Json: jspb.Message.getFieldWithDefault(msg, 114, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.VisionProgramFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramFields;
  return proto.vivacity.config.VisionProgramFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.VisionProgramFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.VisionProgramFields.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSupermarioHttpPort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioImage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVisionProgramImage(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCameraNumberIsPopulated(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCameraNumber(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKafkaProducerEnableIsPopulated(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKafkaProducerEnable(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCrossingsEnableIsPopulated(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCrossingsEnable(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalSpeedInstantaneousEnableIsPopulated(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalSpeedInstantaneousEnable(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalOccupancyInstantaneousEnableIsPopulated(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalOccupancyInstantaneousEnable(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProtoclearEnableIsPopulated(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProtoclearEnable(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoclearFieldList(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpEnableIsPopulated(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpEnable(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpProtodumpEnableIsPopulated(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpProtodumpEnable(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpForceRedialEnableIsPopulated(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpForceRedialEnable(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUdpForceRedialInterval(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.addUdpDestinations(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomaticityConfigs(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJsonMerge(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtraValuesJsonUnsafeMerge(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomaticityConfigsMerge(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticityConfigsUnsafeMerge(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDetectionConfidenceThreshold(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDetectionConfidenceThresholdIsPopulated(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClasstimatorAlpha(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClasstimatorAlphaIsPopulated(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClasstimatorIncludeStopped(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClasstimatorIncludeStoppedIsPopulated(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setKalmanTrackerJson(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvTrackerJson(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableZonalAlerts(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableZonalAlertsIsPopulated(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableTurningMovement(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableTurningMovementIsPopulated(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableAnpr(value);
      break;
    case 46:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableAnprIsPopulated(value);
      break;
    case 47:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablePose3d(value);
      break;
    case 48:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablePose3dIsPopulated(value);
      break;
    case 49:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableTraffic32(value);
      break;
    case 50:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableTraffic32IsPopulated(value);
      break;
    case 51:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableHalfRes(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableHalfResIsPopulated(value);
      break;
    case 53:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableUdpRateLimit(value);
      break;
    case 54:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableUdpRateLimitIsPopulated(value);
      break;
    case 55:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesIncludeMissingClasses(value);
      break;
    case 56:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesIncludeMissingClassesIsPopulated(value);
      break;
    case 57:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesExcludeExtraClasses(value);
      break;
    case 58:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesExcludeExtraClassesIsPopulated(value);
      break;
    case 59:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesExcludeExtraZones(value);
      break;
    case 60:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesExcludeExtraZonesIsPopulated(value);
      break;
    case 61:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesDoNotIncludeMissingZones(value);
      break;
    case 62:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesDoNotIncludeMissingZonesIsPopulated(value);
      break;
    case 63:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesDoNotIgnorePredictedTracks(value);
      break;
    case 64:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalFeaturesDoNotIgnorePredictedTracksIsPopulated(value);
      break;
    case 65:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDrawingTrackHeads(value);
      break;
    case 66:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDrawingTrackHeadsIsPopulated(value);
      break;
    case 67:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDrawingTrackTails(value);
      break;
    case 68:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDrawingTrackTailsIsPopulated(value);
      break;
    case 69:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableDrawingMasks(value);
      break;
    case 70:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableDrawingMasksIsPopulated(value);
      break;
    case 71:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableDrawingZones(value);
      break;
    case 72:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableDrawingZonesIsPopulated(value);
      break;
    case 73:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableDrawingCountlines(value);
      break;
    case 74:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableDrawingCountlinesIsPopulated(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 76:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 77:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotMonitor(value);
      break;
    case 78:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotMonitorIsPopulated(value);
      break;
    case 79:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoNotMonitorReason(value);
      break;
    case 80:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertIfUp(value);
      break;
    case 81:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertIfUpIsPopulated(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertIfUpReason(value);
      break;
    case 83:
      var value = /** @type {string} */ (reader.readString());
      msg.setKalmanTrackerJsonMerge(value);
      break;
    case 84:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKalmanTrackerJsonUnsafeMerge(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvTrackerJsonMerge(value);
      break;
    case 86:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNvTrackerJsonUnsafeMerge(value);
      break;
    case 87:
      var value = new proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds;
      reader.readMessage(value,proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.deserializeBinaryFromReader);
      msg.addClassBasedDetectionConfidenceThresholds(value);
      break;
    case 88:
      var value = new proto.vivacity.config.VisionProgramClassConfidenceBoost;
      reader.readMessage(value,proto.vivacity.config.VisionProgramClassConfidenceBoost.deserializeBinaryFromReader);
      msg.addClassConfidenceBoost(value);
      break;
    case 89:
      var value = new proto.vivacity.config.VisionProgramClassCollapse;
      reader.readMessage(value,proto.vivacity.config.VisionProgramClassCollapse.deserializeBinaryFromReader);
      msg.addClassCollapse(value);
      break;
    case 90:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVpWindowedFeaturesEnable(value);
      break;
    case 91:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVpWindowedFeaturesEnableIsPopulated(value);
      break;
    case 92:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVpWindowedFeaturesTickIntervalMilliseconds(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.addVpWindowedFeaturesProtoclearFieldList(value);
      break;
    case 94:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpDtfDirectionalOccupancySwap(value);
      break;
    case 95:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpDtfDirectionalOccupancySwapIsPopulated(value);
      break;
    case 96:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrimaryModelId(value);
      break;
    case 97:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrimaryPlateModelId(value);
      break;
    case 98:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSecondary3dPoseModelId(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSecondaryOcrDetectorModelId(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSecondaryClassifierHumanoidModelId(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryModelResolution(value);
      break;
    case 102:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotCollapse(value);
      break;
    case 103:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotCollapseIsPopulated(value);
      break;
    case 104:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetId(value);
      break;
    case 105:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetVersion(value);
      break;
    case 106:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeprecatedEscootersEnabled(value);
      break;
    case 107:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeprecatedEscootersEnabledIsPopulated(value);
      break;
    case 108:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableTesting(value);
      break;
    case 109:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableTestingIsPopulated(value);
      break;
    case 110:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setS4tcDynniqControllerPort(value);
      break;
    case 111:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setS4tcDynniqControllerPortIsPopulated(value);
      break;
    case 112:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwarePositionId(value);
      break;
    case 113:
      var value = /** @type {!proto.vivacity.config.VisionProgramStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 114:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvtrackerV6Json(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupermarioHttpPort();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSupermarioImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVisionProgramImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCameraNumberIsPopulated();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCameraNumber();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getKafkaProducerEnableIsPopulated();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getKafkaProducerEnable();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCrossingsEnableIsPopulated();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCrossingsEnable();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getZonalSpeedInstantaneousEnableIsPopulated();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getZonalSpeedInstantaneousEnable();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getZonalOccupancyInstantaneousEnableIsPopulated();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getZonalOccupancyInstantaneousEnable();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getProtoclearEnableIsPopulated();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getProtoclearEnable();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getProtoclearFieldListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getUdpEnableIsPopulated();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getUdpEnable();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getUdpProtodumpEnableIsPopulated();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getUdpProtodumpEnable();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getUdpForceRedialEnableIsPopulated();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getUdpForceRedialEnable();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getUdpForceRedialInterval();
  if (f !== 0) {
    writer.writeUint32(
      26,
      f
    );
  }
  f = message.getUdpDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      27,
      f
    );
  }
  f = message.getAutomaticityConfigs();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getExtraValuesJsonMerge();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getExtraValuesJsonUnsafeMerge();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getAutomaticityConfigsMerge();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getAutomaticityConfigsUnsafeMerge();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getDetectionConfidenceThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      33,
      f
    );
  }
  f = message.getDetectionConfidenceThresholdIsPopulated();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getClasstimatorAlpha();
  if (f !== 0.0) {
    writer.writeFloat(
      35,
      f
    );
  }
  f = message.getClasstimatorAlphaIsPopulated();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getClasstimatorIncludeStopped();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getClasstimatorIncludeStoppedIsPopulated();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getKalmanTrackerJson();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getNvTrackerJson();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getEnableZonalAlerts();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getEnableZonalAlertsIsPopulated();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getEnableTurningMovement();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getEnableTurningMovementIsPopulated();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
  f = message.getEnableAnpr();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getEnableAnprIsPopulated();
  if (f) {
    writer.writeBool(
      46,
      f
    );
  }
  f = message.getEnablePose3d();
  if (f) {
    writer.writeBool(
      47,
      f
    );
  }
  f = message.getEnablePose3dIsPopulated();
  if (f) {
    writer.writeBool(
      48,
      f
    );
  }
  f = message.getEnableTraffic32();
  if (f) {
    writer.writeBool(
      49,
      f
    );
  }
  f = message.getEnableTraffic32IsPopulated();
  if (f) {
    writer.writeBool(
      50,
      f
    );
  }
  f = message.getEnableHalfRes();
  if (f) {
    writer.writeBool(
      51,
      f
    );
  }
  f = message.getEnableHalfResIsPopulated();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getEnableUdpRateLimit();
  if (f) {
    writer.writeBool(
      53,
      f
    );
  }
  f = message.getEnableUdpRateLimitIsPopulated();
  if (f) {
    writer.writeBool(
      54,
      f
    );
  }
  f = message.getZonalFeaturesIncludeMissingClasses();
  if (f) {
    writer.writeBool(
      55,
      f
    );
  }
  f = message.getZonalFeaturesIncludeMissingClassesIsPopulated();
  if (f) {
    writer.writeBool(
      56,
      f
    );
  }
  f = message.getZonalFeaturesExcludeExtraClasses();
  if (f) {
    writer.writeBool(
      57,
      f
    );
  }
  f = message.getZonalFeaturesExcludeExtraClassesIsPopulated();
  if (f) {
    writer.writeBool(
      58,
      f
    );
  }
  f = message.getZonalFeaturesExcludeExtraZones();
  if (f) {
    writer.writeBool(
      59,
      f
    );
  }
  f = message.getZonalFeaturesExcludeExtraZonesIsPopulated();
  if (f) {
    writer.writeBool(
      60,
      f
    );
  }
  f = message.getZonalFeaturesDoNotIncludeMissingZones();
  if (f) {
    writer.writeBool(
      61,
      f
    );
  }
  f = message.getZonalFeaturesDoNotIncludeMissingZonesIsPopulated();
  if (f) {
    writer.writeBool(
      62,
      f
    );
  }
  f = message.getZonalFeaturesDoNotIgnorePredictedTracks();
  if (f) {
    writer.writeBool(
      63,
      f
    );
  }
  f = message.getZonalFeaturesDoNotIgnorePredictedTracksIsPopulated();
  if (f) {
    writer.writeBool(
      64,
      f
    );
  }
  f = message.getEnableDrawingTrackHeads();
  if (f) {
    writer.writeBool(
      65,
      f
    );
  }
  f = message.getEnableDrawingTrackHeadsIsPopulated();
  if (f) {
    writer.writeBool(
      66,
      f
    );
  }
  f = message.getEnableDrawingTrackTails();
  if (f) {
    writer.writeBool(
      67,
      f
    );
  }
  f = message.getEnableDrawingTrackTailsIsPopulated();
  if (f) {
    writer.writeBool(
      68,
      f
    );
  }
  f = message.getDisableDrawingMasks();
  if (f) {
    writer.writeBool(
      69,
      f
    );
  }
  f = message.getDisableDrawingMasksIsPopulated();
  if (f) {
    writer.writeBool(
      70,
      f
    );
  }
  f = message.getDisableDrawingZones();
  if (f) {
    writer.writeBool(
      71,
      f
    );
  }
  f = message.getDisableDrawingZonesIsPopulated();
  if (f) {
    writer.writeBool(
      72,
      f
    );
  }
  f = message.getDisableDrawingCountlines();
  if (f) {
    writer.writeBool(
      73,
      f
    );
  }
  f = message.getDisableDrawingCountlinesIsPopulated();
  if (f) {
    writer.writeBool(
      74,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      76,
      f
    );
  }
  f = message.getDoNotMonitor();
  if (f) {
    writer.writeBool(
      77,
      f
    );
  }
  f = message.getDoNotMonitorIsPopulated();
  if (f) {
    writer.writeBool(
      78,
      f
    );
  }
  f = message.getDoNotMonitorReason();
  if (f.length > 0) {
    writer.writeString(
      79,
      f
    );
  }
  f = message.getAlertIfUp();
  if (f) {
    writer.writeBool(
      80,
      f
    );
  }
  f = message.getAlertIfUpIsPopulated();
  if (f) {
    writer.writeBool(
      81,
      f
    );
  }
  f = message.getAlertIfUpReason();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
  f = message.getKalmanTrackerJsonMerge();
  if (f.length > 0) {
    writer.writeString(
      83,
      f
    );
  }
  f = message.getKalmanTrackerJsonUnsafeMerge();
  if (f) {
    writer.writeBool(
      84,
      f
    );
  }
  f = message.getNvTrackerJsonMerge();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getNvTrackerJsonUnsafeMerge();
  if (f) {
    writer.writeBool(
      86,
      f
    );
  }
  f = message.getClassBasedDetectionConfidenceThresholdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      87,
      f,
      proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.serializeBinaryToWriter
    );
  }
  f = message.getClassConfidenceBoostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      88,
      f,
      proto.vivacity.config.VisionProgramClassConfidenceBoost.serializeBinaryToWriter
    );
  }
  f = message.getClassCollapseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      89,
      f,
      proto.vivacity.config.VisionProgramClassCollapse.serializeBinaryToWriter
    );
  }
  f = message.getVpWindowedFeaturesEnable();
  if (f) {
    writer.writeBool(
      90,
      f
    );
  }
  f = message.getVpWindowedFeaturesEnableIsPopulated();
  if (f) {
    writer.writeBool(
      91,
      f
    );
  }
  f = message.getVpWindowedFeaturesTickIntervalMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      92,
      f
    );
  }
  f = message.getVpWindowedFeaturesProtoclearFieldListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      93,
      f
    );
  }
  f = message.getUdpDtfDirectionalOccupancySwap();
  if (f) {
    writer.writeBool(
      94,
      f
    );
  }
  f = message.getUdpDtfDirectionalOccupancySwapIsPopulated();
  if (f) {
    writer.writeBool(
      95,
      f
    );
  }
  f = message.getPrimaryModelId();
  if (f !== 0) {
    writer.writeUint32(
      96,
      f
    );
  }
  f = message.getPrimaryPlateModelId();
  if (f !== 0) {
    writer.writeUint32(
      97,
      f
    );
  }
  f = message.getSecondary3dPoseModelId();
  if (f !== 0) {
    writer.writeUint32(
      98,
      f
    );
  }
  f = message.getSecondaryOcrDetectorModelId();
  if (f !== 0) {
    writer.writeUint32(
      99,
      f
    );
  }
  f = message.getSecondaryClassifierHumanoidModelId();
  if (f !== 0) {
    writer.writeUint32(
      100,
      f
    );
  }
  f = message.getPrimaryModelResolution();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getDoNotCollapse();
  if (f) {
    writer.writeBool(
      102,
      f
    );
  }
  f = message.getDoNotCollapseIsPopulated();
  if (f) {
    writer.writeBool(
      103,
      f
    );
  }
  f = message.getPresetId();
  if (f !== 0) {
    writer.writeUint32(
      104,
      f
    );
  }
  f = message.getPresetVersion();
  if (f !== 0) {
    writer.writeUint32(
      105,
      f
    );
  }
  f = message.getDeprecatedEscootersEnabled();
  if (f) {
    writer.writeBool(
      106,
      f
    );
  }
  f = message.getDeprecatedEscootersEnabledIsPopulated();
  if (f) {
    writer.writeBool(
      107,
      f
    );
  }
  f = message.getEnableTesting();
  if (f) {
    writer.writeBool(
      108,
      f
    );
  }
  f = message.getEnableTestingIsPopulated();
  if (f) {
    writer.writeBool(
      109,
      f
    );
  }
  f = message.getS4tcDynniqControllerPort();
  if (f !== 0) {
    writer.writeUint32(
      110,
      f
    );
  }
  f = message.getS4tcDynniqControllerPortIsPopulated();
  if (f) {
    writer.writeBool(
      111,
      f
    );
  }
  f = message.getHardwarePositionId();
  if (f !== 0) {
    writer.writeUint32(
      112,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      113,
      f
    );
  }
  f = message.getNvtrackerV6Json();
  if (f.length > 0) {
    writer.writeString(
      114,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.VisionProgramFields.OperationType = {
  UNKNOWN: 0,
  SET: 1,
  CLEAR_ZERO: 2,
  CLEAR_NON_ZERO: 3
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config.VisionProgramFields.OperationType}
 */
proto.vivacity.config.VisionProgramFields.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config.VisionProgramFields.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.VisionProgramFields.OperationType} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 vision_program_id = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string extra_values_json = 3;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 supermario_http_port = 4;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getSupermarioHttpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setSupermarioHttpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string supermario_image = 5;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getSupermarioImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setSupermarioImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string vision_program_image = 6;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVisionProgramImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVisionProgramImage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool camera_number_is_populated = 7;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCameraNumberIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCameraNumberIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional uint32 camera_number = 8;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCameraNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCameraNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool kafka_producer_enable_is_populated = 9;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getKafkaProducerEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setKafkaProducerEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool kafka_producer_enable = 10;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getKafkaProducerEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setKafkaProducerEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool crossings_enable_is_populated = 11;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCrossingsEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCrossingsEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool crossings_enable = 12;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCrossingsEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCrossingsEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool zonal_speed_instantaneous_enable_is_populated = 13;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalSpeedInstantaneousEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalSpeedInstantaneousEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool zonal_speed_instantaneous_enable = 14;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalSpeedInstantaneousEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalSpeedInstantaneousEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool zonal_occupancy_instantaneous_enable_is_populated = 15;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalOccupancyInstantaneousEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalOccupancyInstantaneousEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool zonal_occupancy_instantaneous_enable = 16;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalOccupancyInstantaneousEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalOccupancyInstantaneousEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool protoclear_enable_is_populated = 17;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getProtoclearEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setProtoclearEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool protoclear_enable = 18;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getProtoclearEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setProtoclearEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * repeated string protoclear_field_list = 19;
 * @return {!Array<string>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getProtoclearFieldListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setProtoclearFieldListList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.addProtoclearFieldList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearProtoclearFieldListList = function() {
  return this.setProtoclearFieldListList([]);
};


/**
 * optional bool udp_enable_is_populated = 20;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool udp_enable = 21;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool udp_protodump_enable_is_populated = 22;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpProtodumpEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpProtodumpEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool udp_protodump_enable = 23;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpProtodumpEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpProtodumpEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool udp_force_redial_enable_is_populated = 24;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpForceRedialEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpForceRedialEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool udp_force_redial_enable = 25;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpForceRedialEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpForceRedialEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional uint32 udp_force_redial_interval = 26;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpForceRedialInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpForceRedialInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * repeated string udp_destinations = 27;
 * @return {!Array<string>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpDestinationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpDestinationsList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.addUdpDestinations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearUdpDestinationsList = function() {
  return this.setUdpDestinationsList([]);
};


/**
 * optional string automaticity_configs = 28;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAutomaticityConfigs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAutomaticityConfigs = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string extra_values_json_merge = 29;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getExtraValuesJsonMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setExtraValuesJsonMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional bool extra_values_json_unsafe_merge = 30;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getExtraValuesJsonUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setExtraValuesJsonUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional string automaticity_configs_merge = 31;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAutomaticityConfigsMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAutomaticityConfigsMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional bool automaticity_configs_unsafe_merge = 32;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAutomaticityConfigsUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAutomaticityConfigsUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional float detection_confidence_threshold = 33;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDetectionConfidenceThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDetectionConfidenceThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional bool detection_confidence_threshold_is_populated = 34;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDetectionConfidenceThresholdIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDetectionConfidenceThresholdIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional float classtimator_alpha = 35;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getClasstimatorAlpha = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setClasstimatorAlpha = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional bool classtimator_alpha_is_populated = 36;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getClasstimatorAlphaIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setClasstimatorAlphaIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool classtimator_include_stopped = 37;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getClasstimatorIncludeStopped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setClasstimatorIncludeStopped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool classtimator_include_stopped_is_populated = 38;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getClasstimatorIncludeStoppedIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setClasstimatorIncludeStoppedIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional string kalman_tracker_json = 39;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getKalmanTrackerJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setKalmanTrackerJson = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string nv_tracker_json = 40;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getNvTrackerJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setNvTrackerJson = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional bool enable_zonal_alerts = 41;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableZonalAlerts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableZonalAlerts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool enable_zonal_alerts_is_populated = 42;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableZonalAlertsIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableZonalAlertsIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool enable_turning_movement = 43;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableTurningMovement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableTurningMovement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional bool enable_turning_movement_is_populated = 44;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableTurningMovementIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 44, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableTurningMovementIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 44, value);
};


/**
 * optional bool enable_anpr = 45;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableAnpr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableAnpr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional bool enable_anpr_is_populated = 46;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableAnprIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableAnprIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 46, value);
};


/**
 * optional bool enable_pose_3d = 47;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnablePose3d = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 47, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnablePose3d = function(value) {
  return jspb.Message.setProto3BooleanField(this, 47, value);
};


/**
 * optional bool enable_pose_3d_is_populated = 48;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnablePose3dIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 48, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnablePose3dIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 48, value);
};


/**
 * optional bool enable_traffic32 = 49;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableTraffic32 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 49, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableTraffic32 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 49, value);
};


/**
 * optional bool enable_traffic32_is_populated = 50;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableTraffic32IsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 50, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableTraffic32IsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 50, value);
};


/**
 * optional bool enable_half_res = 51;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableHalfRes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 51, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableHalfRes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 51, value);
};


/**
 * optional bool enable_half_res_is_populated = 52;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableHalfResIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableHalfResIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * optional bool enable_udp_rate_limit = 53;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableUdpRateLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 53, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableUdpRateLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 53, value);
};


/**
 * optional bool enable_udp_rate_limit_is_populated = 54;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableUdpRateLimitIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 54, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableUdpRateLimitIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 54, value);
};


/**
 * optional bool zonal_features_include_missing_classes = 55;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesIncludeMissingClasses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 55, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesIncludeMissingClasses = function(value) {
  return jspb.Message.setProto3BooleanField(this, 55, value);
};


/**
 * optional bool zonal_features_include_missing_classes_is_populated = 56;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesIncludeMissingClassesIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 56, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesIncludeMissingClassesIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 56, value);
};


/**
 * optional bool zonal_features_exclude_extra_classes = 57;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesExcludeExtraClasses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 57, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesExcludeExtraClasses = function(value) {
  return jspb.Message.setProto3BooleanField(this, 57, value);
};


/**
 * optional bool zonal_features_exclude_extra_classes_is_populated = 58;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesExcludeExtraClassesIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 58, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesExcludeExtraClassesIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 58, value);
};


/**
 * optional bool zonal_features_exclude_extra_zones = 59;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesExcludeExtraZones = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 59, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesExcludeExtraZones = function(value) {
  return jspb.Message.setProto3BooleanField(this, 59, value);
};


/**
 * optional bool zonal_features_exclude_extra_zones_is_populated = 60;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesExcludeExtraZonesIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 60, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesExcludeExtraZonesIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 60, value);
};


/**
 * optional bool zonal_features_do_not_include_missing_zones = 61;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesDoNotIncludeMissingZones = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 61, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesDoNotIncludeMissingZones = function(value) {
  return jspb.Message.setProto3BooleanField(this, 61, value);
};


/**
 * optional bool zonal_features_do_not_include_missing_zones_is_populated = 62;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesDoNotIncludeMissingZonesIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 62, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesDoNotIncludeMissingZonesIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 62, value);
};


/**
 * optional bool zonal_features_do_not_ignore_predicted_tracks = 63;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesDoNotIgnorePredictedTracks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 63, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesDoNotIgnorePredictedTracks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 63, value);
};


/**
 * optional bool zonal_features_do_not_ignore_predicted_tracks_is_populated = 64;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalFeaturesDoNotIgnorePredictedTracksIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 64, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalFeaturesDoNotIgnorePredictedTracksIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 64, value);
};


/**
 * optional bool enable_drawing_track_heads = 65;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableDrawingTrackHeads = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 65, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableDrawingTrackHeads = function(value) {
  return jspb.Message.setProto3BooleanField(this, 65, value);
};


/**
 * optional bool enable_drawing_track_heads_is_populated = 66;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableDrawingTrackHeadsIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 66, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableDrawingTrackHeadsIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 66, value);
};


/**
 * optional bool enable_drawing_track_tails = 67;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableDrawingTrackTails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 67, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableDrawingTrackTails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 67, value);
};


/**
 * optional bool enable_drawing_track_tails_is_populated = 68;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableDrawingTrackTailsIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 68, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableDrawingTrackTailsIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 68, value);
};


/**
 * optional bool disable_drawing_masks = 69;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDisableDrawingMasks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 69, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDisableDrawingMasks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 69, value);
};


/**
 * optional bool disable_drawing_masks_is_populated = 70;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDisableDrawingMasksIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 70, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDisableDrawingMasksIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 70, value);
};


/**
 * optional bool disable_drawing_zones = 71;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDisableDrawingZones = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 71, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDisableDrawingZones = function(value) {
  return jspb.Message.setProto3BooleanField(this, 71, value);
};


/**
 * optional bool disable_drawing_zones_is_populated = 72;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDisableDrawingZonesIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 72, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDisableDrawingZonesIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 72, value);
};


/**
 * optional bool disable_drawing_countlines = 73;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDisableDrawingCountlines = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 73, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDisableDrawingCountlines = function(value) {
  return jspb.Message.setProto3BooleanField(this, 73, value);
};


/**
 * optional bool disable_drawing_countlines_is_populated = 74;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDisableDrawingCountlinesIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 74, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDisableDrawingCountlinesIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 74, value);
};


/**
 * optional string description = 75;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * optional string notes = 76;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 76, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 76, value);
};


/**
 * optional bool do_not_monitor = 77;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDoNotMonitor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 77, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDoNotMonitor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 77, value);
};


/**
 * optional bool do_not_monitor_is_populated = 78;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDoNotMonitorIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 78, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDoNotMonitorIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 78, value);
};


/**
 * optional string do_not_monitor_reason = 79;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDoNotMonitorReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 79, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDoNotMonitorReason = function(value) {
  return jspb.Message.setProto3StringField(this, 79, value);
};


/**
 * optional bool alert_if_up = 80;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAlertIfUp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 80, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAlertIfUp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 80, value);
};


/**
 * optional bool alert_if_up_is_populated = 81;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAlertIfUpIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 81, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAlertIfUpIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 81, value);
};


/**
 * optional string alert_if_up_reason = 82;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAlertIfUpReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAlertIfUpReason = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};


/**
 * optional string kalman_tracker_json_merge = 83;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getKalmanTrackerJsonMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 83, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setKalmanTrackerJsonMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 83, value);
};


/**
 * optional bool kalman_tracker_json_unsafe_merge = 84;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getKalmanTrackerJsonUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 84, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setKalmanTrackerJsonUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 84, value);
};


/**
 * optional string nv_tracker_json_merge = 85;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getNvTrackerJsonMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setNvTrackerJsonMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional bool nv_tracker_json_unsafe_merge = 86;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getNvTrackerJsonUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 86, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setNvTrackerJsonUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 86, value);
};


/**
 * repeated VisionProgramClassDetectionConfidenceThresholds class_based_detection_confidence_thresholds = 87;
 * @return {!Array<!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getClassBasedDetectionConfidenceThresholdsList = function() {
  return /** @type{!Array<!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds, 87));
};


/**
 * @param {!Array<!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
*/
proto.vivacity.config.VisionProgramFields.prototype.setClassBasedDetectionConfidenceThresholdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 87, value);
};


/**
 * @param {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds}
 */
proto.vivacity.config.VisionProgramFields.prototype.addClassBasedDetectionConfidenceThresholds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 87, opt_value, proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearClassBasedDetectionConfidenceThresholdsList = function() {
  return this.setClassBasedDetectionConfidenceThresholdsList([]);
};


/**
 * repeated VisionProgramClassConfidenceBoost class_confidence_boost = 88;
 * @return {!Array<!proto.vivacity.config.VisionProgramClassConfidenceBoost>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getClassConfidenceBoostList = function() {
  return /** @type{!Array<!proto.vivacity.config.VisionProgramClassConfidenceBoost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.VisionProgramClassConfidenceBoost, 88));
};


/**
 * @param {!Array<!proto.vivacity.config.VisionProgramClassConfidenceBoost>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
*/
proto.vivacity.config.VisionProgramFields.prototype.setClassConfidenceBoostList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 88, value);
};


/**
 * @param {!proto.vivacity.config.VisionProgramClassConfidenceBoost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramClassConfidenceBoost}
 */
proto.vivacity.config.VisionProgramFields.prototype.addClassConfidenceBoost = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 88, opt_value, proto.vivacity.config.VisionProgramClassConfidenceBoost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearClassConfidenceBoostList = function() {
  return this.setClassConfidenceBoostList([]);
};


/**
 * repeated VisionProgramClassCollapse class_collapse = 89;
 * @return {!Array<!proto.vivacity.config.VisionProgramClassCollapse>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getClassCollapseList = function() {
  return /** @type{!Array<!proto.vivacity.config.VisionProgramClassCollapse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.VisionProgramClassCollapse, 89));
};


/**
 * @param {!Array<!proto.vivacity.config.VisionProgramClassCollapse>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
*/
proto.vivacity.config.VisionProgramFields.prototype.setClassCollapseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 89, value);
};


/**
 * @param {!proto.vivacity.config.VisionProgramClassCollapse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramClassCollapse}
 */
proto.vivacity.config.VisionProgramFields.prototype.addClassCollapse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 89, opt_value, proto.vivacity.config.VisionProgramClassCollapse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearClassCollapseList = function() {
  return this.setClassCollapseList([]);
};


/**
 * optional bool vp_windowed_features_enable = 90;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVpWindowedFeaturesEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 90, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVpWindowedFeaturesEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 90, value);
};


/**
 * optional bool vp_windowed_features_enable_is_populated = 91;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVpWindowedFeaturesEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 91, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVpWindowedFeaturesEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 91, value);
};


/**
 * optional int32 vp_windowed_features_tick_interval_milliseconds = 92;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVpWindowedFeaturesTickIntervalMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 92, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVpWindowedFeaturesTickIntervalMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 92, value);
};


/**
 * repeated string vp_windowed_features_protoclear_field_list = 93;
 * @return {!Array<string>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVpWindowedFeaturesProtoclearFieldListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 93));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVpWindowedFeaturesProtoclearFieldListList = function(value) {
  return jspb.Message.setField(this, 93, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.addVpWindowedFeaturesProtoclearFieldList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 93, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearVpWindowedFeaturesProtoclearFieldListList = function() {
  return this.setVpWindowedFeaturesProtoclearFieldListList([]);
};


/**
 * optional bool udp_dtf_directional_occupancy_swap = 94;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpDtfDirectionalOccupancySwap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 94, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpDtfDirectionalOccupancySwap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 94, value);
};


/**
 * optional bool udp_dtf_directional_occupancy_swap_is_populated = 95;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpDtfDirectionalOccupancySwapIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 95, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpDtfDirectionalOccupancySwapIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 95, value);
};


/**
 * optional uint32 primary_model_id = 96;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getPrimaryModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 96, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setPrimaryModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 96, value);
};


/**
 * optional uint32 primary_plate_model_id = 97;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getPrimaryPlateModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 97, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setPrimaryPlateModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 97, value);
};


/**
 * optional uint32 secondary_3d_pose_model_id = 98;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getSecondary3dPoseModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 98, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setSecondary3dPoseModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 98, value);
};


/**
 * optional uint32 secondary_ocr_detector_model_id = 99;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getSecondaryOcrDetectorModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setSecondaryOcrDetectorModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional uint32 secondary_classifier_humanoid_model_id = 100;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getSecondaryClassifierHumanoidModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setSecondaryClassifierHumanoidModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional string primary_model_resolution = 101;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getPrimaryModelResolution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setPrimaryModelResolution = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional bool do_not_collapse = 102;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDoNotCollapse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDoNotCollapse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 102, value);
};


/**
 * optional bool do_not_collapse_is_populated = 103;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDoNotCollapseIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 103, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDoNotCollapseIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 103, value);
};


/**
 * optional uint32 preset_id = 104;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getPresetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 104, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setPresetId = function(value) {
  return jspb.Message.setProto3IntField(this, 104, value);
};


/**
 * optional uint32 preset_version = 105;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getPresetVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 105, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setPresetVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 105, value);
};


/**
 * optional bool deprecated_escooters_enabled = 106;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDeprecatedEscootersEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 106, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDeprecatedEscootersEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 106, value);
};


/**
 * optional bool deprecated_escooters_enabled_is_populated = 107;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getDeprecatedEscootersEnabledIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 107, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setDeprecatedEscootersEnabledIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 107, value);
};


/**
 * optional bool enable_testing = 108;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableTesting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 108, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableTesting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 108, value);
};


/**
 * optional bool enable_testing_is_populated = 109;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getEnableTestingIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 109, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setEnableTestingIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 109, value);
};


/**
 * optional uint32 s4tc_dynniq_controller_port = 110;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getS4tcDynniqControllerPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 110, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setS4tcDynniqControllerPort = function(value) {
  return jspb.Message.setProto3IntField(this, 110, value);
};


/**
 * optional bool s4tc_dynniq_controller_port_is_populated = 111;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getS4tcDynniqControllerPortIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 111, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setS4tcDynniqControllerPortIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 111, value);
};


/**
 * optional uint32 hardware_position_id = 112;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getHardwarePositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 112, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setHardwarePositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 112, value);
};


/**
 * optional VisionProgramStatus status = 113;
 * @return {!proto.vivacity.config.VisionProgramStatus}
 */
proto.vivacity.config.VisionProgramFields.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.config.VisionProgramStatus} */ (jspb.Message.getFieldWithDefault(this, 113, 0));
};


/**
 * @param {!proto.vivacity.config.VisionProgramStatus} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 113, value);
};


/**
 * optional string nvtracker_v6_json = 114;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getNvtrackerV6Json = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 114, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setNvtrackerV6Json = function(value) {
  return jspb.Message.setProto3StringField(this, 114, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_class: jspb.Message.getFieldWithDefault(msg, 1, 0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds}
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds;
  return proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds}
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional vivacity.core.ClassifyingDetectorClassTypes class = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.prototype.getClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds} returns this
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float threshold = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds} returns this
 */
proto.vivacity.config.VisionProgramClassDetectionConfidenceThresholds.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramClassConfidenceBoost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramClassConfidenceBoost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_class: jspb.Message.getFieldWithDefault(msg, 1, 0),
    boost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramClassConfidenceBoost}
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramClassConfidenceBoost;
  return proto.vivacity.config.VisionProgramClassConfidenceBoost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramClassConfidenceBoost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramClassConfidenceBoost}
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBoost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramClassConfidenceBoost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramClassConfidenceBoost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBoost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional vivacity.core.ClassifyingDetectorClassTypes class = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.prototype.getClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.config.VisionProgramClassConfidenceBoost} returns this
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float boost = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.prototype.getBoost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramClassConfidenceBoost} returns this
 */
proto.vivacity.config.VisionProgramClassConfidenceBoost.prototype.setBoost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramClassCollapse.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramClassCollapse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramClassCollapse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramClassCollapse.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalClass: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetClass: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramClassCollapse}
 */
proto.vivacity.config.VisionProgramClassCollapse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramClassCollapse;
  return proto.vivacity.config.VisionProgramClassCollapse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramClassCollapse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramClassCollapse}
 */
proto.vivacity.config.VisionProgramClassCollapse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setOriginalClass(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTargetClass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramClassCollapse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramClassCollapse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramClassCollapse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramClassCollapse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTargetClass();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional vivacity.core.ClassifyingDetectorClassTypes original_class = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.config.VisionProgramClassCollapse.prototype.getOriginalClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.config.VisionProgramClassCollapse} returns this
 */
proto.vivacity.config.VisionProgramClassCollapse.prototype.setOriginalClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional vivacity.core.ClassifyingDetectorClassTypes target_class = 2;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.config.VisionProgramClassCollapse.prototype.getTargetClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.config.VisionProgramClassCollapse} returns this
 */
proto.vivacity.config.VisionProgramClassCollapse.prototype.setTargetClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramToHardware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramToHardware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToHardware.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.VisionProgramToHardware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramToHardware;
  return proto.vivacity.config.VisionProgramToHardware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramToHardware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.VisionProgramToHardware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramToHardware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramToHardware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToHardware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToHardware} returns this
 */
proto.vivacity.config.VisionProgramToHardware.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 hardware_id = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToHardware} returns this
 */
proto.vivacity.config.VisionProgramToHardware.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vpWindowedFeaturesWebsocketConnectionConfigId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig;
  return proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVpWindowedFeaturesWebsocketConnectionConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getVpWindowedFeaturesWebsocketConnectionConfigId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 vp_windowed_features_websocket_connection_config_id = 2;
 * @return {number}
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.prototype.getVpWindowedFeaturesWebsocketConnectionConfigId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPToVPWindowedFeaturesWebsocketConnectionConfig.prototype.setVpWindowedFeaturesWebsocketConnectionConfigId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    connectionConfigName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    useSjTlsAndJwt: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    wsServerAddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
    wsServerEndpoint: jspb.Message.getFieldWithDefault(msg, 5, ""),
    wsServerPort: jspb.Message.getFieldWithDefault(msg, 6, 0),
    reconnectionIntervalMilliseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    exitTimeoutMilliseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    leakySink: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    leakySinkBlockTimeoutMilliseconds: jspb.Message.getFieldWithDefault(msg, 10, 0),
    jwtConfigJson: jspb.Message.getFieldWithDefault(msg, 11, ""),
    tlsConfigJson: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig;
  return proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectionConfigName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSjTlsAndJwt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWsServerAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWsServerEndpoint(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWsServerPort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReconnectionIntervalMilliseconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExitTimeoutMilliseconds(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLeakySink(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeakySinkBlockTimeoutMilliseconds(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwtConfigJson(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTlsConfigJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConnectionConfigName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUseSjTlsAndJwt();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getWsServerAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWsServerEndpoint();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWsServerPort();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getReconnectionIntervalMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getExitTimeoutMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLeakySink();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLeakySinkBlockTimeoutMilliseconds();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getJwtConfigJson();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTlsConfigJson();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string connection_config_name = 2;
 * @return {string}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getConnectionConfigName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setConnectionConfigName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool use_sj_tls_and_jwt = 3;
 * @return {boolean}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getUseSjTlsAndJwt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setUseSjTlsAndJwt = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string ws_server_address = 4;
 * @return {string}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getWsServerAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setWsServerAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ws_server_endpoint = 5;
 * @return {string}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getWsServerEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setWsServerEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 ws_server_port = 6;
 * @return {number}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getWsServerPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setWsServerPort = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 reconnection_interval_milliseconds = 7;
 * @return {number}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getReconnectionIntervalMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setReconnectionIntervalMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 exit_timeout_milliseconds = 8;
 * @return {number}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getExitTimeoutMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setExitTimeoutMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool leaky_sink = 9;
 * @return {boolean}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getLeakySink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setLeakySink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 leaky_sink_block_timeout_milliseconds = 10;
 * @return {number}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getLeakySinkBlockTimeoutMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setLeakySinkBlockTimeoutMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string jwt_config_json = 11;
 * @return {string}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getJwtConfigJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setJwtConfigJson = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string tls_config_json = 12;
 * @return {string}
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.getTlsConfigJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig} returns this
 */
proto.vivacity.config.VPWindowedFeaturesWebsocketConnectionConfig.prototype.setTlsConfigJson = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramToHardwarePosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramToHardwarePosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToHardwarePosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwarePositionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    attachedHardwareId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramToHardwarePosition}
 */
proto.vivacity.config.VisionProgramToHardwarePosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramToHardwarePosition;
  return proto.vivacity.config.VisionProgramToHardwarePosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramToHardwarePosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramToHardwarePosition}
 */
proto.vivacity.config.VisionProgramToHardwarePosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwarePositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAttachedHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramToHardwarePosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramToHardwarePosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToHardwarePosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwarePositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAttachedHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 hardware_position_id = 1;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.getHardwarePositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToHardwarePosition} returns this
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.setHardwarePositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 vision_program_id = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToHardwarePosition} returns this
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 attached_hardware_id = 3;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.getAttachedHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToHardwarePosition} returns this
 */
proto.vivacity.config.VisionProgramToHardwarePosition.prototype.setAttachedHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.vivacity.config.VisionProgramStatus = {
  UNKNOWN: 0,
  QC_COMPLETED: 1,
  CONFIGURED_UNVALIDATED: 2,
  CONFIGURED_VALIDATED: 3,
  STOPPED: 4
};

goog.object.extend(exports, proto.vivacity.config);
