import {
    Badge,
    Button,
    Col, CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from "reactstrap";
import React from "react";
import {GStreamerControl, GStreamerResponse} from "../../vivacity/core/gstreamer_control_pb";
import {inject, observer} from "mobx-react";
import StoreRoot from "../../stores/StoreRoot";
import transposeMap from "../../utils/proto-enum-helper";

type GStreamerProps = {
    peerId: string
    pipelineId: string
    appState: StoreRoot
    initialPipelineDef: string
    dataChannel: RTCDataChannel | undefined
}

@inject("appState")
@observer
export default class GStreamerPipelineControl extends React.Component<GStreamerProps, { gstPipelineDef: string, gstError: GStreamerResponse | null, loop: boolean, restart: boolean }> {
    constructor(props: GStreamerProps) {
        super(props);
        this.state = {
            gstPipelineDef: props.initialPipelineDef,
            gstError: null,
            loop: false,
            restart: true,
        }
    }

    handleMessage = (e: MessageEvent) => {
        try {
            const gstErr = GStreamerResponse.deserializeBinary(e.data);
            const controlMsg = gstErr.getControlMessage();

            if (controlMsg !== undefined) {
                if (controlMsg.getPipelineId() === this.props.pipelineId && gstErr.getCode() !== GStreamerResponse.ResponseCode.OK_NO_ERROR) {
                    this.setState({
                        gstError: gstErr,
                    })
                }
            }
        } catch (err) {
            console.log("could not parse protobuf from ", e, err);
        }
    };

    componentDidMount(): void {
        if (this.props.dataChannel !== undefined) {
            this.props.dataChannel.addEventListener("message", this.handleMessage);
        } else {
            console.log("was undefined");
        }
    }

    componentWillUnmount(): void {
        if (this.props.dataChannel !== undefined) {
            this.props.dataChannel.removeEventListener("message", this.handleMessage);
        }
    }

    render() {
        let error = null;
        if (this.state.gstError !== null) {
            error = (<h5><Badge
                color={"danger"}>{transposeMap(GStreamerResponse.ResponseCode)[this.state.gstError.getCode()]} {this.state.gstError.getError()}</Badge></h5>)
        }
        return (
            <Col>
                <Form>
                    <h2>{this.props.pipelineId}</h2>
                    <FormGroup>
                        <Label>
                            GStreamerPipeline Definition
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Pipeline</InputGroupText>
                                </InputGroupAddon>
                                <Input type={"textarea"} value={this.state.gstPipelineDef} onChange={(e) => {
                                    this.setState({
                                        gstPipelineDef: e.target.value,
                                    });
                                }} style={{width: "800px", fontFamily: "monospace"}}/>
                            </InputGroup>
                        </Label>
                    </FormGroup>
                    {error}
                    <FormGroup>
                        <Button color={"success"} onClick={() => {
                            if (this.props.dataChannel !== undefined) {
                                sendCreateAndStartGStreamerPipelineMsg(this.props.pipelineId, this.state.gstPipelineDef, this.props.dataChannel, true, this.state.loop, this.state.restart);
                                this.setState({gstError: null});
                            } else {
                                alert("Could not find data channel with label 'gstreamer_control'");
                            }
                        }}
                                disabled={this.props.dataChannel === undefined}
                        >
                            Create and Start
                        </Button>
                        {" "}
                        <Button onClick={() => {
                            if (this.props.dataChannel !== undefined) {
                                sendPauseGStreamerPipelineMsg(this.props.pipelineId, this.props.dataChannel);
                                this.setState({gstError: null});

                            } else {
                                alert("Could not find data channel with label 'gstreamer_control'");
                            }
                        }}
                                disabled={this.props.dataChannel === undefined}
                        >
                            Pause
                        </Button>
                        {" "}
                        <Button onClick={() => {
                            if (this.props.dataChannel !== undefined) {
                                sendResumeGStreamerPipelineMsg(this.props.pipelineId, this.props.dataChannel);
                                this.setState({gstError: null});
                            } else {
                                alert("Could not find data channel with label 'gstreamer_control'");
                            }
                        }}
                                disabled={this.props.dataChannel === undefined}
                        >
                            Resume
                        </Button>
                        {" "}
                        <Button color={"danger"} onClick={() => {
                            if (this.props.dataChannel !== undefined) {
                                sendStopAndDestroyGStreamerPipelineMsg(this.props.pipelineId, this.props.dataChannel);
                                this.setState({gstError: null});
                            } else {
                                alert("Could not find data channel with label 'gstreamer_control'");
                            }
                        }}
                                disabled={this.props.dataChannel === undefined}
                        >
                            Stop
                        </Button>
                        {" "}
                        <CustomInput
                            type="switch"
                            id={"loop-" + this.props.peerId + this.props.pipelineId}
                            name="loopStream"
                            label="Loop on EOS"
                            inline
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        loop: !prevState.loop
                                    }
                                })
                            }}
                        />
                        {" "}
                        <CustomInput
                            type="switch"
                            id={"restart-" + this.props.peerId + this.props.pipelineId}
                            name="restartPipelineOnFailure"
                            label="Restart on failure"
                            inline
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        restart: !prevState.restart
                                    }
                                })
                            }}
                        />
                    </FormGroup>
                </Form>
            </Col>
        )
    }
}

function sendCreateAndStartGStreamerPipelineMsg(pipelineID: string, pipelineDef: string, dataChannel: RTCDataChannel, log: boolean, loop: boolean, restart: boolean) {
    const pipelineConfig = new GStreamerControl.PipelineConfig();
    pipelineConfig.setClockRate(GStreamerControl.PipelineConfig.ClockRate.VIDEO);
    pipelineConfig.setPipelineDefinition(pipelineDef);
    pipelineConfig.setLogBusEvents(log);
    pipelineConfig.setLoop(loop);
    pipelineConfig.setRestartGstreamerPipelineOnError(restart);
    const gstMsg = new GStreamerControl();
    gstMsg.setPipelineId(pipelineID);
    gstMsg.setOperation(GStreamerControl.Operation.CREATE_AND_START_PIPELINE);
    gstMsg.setConfig(pipelineConfig);
    dataChannel.send(gstMsg.serializeBinary());
}

function sendPauseGStreamerPipelineMsg(pipelineID: string, dataChannel: RTCDataChannel) {
    const gstMsg = new GStreamerControl();
    gstMsg.setPipelineId(pipelineID);
    gstMsg.setOperation(GStreamerControl.Operation.PAUSE_PIPELINE);
    dataChannel.send(gstMsg.serializeBinary());
}

function sendResumeGStreamerPipelineMsg(pipelineID: string, dataChannel: RTCDataChannel) {
    const gstMsg = new GStreamerControl();
    gstMsg.setPipelineId(pipelineID);
    gstMsg.setOperation(GStreamerControl.Operation.RESUME_PIPELINE);
    dataChannel.send(gstMsg.serializeBinary());
}

function sendStopAndDestroyGStreamerPipelineMsg(pipelineID: string, dataChannel: RTCDataChannel) {
    const gstMsg = new GStreamerControl();
    gstMsg.setPipelineId(pipelineID);
    gstMsg.setOperation(GStreamerControl.Operation.STOP_AND_DESTROY_PIPELINE);
    dataChannel.send(gstMsg.serializeBinary());
}