/* eslint-disable */
// source: vivacity/config/entity_templates.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_config_file_templates_pb = require('../../vivacity/config/file_templates_pb.js');
goog.object.extend(proto, vivacity_config_file_templates_pb);
goog.exportSymbol('proto.vivacity.config.EntityTemplate', null, global);
goog.exportSymbol('proto.vivacity.config.EntityTemplateType', null, global);
goog.exportSymbol('proto.vivacity.config.EntityTemplateType.EntityType', null, global);
goog.exportSymbol('proto.vivacity.config.EntityTemplateTypeToEntity', null, global);
goog.exportSymbol('proto.vivacity.config.RenderedEntityTemplate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityTemplateType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.EntityTemplateType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityTemplateType.displayName = 'proto.vivacity.config.EntityTemplateType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityTemplateTypeToEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.EntityTemplateTypeToEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityTemplateTypeToEntity.displayName = 'proto.vivacity.config.EntityTemplateTypeToEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.EntityTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.EntityTemplate.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.EntityTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.EntityTemplate.displayName = 'proto.vivacity.config.EntityTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.RenderedEntityTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.RenderedEntityTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.RenderedEntityTemplate.displayName = 'proto.vivacity.config.RenderedEntityTemplate';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityTemplateType.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityTemplateType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityTemplateType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityTemplateType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.EntityTemplateType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityTemplateType;
  return proto.vivacity.config.EntityTemplateType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityTemplateType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityTemplateType}
 */
proto.vivacity.config.EntityTemplateType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (reader.readEnum());
      msg.setEntityType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityTemplateType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityTemplateType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityTemplateType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityTemplateType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.EntityTemplateType.EntityType = {
  UNKNOWN: 0,
  DETOXICITY: 1,
  VELOCITY: 2,
  CONTROLLER: 3,
  AGENT: 4,
  REGION: 5
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.EntityTemplateType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityTemplateType} returns this
 */
proto.vivacity.config.EntityTemplateType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vivacity.config.EntityTemplateType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityTemplateType} returns this
 */
proto.vivacity.config.EntityTemplateType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EntityType entity_type = 3;
 * @return {!proto.vivacity.config.EntityTemplateType.EntityType}
 */
proto.vivacity.config.EntityTemplateType.prototype.getEntityType = function() {
  return /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.config.EntityTemplateType.EntityType} value
 * @return {!proto.vivacity.config.EntityTemplateType} returns this
 */
proto.vivacity.config.EntityTemplateType.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.vivacity.config.EntityTemplateType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityTemplateType} returns this
 */
proto.vivacity.config.EntityTemplateType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityTemplateTypeToEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityTemplateTypeToEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityTemplateTypeToEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityTemplateTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deployedEntityTemplateOrdinal: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityTemplateTypeToEntity;
  return proto.vivacity.config.EntityTemplateTypeToEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityTemplateTypeToEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityTemplateTypeToEntity}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityTemplateTypeId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (reader.readEnum());
      msg.setEntityType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeployedEntityTemplateOrdinal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityTemplateTypeToEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityTemplateTypeToEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityTemplateTypeToEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityTemplateTypeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDeployedEntityTemplateOrdinal();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 entity_template_type_id = 1;
 * @return {number}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.getEntityTemplateTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityTemplateTypeToEntity} returns this
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.setEntityTemplateTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EntityTemplateType.EntityType entity_type = 2;
 * @return {!proto.vivacity.config.EntityTemplateType.EntityType}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.getEntityType = function() {
  return /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.EntityTemplateType.EntityType} value
 * @return {!proto.vivacity.config.EntityTemplateTypeToEntity} returns this
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 entity_id = 3;
 * @return {number}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityTemplateTypeToEntity} returns this
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 deployed_entity_template_ordinal = 4;
 * @return {number}
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.getDeployedEntityTemplateOrdinal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityTemplateTypeToEntity} returns this
 */
proto.vivacity.config.EntityTemplateTypeToEntity.prototype.setDeployedEntityTemplateOrdinal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.EntityTemplate.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.EntityTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.EntityTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.EntityTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityTemplateTypeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entityTemplateOrdinal: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    query: jspb.Message.getFieldWithDefault(msg, 5, ""),
    childQueriesList: jspb.Message.toObjectList(msg.getChildQueriesList(),
    vivacity_config_file_templates_pb.FileTemplateChildQuery.toObject, includeInstance),
    template: jspb.Message.getFieldWithDefault(msg, 7, ""),
    leftDelimiter: jspb.Message.getFieldWithDefault(msg, 8, ""),
    rightDelimiter: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.EntityTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.EntityTemplate;
  return proto.vivacity.config.EntityTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.EntityTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.EntityTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityTemplateTypeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityTemplateOrdinal(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 6:
      var value = new vivacity_config_file_templates_pb.FileTemplateChildQuery;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplateChildQuery.deserializeBinaryFromReader);
      msg.addChildQueries(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftDelimiter(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightDelimiter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.EntityTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.EntityTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.EntityTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.EntityTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityTemplateTypeId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEntityTemplateOrdinal();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChildQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      vivacity_config_file_templates_pb.FileTemplateChildQuery.serializeBinaryToWriter
    );
  }
  f = message.getTemplate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLeftDelimiter();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRightDelimiter();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.EntityTemplate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 entity_template_type_id = 2;
 * @return {number}
 */
proto.vivacity.config.EntityTemplate.prototype.getEntityTemplateTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setEntityTemplateTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 entity_template_ordinal = 3;
 * @return {number}
 */
proto.vivacity.config.EntityTemplate.prototype.getEntityTemplateOrdinal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setEntityTemplateOrdinal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.vivacity.config.EntityTemplate.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.vivacity.config.EntityTemplate.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated FileTemplateChildQuery child_queries = 6;
 * @return {!Array<!proto.vivacity.config.FileTemplateChildQuery>}
 */
proto.vivacity.config.EntityTemplate.prototype.getChildQueriesList = function() {
  return /** @type{!Array<!proto.vivacity.config.FileTemplateChildQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_config_file_templates_pb.FileTemplateChildQuery, 6));
};


/**
 * @param {!Array<!proto.vivacity.config.FileTemplateChildQuery>} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
*/
proto.vivacity.config.EntityTemplate.prototype.setChildQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.config.FileTemplateChildQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.FileTemplateChildQuery}
 */
proto.vivacity.config.EntityTemplate.prototype.addChildQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.config.FileTemplateChildQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.clearChildQueriesList = function() {
  return this.setChildQueriesList([]);
};


/**
 * optional string template = 7;
 * @return {string}
 */
proto.vivacity.config.EntityTemplate.prototype.getTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string left_delimiter = 8;
 * @return {string}
 */
proto.vivacity.config.EntityTemplate.prototype.getLeftDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setLeftDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string right_delimiter = 9;
 * @return {string}
 */
proto.vivacity.config.EntityTemplate.prototype.getRightDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.EntityTemplate} returns this
 */
proto.vivacity.config.EntityTemplate.prototype.setRightDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.RenderedEntityTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.RenderedEntityTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.RenderedEntityTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    entityTemplateId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    renderedTemplate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pb_delete: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dryRun: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    dryRunEntityTemplate: (f = msg.getDryRunEntityTemplate()) && proto.vivacity.config.EntityTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.RenderedEntityTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.RenderedEntityTemplate;
  return proto.vivacity.config.RenderedEntityTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.RenderedEntityTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.RenderedEntityTemplate}
 */
proto.vivacity.config.RenderedEntityTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (reader.readEnum());
      msg.setEntityType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityTemplateId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRenderedTemplate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelete(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDryRun(value);
      break;
    case 9:
      var value = new proto.vivacity.config.EntityTemplate;
      reader.readMessage(value,proto.vivacity.config.EntityTemplate.deserializeBinaryFromReader);
      msg.setDryRunEntityTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.RenderedEntityTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.RenderedEntityTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.RenderedEntityTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEntityTemplateId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRenderedTemplate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDelete();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDryRun();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDryRunEntityTemplate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.config.EntityTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EntityTemplateType.EntityType entity_type = 2;
 * @return {!proto.vivacity.config.EntityTemplateType.EntityType}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getEntityType = function() {
  return /** @type {!proto.vivacity.config.EntityTemplateType.EntityType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.EntityTemplateType.EntityType} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 entity_id = 3;
 * @return {number}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 entity_template_id = 4;
 * @return {number}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getEntityTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.setEntityTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string rendered_template = 5;
 * @return {string}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getRenderedTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.setRenderedTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool delete = 7;
 * @return {boolean}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.setDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool dry_run = 8;
 * @return {boolean}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getDryRun = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.setDryRun = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional EntityTemplate dry_run_entity_template = 9;
 * @return {?proto.vivacity.config.EntityTemplate}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.getDryRunEntityTemplate = function() {
  return /** @type{?proto.vivacity.config.EntityTemplate} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.EntityTemplate, 9));
};


/**
 * @param {?proto.vivacity.config.EntityTemplate|undefined} value
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
*/
proto.vivacity.config.RenderedEntityTemplate.prototype.setDryRunEntityTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.RenderedEntityTemplate} returns this
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.clearDryRunEntityTemplate = function() {
  return this.setDryRunEntityTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.RenderedEntityTemplate.prototype.hasDryRunEntityTemplate = function() {
  return jspb.Message.getField(this, 9) != null;
};


goog.object.extend(exports, proto.vivacity.config);
