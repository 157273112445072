/* eslint-disable */
// source: vivacity/config/hardware.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
var vivacity_config_vault_pb = require('../../vivacity/config/vault_pb.js');
goog.object.extend(proto, vivacity_config_vault_pb);
goog.exportSymbol('proto.vivacity.config.Fingerprint', null, global);
goog.exportSymbol('proto.vivacity.config.Fingerprint.HardwareArchitecture', null, global);
goog.exportSymbol('proto.vivacity.config.Fingerprint.TegraChipId', null, global);
goog.exportSymbol('proto.vivacity.config.GetOrCreateHardwareId', null, global);
goog.exportSymbol('proto.vivacity.config.HardwareFields', null, global);
goog.exportSymbol('proto.vivacity.config.HardwareFields.OperationType', null, global);
goog.exportSymbol('proto.vivacity.config.HardwareFields.TegraChipId', null, global);
goog.exportSymbol('proto.vivacity.config.HardwareIdCreated', null, global);
goog.exportSymbol('proto.vivacity.config.HardwareIdTokenResponse', null, global);
goog.exportSymbol('proto.vivacity.config.HardwareManufacturers', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwareFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.HardwareFields.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.HardwareFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwareFields.displayName = 'proto.vivacity.config.HardwareFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.GetOrCreateHardwareId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.GetOrCreateHardwareId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.GetOrCreateHardwareId.displayName = 'proto.vivacity.config.GetOrCreateHardwareId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.Fingerprint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.Fingerprint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.Fingerprint.displayName = 'proto.vivacity.config.Fingerprint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwareIdTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.HardwareIdTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwareIdTokenResponse.displayName = 'proto.vivacity.config.HardwareIdTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwareIdCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.HardwareIdCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwareIdCreated.displayName = 'proto.vivacity.config.HardwareIdCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwareManufacturers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.HardwareManufacturers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwareManufacturers.displayName = 'proto.vivacity.config.HardwareManufacturers';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.HardwareFields.repeatedFields_ = [6,30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwareFields.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwareFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwareFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hardwareLocation: (f = msg.getHardwareLocation()) && vivacity_core_point_pb.PointF.toObject(includeInstance, f),
    deprecatedDeviceUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lensNamesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    deprecatedProjectName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deprecatedSensorNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    devManDeviceName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    extraValuesJsonMerge: jspb.Message.getFieldWithDefault(msg, 10, ""),
    extraValuesJsonUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    tegraChipId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    eeprom: jspb.Message.getFieldWithDefault(msg, 13, ""),
    doNotMonitor: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    doNotMonitorIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    description: jspb.Message.getFieldWithDefault(msg, 17, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 18, ""),
    nvTrackerJson: jspb.Message.getFieldWithDefault(msg, 19, ""),
    supermarioImageBase: jspb.Message.getFieldWithDefault(msg, 20, ""),
    supermarioImageTag: jspb.Message.getFieldWithDefault(msg, 21, ""),
    supermarioHttpPort: jspb.Message.getFieldWithDefault(msg, 22, 0),
    doNotMonitorReason: jspb.Message.getFieldWithDefault(msg, 23, ""),
    alertIfUp: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    alertIfUpIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    alertIfUpReason: jspb.Message.getFieldWithDefault(msg, 26, ""),
    nvTrackerJsonMerge: jspb.Message.getFieldWithDefault(msg, 27, ""),
    nvTrackerJsonUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    hwmanVersion: jspb.Message.getFieldWithDefault(msg, 29, ""),
    mrpCoNumbersList: (f = jspb.Message.getRepeatedField(msg, 30)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwareFields}
 */
proto.vivacity.config.HardwareFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwareFields;
  return proto.vivacity.config.HardwareFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwareFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwareFields}
 */
proto.vivacity.config.HardwareFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.HardwareFields.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 3:
      var value = new vivacity_core_point_pb.PointF;
      reader.readMessage(value,vivacity_core_point_pb.PointF.deserializeBinaryFromReader);
      msg.setHardwareLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedDeviceUuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addLensNames(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedProjectName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeprecatedSensorNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevManDeviceName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJsonMerge(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtraValuesJsonUnsafeMerge(value);
      break;
    case 12:
      var value = /** @type {!proto.vivacity.config.HardwareFields.TegraChipId} */ (reader.readEnum());
      msg.setTegraChipId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEeprom(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotMonitor(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotMonitorIsPopulated(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvTrackerJson(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioImageBase(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioImageTag(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSupermarioHttpPort(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoNotMonitorReason(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertIfUp(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlertIfUpIsPopulated(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertIfUpReason(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvTrackerJsonMerge(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNvTrackerJsonUnsafeMerge(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwmanVersion(value);
      break;
    case 30:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMrpCoNumbers(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwareFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwareFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwareFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHardwareLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_point_pb.PointF.serializeBinaryToWriter
    );
  }
  f = message.getDeprecatedDeviceUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLensNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getDeprecatedProjectName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeprecatedSensorNumber();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getDevManDeviceName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExtraValuesJsonMerge();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExtraValuesJsonUnsafeMerge();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getTegraChipId();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getEeprom();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDoNotMonitor();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getDoNotMonitorIsPopulated();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getNvTrackerJson();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSupermarioImageBase();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSupermarioImageTag();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSupermarioHttpPort();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
  f = message.getDoNotMonitorReason();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAlertIfUp();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getAlertIfUpIsPopulated();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getAlertIfUpReason();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getNvTrackerJsonMerge();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getNvTrackerJsonUnsafeMerge();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getHwmanVersion();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getMrpCoNumbersList();
  if (f.length > 0) {
    writer.writePackedUint32(
      30,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.HardwareFields.OperationType = {
  UNKNOWN: 0,
  SET: 1,
  CLEAR_ZERO: 2,
  CLEAR_NON_ZERO: 3
};

/**
 * @enum {number}
 */
proto.vivacity.config.HardwareFields.TegraChipId = {
  UNKNOWN_TEGRA_CHIP_ID: 0,
  NOT_FOUND_TEGRA_CHIP_ID: 1,
  TX1: 2,
  TX2: 3,
  TK1: 4,
  XAVIER_NX_8GB: 5,
  XAVIER_NX_16GB: 6,
  TX2_NX: 7,
  ORIN_NX_8GB: 8,
  ORIN_NX_16GB: 9,
  ORIN_NANO_4GB: 10,
  ORIN_NANO_8GB: 11
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config.HardwareFields.OperationType}
 */
proto.vivacity.config.HardwareFields.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config.HardwareFields.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.HardwareFields.OperationType} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 hardware_id = 2;
 * @return {number}
 */
proto.vivacity.config.HardwareFields.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional vivacity.core.PointF hardware_location = 3;
 * @return {?proto.vivacity.core.PointF}
 */
proto.vivacity.config.HardwareFields.prototype.getHardwareLocation = function() {
  return /** @type{?proto.vivacity.core.PointF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.PointF, 3));
};


/**
 * @param {?proto.vivacity.core.PointF|undefined} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
*/
proto.vivacity.config.HardwareFields.prototype.setHardwareLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.clearHardwareLocation = function() {
  return this.setHardwareLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HardwareFields.prototype.hasHardwareLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string deprecated_device_uuid = 4;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getDeprecatedDeviceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDeprecatedDeviceUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string extra_values_json = 5;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string lens_names = 6;
 * @return {!Array<string>}
 */
proto.vivacity.config.HardwareFields.prototype.getLensNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setLensNamesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.addLensNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.clearLensNamesList = function() {
  return this.setLensNamesList([]);
};


/**
 * optional string deprecated_project_name = 7;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getDeprecatedProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDeprecatedProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 deprecated_sensor_number = 8;
 * @return {number}
 */
proto.vivacity.config.HardwareFields.prototype.getDeprecatedSensorNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDeprecatedSensorNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string dev_man_device_name = 9;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getDevManDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDevManDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string extra_values_json_merge = 10;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getExtraValuesJsonMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setExtraValuesJsonMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool extra_values_json_unsafe_merge = 11;
 * @return {boolean}
 */
proto.vivacity.config.HardwareFields.prototype.getExtraValuesJsonUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setExtraValuesJsonUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional TegraChipId tegra_chip_id = 12;
 * @return {!proto.vivacity.config.HardwareFields.TegraChipId}
 */
proto.vivacity.config.HardwareFields.prototype.getTegraChipId = function() {
  return /** @type {!proto.vivacity.config.HardwareFields.TegraChipId} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.vivacity.config.HardwareFields.TegraChipId} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setTegraChipId = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string eeprom = 13;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getEeprom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setEeprom = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool do_not_monitor = 14;
 * @return {boolean}
 */
proto.vivacity.config.HardwareFields.prototype.getDoNotMonitor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDoNotMonitor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool do_not_monitor_is_populated = 15;
 * @return {boolean}
 */
proto.vivacity.config.HardwareFields.prototype.getDoNotMonitorIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDoNotMonitorIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string description = 17;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string notes = 18;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string nv_tracker_json = 19;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getNvTrackerJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setNvTrackerJson = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string supermario_image_base = 20;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getSupermarioImageBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setSupermarioImageBase = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string supermario_image_tag = 21;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getSupermarioImageTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setSupermarioImageTag = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional uint32 supermario_http_port = 22;
 * @return {number}
 */
proto.vivacity.config.HardwareFields.prototype.getSupermarioHttpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setSupermarioHttpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string do_not_monitor_reason = 23;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getDoNotMonitorReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setDoNotMonitorReason = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool alert_if_up = 24;
 * @return {boolean}
 */
proto.vivacity.config.HardwareFields.prototype.getAlertIfUp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setAlertIfUp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool alert_if_up_is_populated = 25;
 * @return {boolean}
 */
proto.vivacity.config.HardwareFields.prototype.getAlertIfUpIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setAlertIfUpIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional string alert_if_up_reason = 26;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getAlertIfUpReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setAlertIfUpReason = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string nv_tracker_json_merge = 27;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getNvTrackerJsonMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setNvTrackerJsonMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional bool nv_tracker_json_unsafe_merge = 28;
 * @return {boolean}
 */
proto.vivacity.config.HardwareFields.prototype.getNvTrackerJsonUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setNvTrackerJsonUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional string hwman_version = 29;
 * @return {string}
 */
proto.vivacity.config.HardwareFields.prototype.getHwmanVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setHwmanVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * repeated uint32 mrp_co_numbers = 30;
 * @return {!Array<number>}
 */
proto.vivacity.config.HardwareFields.prototype.getMrpCoNumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 30));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.setMrpCoNumbersList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.addMrpCoNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.HardwareFields} returns this
 */
proto.vivacity.config.HardwareFields.prototype.clearMrpCoNumbersList = function() {
  return this.setMrpCoNumbersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.GetOrCreateHardwareId.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.GetOrCreateHardwareId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.GetOrCreateHardwareId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.GetOrCreateHardwareId.toObject = function(includeInstance, msg) {
  var f, obj = {
    fingerprint: (f = msg.getFingerprint()) && proto.vivacity.config.Fingerprint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.GetOrCreateHardwareId}
 */
proto.vivacity.config.GetOrCreateHardwareId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.GetOrCreateHardwareId;
  return proto.vivacity.config.GetOrCreateHardwareId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.GetOrCreateHardwareId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.GetOrCreateHardwareId}
 */
proto.vivacity.config.GetOrCreateHardwareId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config.Fingerprint;
      reader.readMessage(value,proto.vivacity.config.Fingerprint.deserializeBinaryFromReader);
      msg.setFingerprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.GetOrCreateHardwareId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.GetOrCreateHardwareId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.GetOrCreateHardwareId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.GetOrCreateHardwareId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFingerprint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config.Fingerprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional Fingerprint fingerprint = 1;
 * @return {?proto.vivacity.config.Fingerprint}
 */
proto.vivacity.config.GetOrCreateHardwareId.prototype.getFingerprint = function() {
  return /** @type{?proto.vivacity.config.Fingerprint} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.Fingerprint, 1));
};


/**
 * @param {?proto.vivacity.config.Fingerprint|undefined} value
 * @return {!proto.vivacity.config.GetOrCreateHardwareId} returns this
*/
proto.vivacity.config.GetOrCreateHardwareId.prototype.setFingerprint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.GetOrCreateHardwareId} returns this
 */
proto.vivacity.config.GetOrCreateHardwareId.prototype.clearFingerprint = function() {
  return this.setFingerprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.GetOrCreateHardwareId.prototype.hasFingerprint = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.Fingerprint.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.Fingerprint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.Fingerprint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Fingerprint.toObject = function(includeInstance, msg) {
  var f, obj = {
    macAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    architecture: jspb.Message.getFieldWithDefault(msg, 3, 0),
    smbiosUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deprecatedProjectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deprecatedSensorNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    flashingMachineHostname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tegraChipId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eeprom: jspb.Message.getFieldWithDefault(msg, 9, ""),
    serialFlasherVersion: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.Fingerprint}
 */
proto.vivacity.config.Fingerprint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.Fingerprint;
  return proto.vivacity.config.Fingerprint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.Fingerprint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.Fingerprint}
 */
proto.vivacity.config.Fingerprint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.config.Fingerprint.HardwareArchitecture} */ (reader.readEnum());
      msg.setArchitecture(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmbiosUuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedProjectName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeprecatedSensorNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlashingMachineHostname(value);
      break;
    case 8:
      var value = /** @type {!proto.vivacity.config.Fingerprint.TegraChipId} */ (reader.readEnum());
      msg.setTegraChipId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEeprom(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialFlasherVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.Fingerprint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.Fingerprint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.Fingerprint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Fingerprint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getArchitecture();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSmbiosUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeprecatedProjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeprecatedSensorNumber();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getFlashingMachineHostname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTegraChipId();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getEeprom();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSerialFlasherVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.Fingerprint.HardwareArchitecture = {
  UNKNOWN: 0,
  AMD64: 1,
  ARM64: 2
};

/**
 * @enum {number}
 */
proto.vivacity.config.Fingerprint.TegraChipId = {
  UNKNOWN_TEGRA_CHIP_ID: 0,
  NOT_FOUND_TEGRA_CHIP_ID: 1,
  TX1: 2,
  TX2: 3,
  TK1: 4,
  XAVIER_NX_8GB: 5,
  XAVIER_NX_16GB: 6,
  TX2_NX: 7,
  ORIN_NX_8GB: 8,
  ORIN_NX_16GB: 9,
  ORIN_NANO_4GB: 10,
  ORIN_NANO_8GB: 11
};

/**
 * optional string mac_address = 1;
 * @return {string}
 */
proto.vivacity.config.Fingerprint.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serial_number = 2;
 * @return {string}
 */
proto.vivacity.config.Fingerprint.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional HardwareArchitecture architecture = 3;
 * @return {!proto.vivacity.config.Fingerprint.HardwareArchitecture}
 */
proto.vivacity.config.Fingerprint.prototype.getArchitecture = function() {
  return /** @type {!proto.vivacity.config.Fingerprint.HardwareArchitecture} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.config.Fingerprint.HardwareArchitecture} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setArchitecture = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string smbios_uuid = 4;
 * @return {string}
 */
proto.vivacity.config.Fingerprint.prototype.getSmbiosUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setSmbiosUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string deprecated_project_name = 5;
 * @return {string}
 */
proto.vivacity.config.Fingerprint.prototype.getDeprecatedProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setDeprecatedProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 deprecated_sensor_number = 6;
 * @return {number}
 */
proto.vivacity.config.Fingerprint.prototype.getDeprecatedSensorNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setDeprecatedSensorNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string flashing_machine_hostname = 7;
 * @return {string}
 */
proto.vivacity.config.Fingerprint.prototype.getFlashingMachineHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setFlashingMachineHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TegraChipId tegra_chip_id = 8;
 * @return {!proto.vivacity.config.Fingerprint.TegraChipId}
 */
proto.vivacity.config.Fingerprint.prototype.getTegraChipId = function() {
  return /** @type {!proto.vivacity.config.Fingerprint.TegraChipId} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vivacity.config.Fingerprint.TegraChipId} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setTegraChipId = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string eeprom = 9;
 * @return {string}
 */
proto.vivacity.config.Fingerprint.prototype.getEeprom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setEeprom = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string serial_flasher_version = 10;
 * @return {string}
 */
proto.vivacity.config.Fingerprint.prototype.getSerialFlasherVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Fingerprint} returns this
 */
proto.vivacity.config.Fingerprint.prototype.setSerialFlasherVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwareIdTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwareIdTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareIdTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vaultTokenDev: (f = msg.getVaultTokenDev()) && vivacity_config_vault_pb.VaultAppRoleToken.toObject(includeInstance, f),
    vaultTokenStaging: (f = msg.getVaultTokenStaging()) && vivacity_config_vault_pb.VaultAppRoleToken.toObject(includeInstance, f),
    vaultTokenProd: (f = msg.getVaultTokenProd()) && vivacity_config_vault_pb.VaultAppRoleToken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwareIdTokenResponse}
 */
proto.vivacity.config.HardwareIdTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwareIdTokenResponse;
  return proto.vivacity.config.HardwareIdTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwareIdTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwareIdTokenResponse}
 */
proto.vivacity.config.HardwareIdTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = new vivacity_config_vault_pb.VaultAppRoleToken;
      reader.readMessage(value,vivacity_config_vault_pb.VaultAppRoleToken.deserializeBinaryFromReader);
      msg.setVaultTokenDev(value);
      break;
    case 3:
      var value = new vivacity_config_vault_pb.VaultAppRoleToken;
      reader.readMessage(value,vivacity_config_vault_pb.VaultAppRoleToken.deserializeBinaryFromReader);
      msg.setVaultTokenStaging(value);
      break;
    case 4:
      var value = new vivacity_config_vault_pb.VaultAppRoleToken;
      reader.readMessage(value,vivacity_config_vault_pb.VaultAppRoleToken.deserializeBinaryFromReader);
      msg.setVaultTokenProd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwareIdTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwareIdTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareIdTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getVaultTokenDev();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_vault_pb.VaultAppRoleToken.serializeBinaryToWriter
    );
  }
  f = message.getVaultTokenStaging();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_vault_pb.VaultAppRoleToken.serializeBinaryToWriter
    );
  }
  f = message.getVaultTokenProd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_vault_pb.VaultAppRoleToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 hardware_id = 1;
 * @return {number}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwareIdTokenResponse} returns this
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional VaultAppRoleToken vault_token_dev = 2;
 * @return {?proto.vivacity.config.VaultAppRoleToken}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.getVaultTokenDev = function() {
  return /** @type{?proto.vivacity.config.VaultAppRoleToken} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vault_pb.VaultAppRoleToken, 2));
};


/**
 * @param {?proto.vivacity.config.VaultAppRoleToken|undefined} value
 * @return {!proto.vivacity.config.HardwareIdTokenResponse} returns this
*/
proto.vivacity.config.HardwareIdTokenResponse.prototype.setVaultTokenDev = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HardwareIdTokenResponse} returns this
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.clearVaultTokenDev = function() {
  return this.setVaultTokenDev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.hasVaultTokenDev = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VaultAppRoleToken vault_token_staging = 3;
 * @return {?proto.vivacity.config.VaultAppRoleToken}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.getVaultTokenStaging = function() {
  return /** @type{?proto.vivacity.config.VaultAppRoleToken} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vault_pb.VaultAppRoleToken, 3));
};


/**
 * @param {?proto.vivacity.config.VaultAppRoleToken|undefined} value
 * @return {!proto.vivacity.config.HardwareIdTokenResponse} returns this
*/
proto.vivacity.config.HardwareIdTokenResponse.prototype.setVaultTokenStaging = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HardwareIdTokenResponse} returns this
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.clearVaultTokenStaging = function() {
  return this.setVaultTokenStaging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.hasVaultTokenStaging = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional VaultAppRoleToken vault_token_prod = 4;
 * @return {?proto.vivacity.config.VaultAppRoleToken}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.getVaultTokenProd = function() {
  return /** @type{?proto.vivacity.config.VaultAppRoleToken} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vault_pb.VaultAppRoleToken, 4));
};


/**
 * @param {?proto.vivacity.config.VaultAppRoleToken|undefined} value
 * @return {!proto.vivacity.config.HardwareIdTokenResponse} returns this
*/
proto.vivacity.config.HardwareIdTokenResponse.prototype.setVaultTokenProd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HardwareIdTokenResponse} returns this
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.clearVaultTokenProd = function() {
  return this.setVaultTokenProd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HardwareIdTokenResponse.prototype.hasVaultTokenProd = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwareIdCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwareIdCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwareIdCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareIdCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fingerprint: (f = msg.getFingerprint()) && proto.vivacity.config.Fingerprint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwareIdCreated}
 */
proto.vivacity.config.HardwareIdCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwareIdCreated;
  return proto.vivacity.config.HardwareIdCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwareIdCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwareIdCreated}
 */
proto.vivacity.config.HardwareIdCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = new proto.vivacity.config.Fingerprint;
      reader.readMessage(value,proto.vivacity.config.Fingerprint.deserializeBinaryFromReader);
      msg.setFingerprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwareIdCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwareIdCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwareIdCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareIdCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFingerprint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.config.Fingerprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 hardware_id = 1;
 * @return {number}
 */
proto.vivacity.config.HardwareIdCreated.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwareIdCreated} returns this
 */
proto.vivacity.config.HardwareIdCreated.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Fingerprint fingerprint = 2;
 * @return {?proto.vivacity.config.Fingerprint}
 */
proto.vivacity.config.HardwareIdCreated.prototype.getFingerprint = function() {
  return /** @type{?proto.vivacity.config.Fingerprint} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.Fingerprint, 2));
};


/**
 * @param {?proto.vivacity.config.Fingerprint|undefined} value
 * @return {!proto.vivacity.config.HardwareIdCreated} returns this
*/
proto.vivacity.config.HardwareIdCreated.prototype.setFingerprint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HardwareIdCreated} returns this
 */
proto.vivacity.config.HardwareIdCreated.prototype.clearFingerprint = function() {
  return this.setFingerprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HardwareIdCreated.prototype.hasFingerprint = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwareManufacturers.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwareManufacturers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwareManufacturers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareManufacturers.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.HardwareManufacturers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwareManufacturers;
  return proto.vivacity.config.HardwareManufacturers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwareManufacturers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwareManufacturers}
 */
proto.vivacity.config.HardwareManufacturers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwareManufacturers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwareManufacturers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwareManufacturers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwareManufacturers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.HardwareManufacturers.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwareManufacturers} returns this
 */
proto.vivacity.config.HardwareManufacturers.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vivacity.config.HardwareManufacturers.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwareManufacturers} returns this
 */
proto.vivacity.config.HardwareManufacturers.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.vivacity.config);
