/* eslint-disable */
// source: vivacity/core/gstreamer_control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.vivacity.core.DeepStreamConfig', null, global);
goog.exportSymbol('proto.vivacity.core.DeepStreamGIEConfig', null, global);
goog.exportSymbol('proto.vivacity.core.GObjectProperty', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerBusMessage', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerBusMessage.MessageType', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerBusMessage.NavigationEvent', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerBusMessage.NavigationEvent.EventType', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerControl', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerControl.Operation', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerControl.PipelineConfig', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerControl.PipelineConfig.ClockRate', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerResponse', null, global);
goog.exportSymbol('proto.vivacity.core.GStreamerResponse.ResponseCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.GStreamerControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.GStreamerControl.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.GStreamerControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.GStreamerControl.displayName = 'proto.vivacity.core.GStreamerControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.GStreamerControl.PipelineConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.GStreamerControl.PipelineConfig.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.GStreamerControl.PipelineConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.GStreamerControl.PipelineConfig.displayName = 'proto.vivacity.core.GStreamerControl.PipelineConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.GObjectProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.GObjectProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.GObjectProperty.displayName = 'proto.vivacity.core.GObjectProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DeepStreamGIEConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.DeepStreamGIEConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DeepStreamGIEConfig.displayName = 'proto.vivacity.core.DeepStreamGIEConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DeepStreamConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.DeepStreamConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DeepStreamConfig.displayName = 'proto.vivacity.core.DeepStreamConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.GStreamerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.GStreamerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.GStreamerResponse.displayName = 'proto.vivacity.core.GStreamerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.GStreamerBusMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.GStreamerBusMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.GStreamerBusMessage.displayName = 'proto.vivacity.core.GStreamerBusMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.GStreamerBusMessage.NavigationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.GStreamerBusMessage.NavigationEvent.displayName = 'proto.vivacity.core.GStreamerBusMessage.NavigationEvent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.GStreamerControl.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.GStreamerControl.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.GStreamerControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.GStreamerControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    config: (f = msg.getConfig()) && proto.vivacity.core.GStreamerControl.PipelineConfig.toObject(includeInstance, f),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.vivacity.core.GObjectProperty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.GStreamerControl}
 */
proto.vivacity.core.GStreamerControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.GStreamerControl;
  return proto.vivacity.core.GStreamerControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.GStreamerControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.GStreamerControl}
 */
proto.vivacity.core.GStreamerControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.GStreamerControl.Operation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 3:
      var value = new proto.vivacity.core.GStreamerControl.PipelineConfig;
      reader.readMessage(value,proto.vivacity.core.GStreamerControl.PipelineConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 4:
      var value = new proto.vivacity.core.GObjectProperty;
      reader.readMessage(value,proto.vivacity.core.GObjectProperty.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.GStreamerControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.GStreamerControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.GStreamerControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.GStreamerControl.PipelineConfig.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vivacity.core.GObjectProperty.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.GStreamerControl.Operation = {
  UNKNOWN_OPERATION: 0,
  CREATE_AND_START_PIPELINE: 1,
  PAUSE_PIPELINE: 2,
  RESUME_PIPELINE: 3,
  STOP_AND_DESTROY_PIPELINE: 4,
  RESTART_PIPELINE: 5,
  SEND_EOS_TO_PIPELINE: 6,
  SET_GOBJECT_PROPERTIES: 7,
  GET_GOBJECT_PROPERTIES: 8
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.GStreamerControl.PipelineConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.GStreamerControl.PipelineConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineDefinition: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clockRate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    loop: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    logBusEvents: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    stopSupermarioPipelineWhenStopped: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    restartGstreamerPipelineOnError: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    restartDelayMs: jspb.Message.getFieldWithDefault(msg, 18, 0),
    omitCapsFromMeta: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    omitOkIfNoError: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    shutdownUsingEos: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    ignoreEos: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    doNotShutdownOnElementStop: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    awaitBusEosOnShutdown: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    deferShutdown: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    pipelineShutdownTimeoutMs: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deepstreamConfig: (f = msg.getDeepstreamConfig()) && proto.vivacity.core.DeepStreamConfig.toObject(includeInstance, f),
    pipelineStartupPriority: jspb.Message.getFieldWithDefault(msg, 13, 0),
    originatingMessageMetaFieldsToCopyList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.GStreamerControl.PipelineConfig;
  return proto.vivacity.core.GStreamerControl.PipelineConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.GStreamerControl.PipelineConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineDefinition(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.GStreamerControl.PipelineConfig.ClockRate} */ (reader.readEnum());
      msg.setClockRate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoop(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLogBusEvents(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStopSupermarioPipelineWhenStopped(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRestartGstreamerPipelineOnError(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRestartDelayMs(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOmitCapsFromMeta(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOmitOkIfNoError(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShutdownUsingEos(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEos(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotShutdownOnElementStop(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAwaitBusEosOnShutdown(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeferShutdown(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPipelineShutdownTimeoutMs(value);
      break;
    case 12:
      var value = new proto.vivacity.core.DeepStreamConfig;
      reader.readMessage(value,proto.vivacity.core.DeepStreamConfig.deserializeBinaryFromReader);
      msg.setDeepstreamConfig(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPipelineStartupPriority(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addOriginatingMessageMetaFieldsToCopy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.GStreamerControl.PipelineConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.GStreamerControl.PipelineConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineDefinition();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClockRate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLoop();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLogBusEvents();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStopSupermarioPipelineWhenStopped();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRestartGstreamerPipelineOnError();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRestartDelayMs();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getOmitCapsFromMeta();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOmitOkIfNoError();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getShutdownUsingEos();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIgnoreEos();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDoNotShutdownOnElementStop();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAwaitBusEosOnShutdown();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getDeferShutdown();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getPipelineShutdownTimeoutMs();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getDeepstreamConfig();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vivacity.core.DeepStreamConfig.serializeBinaryToWriter
    );
  }
  f = message.getPipelineStartupPriority();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getOriginatingMessageMetaFieldsToCopyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.ClockRate = {
  VIDEO: 0,
  AUDIO: 1,
  PCM: 2
};

/**
 * optional string pipeline_definition = 1;
 * @return {string}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getPipelineDefinition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setPipelineDefinition = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ClockRate clock_rate = 2;
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig.ClockRate}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getClockRate = function() {
  return /** @type {!proto.vivacity.core.GStreamerControl.PipelineConfig.ClockRate} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.GStreamerControl.PipelineConfig.ClockRate} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setClockRate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool loop = 3;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getLoop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setLoop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool log_bus_events = 4;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getLogBusEvents = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setLogBusEvents = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool stop_supermario_pipeline_when_stopped = 5;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getStopSupermarioPipelineWhenStopped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setStopSupermarioPipelineWhenStopped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool restart_gstreamer_pipeline_on_error = 6;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getRestartGstreamerPipelineOnError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setRestartGstreamerPipelineOnError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 restart_delay_ms = 18;
 * @return {number}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getRestartDelayMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setRestartDelayMs = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool omit_caps_from_meta = 7;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getOmitCapsFromMeta = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setOmitCapsFromMeta = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool omit_ok_if_no_error = 8;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getOmitOkIfNoError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setOmitOkIfNoError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool shutdown_using_eos = 9;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getShutdownUsingEos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setShutdownUsingEos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool ignore_eos = 10;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getIgnoreEos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setIgnoreEos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool do_not_shutdown_on_element_stop = 14;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getDoNotShutdownOnElementStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setDoNotShutdownOnElementStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool await_bus_eos_on_shutdown = 15;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getAwaitBusEosOnShutdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setAwaitBusEosOnShutdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool defer_shutdown = 16;
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getDeferShutdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setDeferShutdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional uint32 pipeline_shutdown_timeout_ms = 11;
 * @return {number}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getPipelineShutdownTimeoutMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setPipelineShutdownTimeoutMs = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional DeepStreamConfig deepstream_config = 12;
 * @return {?proto.vivacity.core.DeepStreamConfig}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getDeepstreamConfig = function() {
  return /** @type{?proto.vivacity.core.DeepStreamConfig} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DeepStreamConfig, 12));
};


/**
 * @param {?proto.vivacity.core.DeepStreamConfig|undefined} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
*/
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setDeepstreamConfig = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.clearDeepstreamConfig = function() {
  return this.setDeepstreamConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.hasDeepstreamConfig = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 pipeline_startup_priority = 13;
 * @return {number}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getPipelineStartupPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setPipelineStartupPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string originating_message_meta_fields_to_copy = 17;
 * @return {!Array<string>}
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.getOriginatingMessageMetaFieldsToCopyList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.setOriginatingMessageMetaFieldsToCopyList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.addOriginatingMessageMetaFieldsToCopy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.GStreamerControl.PipelineConfig} returns this
 */
proto.vivacity.core.GStreamerControl.PipelineConfig.prototype.clearOriginatingMessageMetaFieldsToCopyList = function() {
  return this.setOriginatingMessageMetaFieldsToCopyList([]);
};


/**
 * optional string pipeline_id = 1;
 * @return {string}
 */
proto.vivacity.core.GStreamerControl.prototype.getPipelineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerControl} returns this
 */
proto.vivacity.core.GStreamerControl.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Operation operation = 2;
 * @return {!proto.vivacity.core.GStreamerControl.Operation}
 */
proto.vivacity.core.GStreamerControl.prototype.getOperation = function() {
  return /** @type {!proto.vivacity.core.GStreamerControl.Operation} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.GStreamerControl.Operation} value
 * @return {!proto.vivacity.core.GStreamerControl} returns this
 */
proto.vivacity.core.GStreamerControl.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PipelineConfig config = 3;
 * @return {?proto.vivacity.core.GStreamerControl.PipelineConfig}
 */
proto.vivacity.core.GStreamerControl.prototype.getConfig = function() {
  return /** @type{?proto.vivacity.core.GStreamerControl.PipelineConfig} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.GStreamerControl.PipelineConfig, 3));
};


/**
 * @param {?proto.vivacity.core.GStreamerControl.PipelineConfig|undefined} value
 * @return {!proto.vivacity.core.GStreamerControl} returns this
*/
proto.vivacity.core.GStreamerControl.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.GStreamerControl} returns this
 */
proto.vivacity.core.GStreamerControl.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.GStreamerControl.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated GObjectProperty properties = 4;
 * @return {!Array<!proto.vivacity.core.GObjectProperty>}
 */
proto.vivacity.core.GStreamerControl.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.vivacity.core.GObjectProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.GObjectProperty, 4));
};


/**
 * @param {!Array<!proto.vivacity.core.GObjectProperty>} value
 * @return {!proto.vivacity.core.GStreamerControl} returns this
*/
proto.vivacity.core.GStreamerControl.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vivacity.core.GObjectProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GObjectProperty}
 */
proto.vivacity.core.GStreamerControl.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vivacity.core.GObjectProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.GStreamerControl} returns this
 */
proto.vivacity.core.GStreamerControl.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.GObjectProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.GObjectProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.GObjectProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GObjectProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertyValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.GObjectProperty}
 */
proto.vivacity.core.GObjectProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.GObjectProperty;
  return proto.vivacity.core.GObjectProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.GObjectProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.GObjectProperty}
 */
proto.vivacity.core.GObjectProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setElementName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.GObjectProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.GObjectProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.GObjectProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GObjectProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPropertyValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string element_name = 1;
 * @return {string}
 */
proto.vivacity.core.GObjectProperty.prototype.getElementName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GObjectProperty} returns this
 */
proto.vivacity.core.GObjectProperty.prototype.setElementName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string property_name = 2;
 * @return {string}
 */
proto.vivacity.core.GObjectProperty.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GObjectProperty} returns this
 */
proto.vivacity.core.GObjectProperty.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string property_value = 3;
 * @return {string}
 */
proto.vivacity.core.GObjectProperty.prototype.getPropertyValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GObjectProperty} returns this
 */
proto.vivacity.core.GObjectProperty.prototype.setPropertyValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DeepStreamGIEConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DeepStreamGIEConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DeepStreamGIEConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DeepStreamGIEConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    classTypesByLabelIndexMap: (f = msg.getClassTypesByLabelIndexMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DeepStreamGIEConfig}
 */
proto.vivacity.core.DeepStreamGIEConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DeepStreamGIEConfig;
  return proto.vivacity.core.DeepStreamGIEConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DeepStreamGIEConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DeepStreamGIEConfig}
 */
proto.vivacity.core.DeepStreamGIEConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getClassTypesByLabelIndexMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DeepStreamGIEConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DeepStreamGIEConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DeepStreamGIEConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DeepStreamGIEConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassTypesByLabelIndexMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * map<uint32, int32> class_types_by_label_index = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.DeepStreamGIEConfig.prototype.getClassTypesByLabelIndexMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.DeepStreamGIEConfig} returns this
 */
proto.vivacity.core.DeepStreamGIEConfig.prototype.clearClassTypesByLabelIndexMap = function() {
  this.getClassTypesByLabelIndexMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DeepStreamConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DeepStreamConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DeepStreamConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DeepStreamConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    gieConfigsMap: (f = msg.getGieConfigsMap()) ? f.toObject(includeInstance, proto.vivacity.core.DeepStreamGIEConfig.toObject) : [],
    startTimestampNanoseconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ptsInsteadOfNvstreammuxTimestamp: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DeepStreamConfig}
 */
proto.vivacity.core.DeepStreamConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DeepStreamConfig;
  return proto.vivacity.core.DeepStreamConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DeepStreamConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DeepStreamConfig}
 */
proto.vivacity.core.DeepStreamConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGieConfigsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DeepStreamGIEConfig.deserializeBinaryFromReader, 0, new proto.vivacity.core.DeepStreamGIEConfig());
         });
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimestampNanoseconds(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPtsInsteadOfNvstreammuxTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DeepStreamConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DeepStreamConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DeepStreamConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DeepStreamConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGieConfigsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DeepStreamGIEConfig.serializeBinaryToWriter);
  }
  f = message.getStartTimestampNanoseconds();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPtsInsteadOfNvstreammuxTimestamp();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * map<int32, DeepStreamGIEConfig> gie_configs = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DeepStreamGIEConfig>}
 */
proto.vivacity.core.DeepStreamConfig.prototype.getGieConfigsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DeepStreamGIEConfig>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.vivacity.core.DeepStreamGIEConfig));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.DeepStreamConfig} returns this
 */
proto.vivacity.core.DeepStreamConfig.prototype.clearGieConfigsMap = function() {
  this.getGieConfigsMap().clear();
  return this;};


/**
 * optional uint64 start_timestamp_nanoseconds = 2;
 * @return {number}
 */
proto.vivacity.core.DeepStreamConfig.prototype.getStartTimestampNanoseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DeepStreamConfig} returns this
 */
proto.vivacity.core.DeepStreamConfig.prototype.setStartTimestampNanoseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool pts_instead_of_nvstreammux_timestamp = 3;
 * @return {boolean}
 */
proto.vivacity.core.DeepStreamConfig.prototype.getPtsInsteadOfNvstreammuxTimestamp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.DeepStreamConfig} returns this
 */
proto.vivacity.core.DeepStreamConfig.prototype.setPtsInsteadOfNvstreammuxTimestamp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.GStreamerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.GStreamerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.GStreamerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlMessage: (f = msg.getControlMessage()) && proto.vivacity.core.GStreamerControl.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.GStreamerResponse}
 */
proto.vivacity.core.GStreamerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.GStreamerResponse;
  return proto.vivacity.core.GStreamerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.GStreamerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.GStreamerResponse}
 */
proto.vivacity.core.GStreamerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.GStreamerResponse.ResponseCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = new proto.vivacity.core.GStreamerControl;
      reader.readMessage(value,proto.vivacity.core.GStreamerControl.deserializeBinaryFromReader);
      msg.setControlMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.GStreamerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.GStreamerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.GStreamerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.GStreamerControl.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.GStreamerResponse.ResponseCode = {
  OK_NO_ERROR: 0,
  ERROR_UNKNOWN_OPERATION: 1,
  ERROR_EMPTY_PIPELINE_ID: 2,
  ERROR_CREATING_PIPELINE: 3,
  ERROR_CREATING_PIPELINE_ALREADY_EXISTS: 8,
  ERROR_STARTING_PIPELINE: 4,
  ERROR_RESUMING_PIPELINE: 5,
  ERROR_PAUSING_PIPELINE: 6,
  ERROR_PIPELINE_NOT_FOUND: 7,
  ERROR_RESTARTING_PIPELINE: 9,
  ERROR_INVALID_CONTROL_MESSAGE: 10,
  ERROR_FAILED_TO_SEND_EOS: 11,
  ERROR_GOBJECT_PROPERTY_NO_SUCH_ELEMENT: 12,
  ERROR_GOBJECT_PROPERTY_NO_SUCH_PROPERTY: 13,
  ERROR_GOBJECT_PROPERTY_SET_FAILED_INCORRECT_TYPE: 14
};

/**
 * optional ResponseCode code = 1;
 * @return {!proto.vivacity.core.GStreamerResponse.ResponseCode}
 */
proto.vivacity.core.GStreamerResponse.prototype.getCode = function() {
  return /** @type {!proto.vivacity.core.GStreamerResponse.ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.GStreamerResponse.ResponseCode} value
 * @return {!proto.vivacity.core.GStreamerResponse} returns this
 */
proto.vivacity.core.GStreamerResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.vivacity.core.GStreamerResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerResponse} returns this
 */
proto.vivacity.core.GStreamerResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GStreamerControl control_message = 3;
 * @return {?proto.vivacity.core.GStreamerControl}
 */
proto.vivacity.core.GStreamerResponse.prototype.getControlMessage = function() {
  return /** @type{?proto.vivacity.core.GStreamerControl} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.GStreamerControl, 3));
};


/**
 * @param {?proto.vivacity.core.GStreamerControl|undefined} value
 * @return {!proto.vivacity.core.GStreamerResponse} returns this
*/
proto.vivacity.core.GStreamerResponse.prototype.setControlMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.GStreamerResponse} returns this
 */
proto.vivacity.core.GStreamerResponse.prototype.clearControlMessage = function() {
  return this.setControlMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.GStreamerResponse.prototype.hasControlMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.GStreamerBusMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.GStreamerBusMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerBusMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    gstreamerPipelineConnectorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    debugMsg: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gstreamerElementName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    navigationEvent: (f = msg.getNavigationEvent()) && proto.vivacity.core.GStreamerBusMessage.NavigationEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.GStreamerBusMessage}
 */
proto.vivacity.core.GStreamerBusMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.GStreamerBusMessage;
  return proto.vivacity.core.GStreamerBusMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.GStreamerBusMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.GStreamerBusMessage}
 */
proto.vivacity.core.GStreamerBusMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.GStreamerBusMessage.MessageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGstreamerPipelineConnectorName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebugMsg(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGstreamerElementName(value);
      break;
    case 7:
      var value = new proto.vivacity.core.GStreamerBusMessage.NavigationEvent;
      reader.readMessage(value,proto.vivacity.core.GStreamerBusMessage.NavigationEvent.deserializeBinaryFromReader);
      msg.setNavigationEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.GStreamerBusMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.GStreamerBusMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerBusMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGstreamerPipelineConnectorName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPipelineId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDebugMsg();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGstreamerElementName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNavigationEvent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.core.GStreamerBusMessage.NavigationEvent.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.GStreamerBusMessage.MessageType = {
  GST_MESSAGE_UNKNOWN: 0,
  GST_MESSAGE_EOS: 1,
  GST_MESSAGE_ERROR: 2,
  GST_MESSAGE_WARNING: 3,
  GST_MESSAGE_INFO: 4,
  GST_MESSAGE_TAG: 5,
  GST_MESSAGE_BUFFERING: 6,
  GST_MESSAGE_STATE_CHANGED: 7,
  GST_MESSAGE_STATE_DIRTY: 8,
  GST_MESSAGE_STEP_DONE: 9,
  GST_MESSAGE_CLOCK_PROVIDE: 10,
  GST_MESSAGE_CLOCK_LOST: 11,
  GST_MESSAGE_NEW_CLOCK: 12,
  GST_MESSAGE_STRUCTURE_CHANGE: 13,
  GST_MESSAGE_STREAM_STATUS: 14,
  GST_MESSAGE_APPLICATION: 15,
  GST_MESSAGE_ELEMENT: 16,
  GST_MESSAGE_SEGMENT_START: 17,
  GST_MESSAGE_SEGMENT_DONE: 18,
  GST_MESSAGE_DURATION_CHANGED: 19,
  GST_MESSAGE_LATENCY: 20,
  GST_MESSAGE_ASYNC_START: 21,
  GST_MESSAGE_ASYNC_DONE: 22,
  GST_MESSAGE_REQUEST_STATE: 23,
  GST_MESSAGE_STEP_START: 24,
  GST_MESSAGE_QOS: 25,
  GST_MESSAGE_PROGRESS: 26,
  GST_MESSAGE_TOC: 27,
  GST_MESSAGE_RESET_TIME: 28,
  GST_MESSAGE_STREAM_START: 29,
  GST_MESSAGE_NEED_CONTEXT: 30,
  GST_MESSAGE_HAVE_CONTEXT: 31,
  GST_MESSAGE_EXTENDED: 32,
  GST_MESSAGE_DEVICE_ADDED: 33,
  GST_MESSAGE_DEVICE_REMOVED: 34,
  GST_MESSAGE_PROPERTY_NOTIFY: 35,
  GST_MESSAGE_STREAM_COLLECTION: 36,
  GST_MESSAGE_STREAMS_SELECTED: 37,
  GST_MESSAGE_REDIRECT: 38,
  GST_MESSAGE_DEVICE_CHANGED: 39,
  GST_MESSAGE_ANY: 40
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.GStreamerBusMessage.NavigationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    key: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.GStreamerBusMessage.NavigationEvent;
  return proto.vivacity.core.GStreamerBusMessage.NavigationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.GStreamerBusMessage.NavigationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.EventType = {
  GST_NAVIGATION_EVENT_INVALID: 0,
  GST_NAVIGATION_EVENT_KEY_PRESS: 1,
  GST_NAVIGATION_EVENT_KEY_RELEASE: 2,
  GST_NAVIGATION_EVENT_MOUSE_BUTTON_PRESS: 3,
  GST_NAVIGATION_EVENT_MOUSE_BUTTON_RELEASE: 4,
  GST_NAVIGATION_EVENT_MOUSE_MOVE: 5,
  GST_NAVIGATION_EVENT_COMMAND: 6
};

/**
 * optional EventType event_type = 1;
 * @return {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent.EventType}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.getEventType = function() {
  return /** @type {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent.EventType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent.EventType} value
 * @return {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent} returns this
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double x = 2;
 * @return {number}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent} returns this
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double y = 3;
 * @return {number}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent} returns this
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string key = 4;
 * @return {string}
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerBusMessage.NavigationEvent} returns this
 */
proto.vivacity.core.GStreamerBusMessage.NavigationEvent.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional MessageType type = 1;
 * @return {!proto.vivacity.core.GStreamerBusMessage.MessageType}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.GStreamerBusMessage.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.GStreamerBusMessage.MessageType} value
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
 */
proto.vivacity.core.GStreamerBusMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string gstreamer_pipeline_connector_name = 2;
 * @return {string}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.getGstreamerPipelineConnectorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
 */
proto.vivacity.core.GStreamerBusMessage.prototype.setGstreamerPipelineConnectorName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pipeline_id = 3;
 * @return {string}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.getPipelineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
 */
proto.vivacity.core.GStreamerBusMessage.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
 */
proto.vivacity.core.GStreamerBusMessage.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string debug_msg = 5;
 * @return {string}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.getDebugMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
 */
proto.vivacity.core.GStreamerBusMessage.prototype.setDebugMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gstreamer_element_name = 6;
 * @return {string}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.getGstreamerElementName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
 */
proto.vivacity.core.GStreamerBusMessage.prototype.setGstreamerElementName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional NavigationEvent navigation_event = 7;
 * @return {?proto.vivacity.core.GStreamerBusMessage.NavigationEvent}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.getNavigationEvent = function() {
  return /** @type{?proto.vivacity.core.GStreamerBusMessage.NavigationEvent} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.GStreamerBusMessage.NavigationEvent, 7));
};


/**
 * @param {?proto.vivacity.core.GStreamerBusMessage.NavigationEvent|undefined} value
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
*/
proto.vivacity.core.GStreamerBusMessage.prototype.setNavigationEvent = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.GStreamerBusMessage} returns this
 */
proto.vivacity.core.GStreamerBusMessage.prototype.clearNavigationEvent = function() {
  return this.setNavigationEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.GStreamerBusMessage.prototype.hasNavigationEvent = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.vivacity.core);
