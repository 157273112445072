/* eslint-disable */
// source: vivacity/core/webrtc_peer_connector.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.vivacity.core.WebRTCAddPeerConnection', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCConnectionError', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCConnectionError.Type', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCDataChannelDataChunk', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCDataChannelRequest', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCICECandidate', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCICEServer', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCICEServer.CredentialCase', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCICEServer.ICECredentialType', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCICEServer.OAuthCredential', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCRemovePeerConnection', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCRequestPeerByPrincipalID', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCSDP', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCSDP.Type', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCSessionNegotiation', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCSignalling', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCSignalling.Type', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCStateUpdate', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCStateUpdate.Type', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCTrackRequest', null, global);
goog.exportSymbol('proto.vivacity.core.WebRTCTrackRequest.PayloadType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCSignalling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCSignalling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCSignalling.displayName = 'proto.vivacity.core.WebRTCSignalling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCAddPeerConnection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.WebRTCAddPeerConnection.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.WebRTCAddPeerConnection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCAddPeerConnection.displayName = 'proto.vivacity.core.WebRTCAddPeerConnection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCRemovePeerConnection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCRemovePeerConnection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCRemovePeerConnection.displayName = 'proto.vivacity.core.WebRTCRemovePeerConnection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCICEServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.WebRTCICEServer.repeatedFields_, proto.vivacity.core.WebRTCICEServer.oneofGroups_);
};
goog.inherits(proto.vivacity.core.WebRTCICEServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCICEServer.displayName = 'proto.vivacity.core.WebRTCICEServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCICEServer.OAuthCredential, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCICEServer.OAuthCredential.displayName = 'proto.vivacity.core.WebRTCICEServer.OAuthCredential';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCTrackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCTrackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCTrackRequest.displayName = 'proto.vivacity.core.WebRTCTrackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCDataChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCDataChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCDataChannelRequest.displayName = 'proto.vivacity.core.WebRTCDataChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCDataChannelDataChunk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCDataChannelDataChunk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCDataChannelDataChunk.displayName = 'proto.vivacity.core.WebRTCDataChannelDataChunk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCICECandidate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCICECandidate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCICECandidate.displayName = 'proto.vivacity.core.WebRTCICECandidate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCSDP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCSDP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCSDP.displayName = 'proto.vivacity.core.WebRTCSDP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCSessionNegotiation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCSessionNegotiation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCSessionNegotiation.displayName = 'proto.vivacity.core.WebRTCSessionNegotiation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCConnectionError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCConnectionError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCConnectionError.displayName = 'proto.vivacity.core.WebRTCConnectionError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCStateUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.WebRTCStateUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCStateUpdate.displayName = 'proto.vivacity.core.WebRTCStateUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.WebRTCRequestPeerByPrincipalID.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.WebRTCRequestPeerByPrincipalID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WebRTCRequestPeerByPrincipalID.displayName = 'proto.vivacity.core.WebRTCRequestPeerByPrincipalID';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCSignalling.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCSignalling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCSignalling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCSignalling.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    senderId: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    peerId: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    peerName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    addPeerConnection: (f = msg.getAddPeerConnection()) && proto.vivacity.core.WebRTCAddPeerConnection.toObject(includeInstance, f),
    removePeerConnection: (f = msg.getRemovePeerConnection()) && proto.vivacity.core.WebRTCRemovePeerConnection.toObject(includeInstance, f),
    sessionNegotiation: (f = msg.getSessionNegotiation()) && proto.vivacity.core.WebRTCSessionNegotiation.toObject(includeInstance, f),
    stateUpdate: (f = msg.getStateUpdate()) && proto.vivacity.core.WebRTCStateUpdate.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.vivacity.core.WebRTCConnectionError.toObject(includeInstance, f),
    requestPeer: (f = msg.getRequestPeer()) && proto.vivacity.core.WebRTCRequestPeerByPrincipalID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCSignalling}
 */
proto.vivacity.core.WebRTCSignalling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCSignalling;
  return proto.vivacity.core.WebRTCSignalling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCSignalling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCSignalling}
 */
proto.vivacity.core.WebRTCSignalling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.WebRTCSignalling.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readFixed64String());
      msg.setSenderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readFixed64String());
      msg.setPeerId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeerName(value);
      break;
    case 4:
      var value = new proto.vivacity.core.WebRTCAddPeerConnection;
      reader.readMessage(value,proto.vivacity.core.WebRTCAddPeerConnection.deserializeBinaryFromReader);
      msg.setAddPeerConnection(value);
      break;
    case 5:
      var value = new proto.vivacity.core.WebRTCRemovePeerConnection;
      reader.readMessage(value,proto.vivacity.core.WebRTCRemovePeerConnection.deserializeBinaryFromReader);
      msg.setRemovePeerConnection(value);
      break;
    case 6:
      var value = new proto.vivacity.core.WebRTCSessionNegotiation;
      reader.readMessage(value,proto.vivacity.core.WebRTCSessionNegotiation.deserializeBinaryFromReader);
      msg.setSessionNegotiation(value);
      break;
    case 7:
      var value = new proto.vivacity.core.WebRTCStateUpdate;
      reader.readMessage(value,proto.vivacity.core.WebRTCStateUpdate.deserializeBinaryFromReader);
      msg.setStateUpdate(value);
      break;
    case 8:
      var value = new proto.vivacity.core.WebRTCConnectionError;
      reader.readMessage(value,proto.vivacity.core.WebRTCConnectionError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 9:
      var value = new proto.vivacity.core.WebRTCRequestPeerByPrincipalID;
      reader.readMessage(value,proto.vivacity.core.WebRTCRequestPeerByPrincipalID.deserializeBinaryFromReader);
      msg.setRequestPeer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCSignalling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCSignalling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCSignalling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCSignalling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSenderId();
  if (parseInt(f, 10) !== 0) {
    writer.writeFixed64String(
      2,
      f
    );
  }
  f = message.getPeerId();
  if (parseInt(f, 10) !== 0) {
    writer.writeFixed64String(
      3,
      f
    );
  }
  f = message.getPeerName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAddPeerConnection();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.WebRTCAddPeerConnection.serializeBinaryToWriter
    );
  }
  f = message.getRemovePeerConnection();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vivacity.core.WebRTCRemovePeerConnection.serializeBinaryToWriter
    );
  }
  f = message.getSessionNegotiation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.core.WebRTCSessionNegotiation.serializeBinaryToWriter
    );
  }
  f = message.getStateUpdate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.core.WebRTCStateUpdate.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vivacity.core.WebRTCConnectionError.serializeBinaryToWriter
    );
  }
  f = message.getRequestPeer();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.core.WebRTCRequestPeerByPrincipalID.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.WebRTCSignalling.Type = {
  UNKNOWN: 0,
  ADD: 1,
  REMOVE: 2,
  SESSION_NEGOTIATION: 3,
  STATE_UPDATE: 4,
  ERROR: 5,
  REQUEST_PEER_BY_PRINCIPAL_ID: 6
};

/**
 * optional Type type = 1;
 * @return {!proto.vivacity.core.WebRTCSignalling.Type}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.WebRTCSignalling.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.WebRTCSignalling.Type} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional fixed64 sender_id = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional fixed64 peer_id = 3;
 * @return {string}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getPeerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.setPeerId = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional string peer_name = 10;
 * @return {string}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getPeerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.setPeerName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional WebRTCAddPeerConnection add_peer_connection = 4;
 * @return {?proto.vivacity.core.WebRTCAddPeerConnection}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getAddPeerConnection = function() {
  return /** @type{?proto.vivacity.core.WebRTCAddPeerConnection} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCAddPeerConnection, 4));
};


/**
 * @param {?proto.vivacity.core.WebRTCAddPeerConnection|undefined} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
*/
proto.vivacity.core.WebRTCSignalling.prototype.setAddPeerConnection = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.clearAddPeerConnection = function() {
  return this.setAddPeerConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSignalling.prototype.hasAddPeerConnection = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WebRTCRemovePeerConnection remove_peer_connection = 5;
 * @return {?proto.vivacity.core.WebRTCRemovePeerConnection}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getRemovePeerConnection = function() {
  return /** @type{?proto.vivacity.core.WebRTCRemovePeerConnection} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCRemovePeerConnection, 5));
};


/**
 * @param {?proto.vivacity.core.WebRTCRemovePeerConnection|undefined} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
*/
proto.vivacity.core.WebRTCSignalling.prototype.setRemovePeerConnection = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.clearRemovePeerConnection = function() {
  return this.setRemovePeerConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSignalling.prototype.hasRemovePeerConnection = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WebRTCSessionNegotiation session_negotiation = 6;
 * @return {?proto.vivacity.core.WebRTCSessionNegotiation}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getSessionNegotiation = function() {
  return /** @type{?proto.vivacity.core.WebRTCSessionNegotiation} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCSessionNegotiation, 6));
};


/**
 * @param {?proto.vivacity.core.WebRTCSessionNegotiation|undefined} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
*/
proto.vivacity.core.WebRTCSignalling.prototype.setSessionNegotiation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.clearSessionNegotiation = function() {
  return this.setSessionNegotiation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSignalling.prototype.hasSessionNegotiation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional WebRTCStateUpdate state_update = 7;
 * @return {?proto.vivacity.core.WebRTCStateUpdate}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getStateUpdate = function() {
  return /** @type{?proto.vivacity.core.WebRTCStateUpdate} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCStateUpdate, 7));
};


/**
 * @param {?proto.vivacity.core.WebRTCStateUpdate|undefined} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
*/
proto.vivacity.core.WebRTCSignalling.prototype.setStateUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.clearStateUpdate = function() {
  return this.setStateUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSignalling.prototype.hasStateUpdate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional WebRTCConnectionError error = 8;
 * @return {?proto.vivacity.core.WebRTCConnectionError}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getError = function() {
  return /** @type{?proto.vivacity.core.WebRTCConnectionError} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCConnectionError, 8));
};


/**
 * @param {?proto.vivacity.core.WebRTCConnectionError|undefined} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
*/
proto.vivacity.core.WebRTCSignalling.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSignalling.prototype.hasError = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional WebRTCRequestPeerByPrincipalID request_peer = 9;
 * @return {?proto.vivacity.core.WebRTCRequestPeerByPrincipalID}
 */
proto.vivacity.core.WebRTCSignalling.prototype.getRequestPeer = function() {
  return /** @type{?proto.vivacity.core.WebRTCRequestPeerByPrincipalID} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCRequestPeerByPrincipalID, 9));
};


/**
 * @param {?proto.vivacity.core.WebRTCRequestPeerByPrincipalID|undefined} value
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
*/
proto.vivacity.core.WebRTCSignalling.prototype.setRequestPeer = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSignalling} returns this
 */
proto.vivacity.core.WebRTCSignalling.prototype.clearRequestPeer = function() {
  return this.setRequestPeer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSignalling.prototype.hasRequestPeer = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.WebRTCAddPeerConnection.repeatedFields_ = [1,2,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCAddPeerConnection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCAddPeerConnection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCAddPeerConnection.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataChannelsList: jspb.Message.toObjectList(msg.getDataChannelsList(),
    proto.vivacity.core.WebRTCDataChannelRequest.toObject, includeInstance),
    tracksList: jspb.Message.toObjectList(msg.getTracksList(),
    proto.vivacity.core.WebRTCTrackRequest.toObject, includeInstance),
    allowedRemoteDataChannelsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    createOffer: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    iceServersList: jspb.Message.toObjectList(msg.getIceServersList(),
    proto.vivacity.core.WebRTCICEServer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection}
 */
proto.vivacity.core.WebRTCAddPeerConnection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCAddPeerConnection;
  return proto.vivacity.core.WebRTCAddPeerConnection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCAddPeerConnection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection}
 */
proto.vivacity.core.WebRTCAddPeerConnection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.WebRTCDataChannelRequest;
      reader.readMessage(value,proto.vivacity.core.WebRTCDataChannelRequest.deserializeBinaryFromReader);
      msg.addDataChannels(value);
      break;
    case 2:
      var value = new proto.vivacity.core.WebRTCTrackRequest;
      reader.readMessage(value,proto.vivacity.core.WebRTCTrackRequest.deserializeBinaryFromReader);
      msg.addTracks(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedRemoteDataChannels(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateOffer(value);
      break;
    case 5:
      var value = new proto.vivacity.core.WebRTCICEServer;
      reader.readMessage(value,proto.vivacity.core.WebRTCICEServer.deserializeBinaryFromReader);
      msg.addIceServers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCAddPeerConnection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCAddPeerConnection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCAddPeerConnection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.WebRTCDataChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getTracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.core.WebRTCTrackRequest.serializeBinaryToWriter
    );
  }
  f = message.getAllowedRemoteDataChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getCreateOffer();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIceServersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.vivacity.core.WebRTCICEServer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WebRTCDataChannelRequest data_channels = 1;
 * @return {!Array<!proto.vivacity.core.WebRTCDataChannelRequest>}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.getDataChannelsList = function() {
  return /** @type{!Array<!proto.vivacity.core.WebRTCDataChannelRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.WebRTCDataChannelRequest, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.WebRTCDataChannelRequest>} value
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
*/
proto.vivacity.core.WebRTCAddPeerConnection.prototype.setDataChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.WebRTCDataChannelRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.addDataChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.WebRTCDataChannelRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.clearDataChannelsList = function() {
  return this.setDataChannelsList([]);
};


/**
 * repeated WebRTCTrackRequest tracks = 2;
 * @return {!Array<!proto.vivacity.core.WebRTCTrackRequest>}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.getTracksList = function() {
  return /** @type{!Array<!proto.vivacity.core.WebRTCTrackRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.WebRTCTrackRequest, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.WebRTCTrackRequest>} value
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
*/
proto.vivacity.core.WebRTCAddPeerConnection.prototype.setTracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.WebRTCTrackRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WebRTCTrackRequest}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.addTracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.WebRTCTrackRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.clearTracksList = function() {
  return this.setTracksList([]);
};


/**
 * repeated string allowed_remote_data_channels = 3;
 * @return {!Array<string>}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.getAllowedRemoteDataChannelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.setAllowedRemoteDataChannelsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.addAllowedRemoteDataChannels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.clearAllowedRemoteDataChannelsList = function() {
  return this.setAllowedRemoteDataChannelsList([]);
};


/**
 * optional bool create_offer = 4;
 * @return {boolean}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.getCreateOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.setCreateOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated WebRTCICEServer ice_servers = 5;
 * @return {!Array<!proto.vivacity.core.WebRTCICEServer>}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.getIceServersList = function() {
  return /** @type{!Array<!proto.vivacity.core.WebRTCICEServer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.WebRTCICEServer, 5));
};


/**
 * @param {!Array<!proto.vivacity.core.WebRTCICEServer>} value
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
*/
proto.vivacity.core.WebRTCAddPeerConnection.prototype.setIceServersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vivacity.core.WebRTCICEServer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WebRTCICEServer}
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.addIceServers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vivacity.core.WebRTCICEServer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WebRTCAddPeerConnection} returns this
 */
proto.vivacity.core.WebRTCAddPeerConnection.prototype.clearIceServersList = function() {
  return this.setIceServersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCRemovePeerConnection.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCRemovePeerConnection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCRemovePeerConnection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCRemovePeerConnection.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCRemovePeerConnection}
 */
proto.vivacity.core.WebRTCRemovePeerConnection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCRemovePeerConnection;
  return proto.vivacity.core.WebRTCRemovePeerConnection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCRemovePeerConnection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCRemovePeerConnection}
 */
proto.vivacity.core.WebRTCRemovePeerConnection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCRemovePeerConnection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCRemovePeerConnection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCRemovePeerConnection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCRemovePeerConnection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.WebRTCICEServer.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.vivacity.core.WebRTCICEServer.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.vivacity.core.WebRTCICEServer.CredentialCase = {
  CREDENTIAL_NOT_SET: 0,
  PASSWORD: 3,
  OAUTH: 4
};

/**
 * @return {proto.vivacity.core.WebRTCICEServer.CredentialCase}
 */
proto.vivacity.core.WebRTCICEServer.prototype.getCredentialCase = function() {
  return /** @type {proto.vivacity.core.WebRTCICEServer.CredentialCase} */(jspb.Message.computeOneofCase(this, proto.vivacity.core.WebRTCICEServer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCICEServer.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCICEServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCICEServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCICEServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    urlsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oauth: (f = msg.getOauth()) && proto.vivacity.core.WebRTCICEServer.OAuthCredential.toObject(includeInstance, f),
    credentialType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCICEServer}
 */
proto.vivacity.core.WebRTCICEServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCICEServer;
  return proto.vivacity.core.WebRTCICEServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCICEServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCICEServer}
 */
proto.vivacity.core.WebRTCICEServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUrls(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = new proto.vivacity.core.WebRTCICEServer.OAuthCredential;
      reader.readMessage(value,proto.vivacity.core.WebRTCICEServer.OAuthCredential.deserializeBinaryFromReader);
      msg.setOauth(value);
      break;
    case 5:
      var value = /** @type {!proto.vivacity.core.WebRTCICEServer.ICECredentialType} */ (reader.readEnum());
      msg.setCredentialType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCICEServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCICEServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCICEServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCICEServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOauth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.WebRTCICEServer.OAuthCredential.serializeBinaryToWriter
    );
  }
  f = message.getCredentialType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.WebRTCICEServer.ICECredentialType = {
  PASSWORD: 0,
  OAUTH: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCICEServer.OAuthCredential.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCICEServer.OAuthCredential} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.toObject = function(includeInstance, msg) {
  var f, obj = {
    macKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCICEServer.OAuthCredential}
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCICEServer.OAuthCredential;
  return proto.vivacity.core.WebRTCICEServer.OAuthCredential.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCICEServer.OAuthCredential} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCICEServer.OAuthCredential}
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCICEServer.OAuthCredential.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCICEServer.OAuthCredential} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string mac_key = 1;
 * @return {string}
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.prototype.getMacKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCICEServer.OAuthCredential} returns this
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.prototype.setMacKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string access_token = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCICEServer.OAuthCredential} returns this
 */
proto.vivacity.core.WebRTCICEServer.OAuthCredential.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string urls = 1;
 * @return {!Array<string>}
 */
proto.vivacity.core.WebRTCICEServer.prototype.getUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.setUrlsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.addUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCICEServer.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.vivacity.core.WebRTCICEServer.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.setPassword = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.vivacity.core.WebRTCICEServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.clearPassword = function() {
  return jspb.Message.setOneofField(this, 3, proto.vivacity.core.WebRTCICEServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCICEServer.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OAuthCredential oauth = 4;
 * @return {?proto.vivacity.core.WebRTCICEServer.OAuthCredential}
 */
proto.vivacity.core.WebRTCICEServer.prototype.getOauth = function() {
  return /** @type{?proto.vivacity.core.WebRTCICEServer.OAuthCredential} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCICEServer.OAuthCredential, 4));
};


/**
 * @param {?proto.vivacity.core.WebRTCICEServer.OAuthCredential|undefined} value
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
*/
proto.vivacity.core.WebRTCICEServer.prototype.setOauth = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.vivacity.core.WebRTCICEServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.clearOauth = function() {
  return this.setOauth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCICEServer.prototype.hasOauth = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ICECredentialType credential_type = 5;
 * @return {!proto.vivacity.core.WebRTCICEServer.ICECredentialType}
 */
proto.vivacity.core.WebRTCICEServer.prototype.getCredentialType = function() {
  return /** @type {!proto.vivacity.core.WebRTCICEServer.ICECredentialType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vivacity.core.WebRTCICEServer.ICECredentialType} value
 * @return {!proto.vivacity.core.WebRTCICEServer} returns this
 */
proto.vivacity.core.WebRTCICEServer.prototype.setCredentialType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCTrackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCTrackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCTrackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    payloadType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCTrackRequest}
 */
proto.vivacity.core.WebRTCTrackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCTrackRequest;
  return proto.vivacity.core.WebRTCTrackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCTrackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCTrackRequest}
 */
proto.vivacity.core.WebRTCTrackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackLabel(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.WebRTCTrackRequest.PayloadType} */ (reader.readEnum());
      msg.setPayloadType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCTrackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCTrackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCTrackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPayloadType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.WebRTCTrackRequest.PayloadType = {
  PCMU: 0,
  PCMA: 8,
  G722: 9,
  OPUS: 111,
  VP8: 96,
  VP9: 98,
  H264: 102
};

/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCTrackRequest} returns this
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string track_label = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.getTrackLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCTrackRequest} returns this
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.setTrackLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PayloadType payload_type = 3;
 * @return {!proto.vivacity.core.WebRTCTrackRequest.PayloadType}
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.getPayloadType = function() {
  return /** @type {!proto.vivacity.core.WebRTCTrackRequest.PayloadType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.WebRTCTrackRequest.PayloadType} value
 * @return {!proto.vivacity.core.WebRTCTrackRequest} returns this
 */
proto.vivacity.core.WebRTCTrackRequest.prototype.setPayloadType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCDataChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCDataChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCDataChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    chunkSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unordered: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    maxPacketLifetimeMs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxRetransmits: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest}
 */
proto.vivacity.core.WebRTCDataChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCDataChannelRequest;
  return proto.vivacity.core.WebRTCDataChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCDataChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest}
 */
proto.vivacity.core.WebRTCDataChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtocol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelLabel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChunkSize(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnordered(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxPacketLifetimeMs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxRetransmits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCDataChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCDataChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCDataChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtocol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChunkSize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getUnordered();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMaxPacketLifetimeMs();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMaxRetransmits();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional string protocol = 1;
 * @return {string}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.getProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest} returns this
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel_label = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.getChannelLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest} returns this
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.setChannelLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 chunk_size = 3;
 * @return {number}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.getChunkSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest} returns this
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.setChunkSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool unordered = 4;
 * @return {boolean}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.getUnordered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest} returns this
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.setUnordered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional uint32 max_packet_lifetime_ms = 5;
 * @return {number}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.getMaxPacketLifetimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest} returns this
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.setMaxPacketLifetimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 max_retransmits = 6;
 * @return {number}
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.getMaxRetransmits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest} returns this
 */
proto.vivacity.core.WebRTCDataChannelRequest.prototype.setMaxRetransmits = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCDataChannelDataChunk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCDataChannelDataChunk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fragmentNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalFragments: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCDataChannelDataChunk}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCDataChannelDataChunk;
  return proto.vivacity.core.WebRTCDataChannelDataChunk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCDataChannelDataChunk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCDataChannelDataChunk}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFragmentNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalFragments(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCDataChannelDataChunk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCDataChannelDataChunk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFragmentNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotalFragments();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WebRTCDataChannelDataChunk} returns this
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 fragment_number = 2;
 * @return {number}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.getFragmentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WebRTCDataChannelDataChunk} returns this
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.setFragmentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_fragments = 3;
 * @return {number}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.getTotalFragments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WebRTCDataChannelDataChunk} returns this
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.setTotalFragments = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bytes data = 4;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes data = 4;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.WebRTCDataChannelDataChunk} returns this
 */
proto.vivacity.core.WebRTCDataChannelDataChunk.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCICECandidate.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCICECandidate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCICECandidate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCICECandidate.toObject = function(includeInstance, msg) {
  var f, obj = {
    candidate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sdpmlineindex: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCICECandidate}
 */
proto.vivacity.core.WebRTCICECandidate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCICECandidate;
  return proto.vivacity.core.WebRTCICECandidate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCICECandidate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCICECandidate}
 */
proto.vivacity.core.WebRTCICECandidate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCandidate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSdpmlineindex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCICECandidate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCICECandidate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCICECandidate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCICECandidate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCandidate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSdpmlineindex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string candidate = 1;
 * @return {string}
 */
proto.vivacity.core.WebRTCICECandidate.prototype.getCandidate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCICECandidate} returns this
 */
proto.vivacity.core.WebRTCICECandidate.prototype.setCandidate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 sdpMLineIndex = 2;
 * @return {number}
 */
proto.vivacity.core.WebRTCICECandidate.prototype.getSdpmlineindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WebRTCICECandidate} returns this
 */
proto.vivacity.core.WebRTCICECandidate.prototype.setSdpmlineindex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCSDP.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCSDP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCSDP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCSDP.toObject = function(includeInstance, msg) {
  var f, obj = {
    sdp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCSDP}
 */
proto.vivacity.core.WebRTCSDP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCSDP;
  return proto.vivacity.core.WebRTCSDP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCSDP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCSDP}
 */
proto.vivacity.core.WebRTCSDP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdp(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.WebRTCSDP.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCSDP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCSDP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCSDP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCSDP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSdp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.WebRTCSDP.Type = {
  UNKNOWN: 0,
  SDPTYPEOFFER: 1,
  SDPTYPEPRANSWER: 2,
  SDPTYPEANSWER: 3,
  SDPTYPEROLLBACK: 4
};

/**
 * optional string sdp = 1;
 * @return {string}
 */
proto.vivacity.core.WebRTCSDP.prototype.getSdp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCSDP} returns this
 */
proto.vivacity.core.WebRTCSDP.prototype.setSdp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.vivacity.core.WebRTCSDP.Type}
 */
proto.vivacity.core.WebRTCSDP.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.WebRTCSDP.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.WebRTCSDP.Type} value
 * @return {!proto.vivacity.core.WebRTCSDP} returns this
 */
proto.vivacity.core.WebRTCSDP.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCSessionNegotiation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCSessionNegotiation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCSessionNegotiation.toObject = function(includeInstance, msg) {
  var f, obj = {
    sdp: (f = msg.getSdp()) && proto.vivacity.core.WebRTCSDP.toObject(includeInstance, f),
    iceCandidate: (f = msg.getIceCandidate()) && proto.vivacity.core.WebRTCICECandidate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCSessionNegotiation}
 */
proto.vivacity.core.WebRTCSessionNegotiation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCSessionNegotiation;
  return proto.vivacity.core.WebRTCSessionNegotiation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCSessionNegotiation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCSessionNegotiation}
 */
proto.vivacity.core.WebRTCSessionNegotiation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.WebRTCSDP;
      reader.readMessage(value,proto.vivacity.core.WebRTCSDP.deserializeBinaryFromReader);
      msg.setSdp(value);
      break;
    case 2:
      var value = new proto.vivacity.core.WebRTCICECandidate;
      reader.readMessage(value,proto.vivacity.core.WebRTCICECandidate.deserializeBinaryFromReader);
      msg.setIceCandidate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCSessionNegotiation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCSessionNegotiation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCSessionNegotiation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSdp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.WebRTCSDP.serializeBinaryToWriter
    );
  }
  f = message.getIceCandidate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.WebRTCICECandidate.serializeBinaryToWriter
    );
  }
};


/**
 * optional WebRTCSDP sdp = 1;
 * @return {?proto.vivacity.core.WebRTCSDP}
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.getSdp = function() {
  return /** @type{?proto.vivacity.core.WebRTCSDP} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCSDP, 1));
};


/**
 * @param {?proto.vivacity.core.WebRTCSDP|undefined} value
 * @return {!proto.vivacity.core.WebRTCSessionNegotiation} returns this
*/
proto.vivacity.core.WebRTCSessionNegotiation.prototype.setSdp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSessionNegotiation} returns this
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.clearSdp = function() {
  return this.setSdp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.hasSdp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WebRTCICECandidate ice_candidate = 2;
 * @return {?proto.vivacity.core.WebRTCICECandidate}
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.getIceCandidate = function() {
  return /** @type{?proto.vivacity.core.WebRTCICECandidate} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.WebRTCICECandidate, 2));
};


/**
 * @param {?proto.vivacity.core.WebRTCICECandidate|undefined} value
 * @return {!proto.vivacity.core.WebRTCSessionNegotiation} returns this
*/
proto.vivacity.core.WebRTCSessionNegotiation.prototype.setIceCandidate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WebRTCSessionNegotiation} returns this
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.clearIceCandidate = function() {
  return this.setIceCandidate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WebRTCSessionNegotiation.prototype.hasIceCandidate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCConnectionError.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCConnectionError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCConnectionError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCConnectionError.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCConnectionError}
 */
proto.vivacity.core.WebRTCConnectionError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCConnectionError;
  return proto.vivacity.core.WebRTCConnectionError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCConnectionError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCConnectionError}
 */
proto.vivacity.core.WebRTCConnectionError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.WebRTCConnectionError.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCConnectionError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCConnectionError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCConnectionError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCConnectionError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.WebRTCConnectionError.Type = {
  UNKNOWN: 0,
  FAILED_TO_CLOSE_PEER_CONNECTION: 1,
  FAILED_TO_ADD_ICE_CANDIDATE: 2,
  FAILED_TO_SET_REMOTE_DESCRIPTION: 3,
  FAILED_TO_SET_LOCAL_DESCRIPTION: 4,
  FAILED_TO_CREATE_SDP_OFFER: 5,
  FAILED_TO_CREATE_SDP_ANSWER: 6,
  NO_SUCH_PEER_ID: 7,
  FAILED_TO_CREATE_PEER_CONNECTION: 8,
  NO_SUCH_VIDEO_SOURCE: 9,
  FAILED_TO_CREATE_TRACK: 10,
  FAILED_TO_ADD_TRACK: 11,
  NO_SUCH_DATA_CHANNEL_SOURCE_SINK: 12,
  FAILED_TO_CREATE_DATA_CHANNEL: 13,
  NO_SUCH_VIDEO_SINK: 14,
  FAILED_TO_SEND_RTCP_PLI: 16,
  VIDEO_SINK_ALREADY_IN_USE: 17,
  FAILED_TO_READ_REMOTE_TRACK: 18,
  UNAUTHORISED_ACCESS_TO_DATA_CHANNEL: 19,
  DATA_CHANNEL_ERROR: 20,
  AUTHENTICATION_FAILED: 21,
  UNAUTHORIZED: 22,
  NO_SUCH_PRINCIPAL_ID: 23
};

/**
 * optional Type type = 1;
 * @return {!proto.vivacity.core.WebRTCConnectionError.Type}
 */
proto.vivacity.core.WebRTCConnectionError.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.WebRTCConnectionError.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.WebRTCConnectionError.Type} value
 * @return {!proto.vivacity.core.WebRTCConnectionError} returns this
 */
proto.vivacity.core.WebRTCConnectionError.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCConnectionError.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCConnectionError} returns this
 */
proto.vivacity.core.WebRTCConnectionError.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.vivacity.core.WebRTCConnectionError.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCConnectionError} returns this
 */
proto.vivacity.core.WebRTCConnectionError.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCStateUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCStateUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCStateUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataChannelLabel: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCStateUpdate}
 */
proto.vivacity.core.WebRTCStateUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCStateUpdate;
  return proto.vivacity.core.WebRTCStateUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCStateUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCStateUpdate}
 */
proto.vivacity.core.WebRTCStateUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.WebRTCStateUpdate.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataChannelLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCStateUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCStateUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCStateUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataChannelLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.WebRTCStateUpdate.Type = {
  UNKNOWN: 0,
  ICE_CONNECTION_STATE: 1,
  SIGNALLING_STATE: 2,
  PEER_CONNECTION_STATE: 3,
  ICE_GATHERER_STATE: 4,
  DATA_CHANNEL_STATE: 5
};

/**
 * optional Type type = 1;
 * @return {!proto.vivacity.core.WebRTCStateUpdate.Type}
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.WebRTCStateUpdate.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.WebRTCStateUpdate.Type} value
 * @return {!proto.vivacity.core.WebRTCStateUpdate} returns this
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCStateUpdate} returns this
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string data_channel_label = 3;
 * @return {string}
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.getDataChannelLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCStateUpdate} returns this
 */
proto.vivacity.core.WebRTCStateUpdate.prototype.setDataChannelLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WebRTCRequestPeerByPrincipalID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.toObject = function(includeInstance, msg) {
  var f, obj = {
    principalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jwt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataChannelsList: jspb.Message.toObjectList(msg.getDataChannelsList(),
    proto.vivacity.core.WebRTCDataChannelRequest.toObject, includeInstance),
    tracksList: jspb.Message.toObjectList(msg.getTracksList(),
    proto.vivacity.core.WebRTCTrackRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WebRTCRequestPeerByPrincipalID;
  return proto.vivacity.core.WebRTCRequestPeerByPrincipalID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    case 3:
      var value = new proto.vivacity.core.WebRTCDataChannelRequest;
      reader.readMessage(value,proto.vivacity.core.WebRTCDataChannelRequest.deserializeBinaryFromReader);
      msg.addDataChannels(value);
      break;
    case 4:
      var value = new proto.vivacity.core.WebRTCTrackRequest;
      reader.readMessage(value,proto.vivacity.core.WebRTCTrackRequest.deserializeBinaryFromReader);
      msg.addTracks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WebRTCRequestPeerByPrincipalID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrincipalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.core.WebRTCDataChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getTracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vivacity.core.WebRTCTrackRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string principal_id = 1;
 * @return {string}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.getPrincipalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} returns this
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.setPrincipalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string jwt = 2;
 * @return {string}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} returns this
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated WebRTCDataChannelRequest data_channels = 3;
 * @return {!Array<!proto.vivacity.core.WebRTCDataChannelRequest>}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.getDataChannelsList = function() {
  return /** @type{!Array<!proto.vivacity.core.WebRTCDataChannelRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.WebRTCDataChannelRequest, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.WebRTCDataChannelRequest>} value
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} returns this
*/
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.setDataChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.WebRTCDataChannelRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WebRTCDataChannelRequest}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.addDataChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.WebRTCDataChannelRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} returns this
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.clearDataChannelsList = function() {
  return this.setDataChannelsList([]);
};


/**
 * repeated WebRTCTrackRequest tracks = 4;
 * @return {!Array<!proto.vivacity.core.WebRTCTrackRequest>}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.getTracksList = function() {
  return /** @type{!Array<!proto.vivacity.core.WebRTCTrackRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.WebRTCTrackRequest, 4));
};


/**
 * @param {!Array<!proto.vivacity.core.WebRTCTrackRequest>} value
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} returns this
*/
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.setTracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vivacity.core.WebRTCTrackRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WebRTCTrackRequest}
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.addTracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vivacity.core.WebRTCTrackRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WebRTCRequestPeerByPrincipalID} returns this
 */
proto.vivacity.core.WebRTCRequestPeerByPrincipalID.prototype.clearTracksList = function() {
  return this.setTracksList([]);
};


goog.object.extend(exports, proto.vivacity.core);
