import React, {Fragment, PropsWithChildren} from "react";
import {inject, observer} from "mobx-react";
import {Text} from "react-pixi-fiber";
import OccupancyZone from "./OccupancyZone";
import DetectionBoxes from "./DetectionBoxes";
import StoreRoot from "../../../stores/StoreRoot";
import * as PIXI from "pixi.js";

type DetectorTrackerFrameViewProps = {
    visionProgramID: number
    appState: StoreRoot
    showBoxes: boolean
    showOccupancyZones: boolean
    showLabels: boolean
    showOccupancyPoints: boolean
    showOnlyStopped: boolean
    showPredicted: boolean
}

const DetectorTrackerFrameView: React.FunctionComponent<PropsWithChildren<DetectorTrackerFrameViewProps>> = inject("appState")(observer((props: PropsWithChildren<DetectorTrackerFrameViewProps>) => {
    const occupancyZonesForVisionProgramID = props.appState.DetectorTrackerFrames.occupancyZoneDefinitions.get(props.visionProgramID);
    let occupancyZoneIDs: number[] = [];
    if (occupancyZonesForVisionProgramID) {
        occupancyZonesForVisionProgramID.forEach((zoneDef, zoneID) => {
            occupancyZoneIDs.push(zoneID);
        })
    }

    const occupancyZones = occupancyZoneIDs.map((zoneID) => {
        return <OccupancyZone appState={props.appState} visionProgramId={props.visionProgramID} occupancyZoneId={zoneID}
                              key={zoneID}/>
    });


    let maybeFPS = props.appState.DetectorTrackerFrames.latestFPS.get(props.visionProgramID);
    let maybeLatency = props.appState.DetectorTrackerFrames.latestFrameAvgLatencies.get(props.visionProgramID);
    let maybeJitter = props.appState.DetectorTrackerFrames.latestFrameJitter.get(props.visionProgramID);

    return (
        <Fragment>
            {props.showOccupancyZones ? occupancyZones : null}
            {props.showBoxes ? (<DetectionBoxes appState={props.appState} visionProgramId={props.visionProgramID}
                                                showLabel={props.showLabels}
                                                showOccupancyPoints={props.showOccupancyPoints}
                                                showOnlyStopped={props.showOnlyStopped}
                                                showPredicted={props.showPredicted}/>) : null}
            {maybeFPS ? (<Text
                text={"FPS:     " + Math.round(maybeFPS * 10) / 10}
                x={0}
                y={330}
                style={new PIXI.TextStyle({fontSize: 10, fontFamily: "monospace"})}
            />) : null}
            {maybeLatency ? (<Text
                text={"Latency: " + Math.round(maybeLatency / 100) / 10 + "ms"}
                x={0}
                y={340}
                style={new PIXI.TextStyle({fontSize: 10, fontFamily: "monospace"})}
            />) : null}
            {maybeJitter ? (<Text
                text={"Jitter:  " + Math.round(maybeJitter / 100) / 10 + "ms"}
                x={0}
                y={350}
                style={new PIXI.TextStyle({fontSize: 10, fontFamily: "monospace"})}
            />) : null}
        </Fragment>
    );
}));

export default DetectorTrackerFrameView