import StoreRoot from "../../../../stores/StoreRoot";
import React, {Fragment, PropsWithChildren} from "react";
import {inject, observer} from "mobx-react";
import {crc24} from "crc";
import {ClassifyingDetectorClassTypes, ClassifyingDetectorClassTypesMap} from "../../../../vivacity/core/classifying_detector_class_types_pb";
import Rectangle from "../../../common/pixi/Rectangle";
import Circle from "../../../common/pixi/Circle";
import {Text} from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import _ from "lodash";
import {DetectionBox} from "../../../../vivacity/core/detection_box_pb";
import {Point} from "../../../../vivacity/core/point_pb";

type DetectionBoxesProps = {
    appState: StoreRoot
    visionProgramId: number
    showLabel: boolean
    showOccupancyPoints: boolean
    showOnlyStopped: boolean
    showPredicted: boolean
}

type BoxDetails = {
    x: number
    y: number
    width: number,
    height: number
    occupancyPoints: JSX.Element[]
    text: string
    lineColor: number
    fillColor: number
    isStopped: boolean
    isPredicted: boolean
}

const classLookup = Object.fromEntries(Object.entries(ClassifyingDetectorClassTypes).map(([className, enumValue]) =>
    [enumValue, _.startCase(className.toLowerCase())]
));

function getBoxDetails(box: DetectionBox, trackClass: ClassifyingDetectorClassTypesMap[keyof ClassifyingDetectorClassTypesMap], trackNumber: number, isStopped: boolean, isPredicted: boolean): BoxDetails {
    let text: string;
    let x = 0;
    let y = 0;
    let width = 0;
    let height = 0;
    let occupancyZonePoints;

    const topLeft = box.getTopLeft();
    const bottomRight = box.getBottomRight();
    if (topLeft && bottomRight) {
        x = topLeft.getX();
        y = topLeft.getY();
        width = bottomRight.getX() - x;
        height = bottomRight.getY() - y;
    }


    let boxClass;
    if (trackClass === 0) {
        boxClass = box.getDetectionClass()
    } else {
        boxClass = trackClass;
    }
    text = isStopped ? "STOPPED " + classLookup[boxClass] : classLookup[boxClass] + " " + trackNumber.toString();

    occupancyZonePoints = box.getOccupancyZonePointsList().map((point: Point, index: number) => {
        return (<Circle
            key={index}
            x={point.getX()} y={point.getY()} radius={1.2} fillColor={0x555555} lineColor={0x333333} lineWidth={0}/>)
    });

    return {
        x: x,
        y: y,
        width: width,
        height: height,
        occupancyPoints: occupancyZonePoints,
        text: text,
        lineColor: isPredicted ? 0xFFFFFF : 0x000000,
        fillColor: isStopped ? 0xFF0000 : crc24(trackNumber.toString()),
        isStopped: isStopped,
        isPredicted: isPredicted,
    }
}

function getBoxTextAndPoints(boxDetails: BoxDetails, visionProgramID: number, i: number, showLabel: boolean, showOccupancyPoints: boolean, showOnlyStopped: boolean, showPredicted: boolean): JSX.Element {
    if (showOnlyStopped && !boxDetails.isStopped) {
        return (<Fragment key={visionProgramID + "-fragment-" + i}/>);
    } else if (!showPredicted && boxDetails.isPredicted) {
        return (<Fragment key={visionProgramID + "-fragment-" + i}/>);
    } else {
        return (
            <Fragment key={visionProgramID + "-fragment-" + i}>
                <Rectangle
                    key={visionProgramID + "-rectangle-" + i}
                    x={boxDetails.x}
                    y={boxDetails.y}
                    width={boxDetails.width}
                    height={boxDetails.height}
                    fillColor={boxDetails.fillColor}
                    lineColor={boxDetails.lineColor}
                    lineWidth={2}
                    alpha={0.2}
                />
                {showLabel ? (
                    <Text
                        key={visionProgramID + "-text-" + i}
                        text={boxDetails.text}
                        x={boxDetails.x}
                        y={boxDetails.y}
                        style={new PIXI.TextStyle({fill: 0x333333, fontSize: 20})}
                    />
                ) : null}

                {showOccupancyPoints ? boxDetails.occupancyPoints : null}
            </Fragment>
        );
    }
}

const DetectionBoxes: React.FunctionComponent<PropsWithChildren<DetectionBoxesProps>> = inject("appState")(observer((props: PropsWithChildren<DetectionBoxesProps>) => {
    const trackSegments = props.appState.DetectorTrackerFrames.latestTrackSegments.get(props.visionProgramId);
    const trackHeads = props.appState.DetectorTrackerFrames.latestTrackHeads.get(props.visionProgramId);

    let boxes = null;

    if (trackSegments && trackSegments.length > 0) {
        boxes = trackSegments.map((trackSegment, i) => {
            const trackHead = trackSegment.getTrackHeadEnd();
            if (trackHead) {
                const box = trackHead.getDetectionBox();
                if (box) {
                    const boxDetails = getBoxDetails(box, trackHead.getTrackClass(), trackHead.getTrackNumber(), trackHead.getIsStopped(), trackHead.getIsPredicted());
                    return getBoxTextAndPoints(boxDetails, props.visionProgramId, i, props.showLabel, props.showOccupancyPoints, props.showOnlyStopped, props.showPredicted);
                }
            }
            return null;
        })
    } else if (trackHeads) {
        boxes = trackHeads.map((trackHead, i) => {
            const box = trackHead.getDetectionBox();
            if (box) {
                const boxDetails = getBoxDetails(box, trackHead.getTrackClass(), trackHead.getTrackNumber(), trackHead.getIsStopped(), trackHead.getIsPredicted());
                return getBoxTextAndPoints(boxDetails, props.visionProgramId, i, props.showLabel, props.showOccupancyPoints, props.showOnlyStopped, props.showPredicted);
            }
            return null;
        })
    }
    return (
        <Fragment>
            {boxes}
            {props.children}
        </Fragment>
    );
}));

export default DetectionBoxes