/* eslint-disable */
// source: vivacity/universal_envelope.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_config_config_message_pb = require('../vivacity/config/config_message_pb.js');
goog.object.extend(proto, vivacity_config_config_message_pb);
var vivacity_config_pipeline_pb = require('../vivacity/config/pipeline_pb.js');
goog.object.extend(proto, vivacity_config_pipeline_pb);
var vivacity_config_zone_config_pb = require('../vivacity/config/zone_config_pb.js');
goog.object.extend(proto, vivacity_config_zone_config_pb);
var vivacity_config_v2_crud_operation_message_pb = require('../vivacity/config_v2/crud_operation_message_pb.js');
goog.object.extend(proto, vivacity_config_v2_crud_operation_message_pb);
var vivacity_core_alerts_pb = require('../vivacity/core/alerts_pb.js');
goog.object.extend(proto, vivacity_core_alerts_pb);
var vivacity_core_anpr_journey_pb = require('../vivacity/core/anpr_journey_pb.js');
goog.object.extend(proto, vivacity_core_anpr_journey_pb);
var vivacity_core_authentication_pb = require('../vivacity/core/authentication_pb.js');
goog.object.extend(proto, vivacity_core_authentication_pb);
var vivacity_core_authorization_pb = require('../vivacity/core/authorization_pb.js');
goog.object.extend(proto, vivacity_core_authorization_pb);
var vivacity_core_countline_crossing_pb = require('../vivacity/core/countline_crossing_pb.js');
goog.object.extend(proto, vivacity_core_countline_crossing_pb);
var vivacity_core_detector_tracker_frame_pb = require('../vivacity/core/detector_tracker_frame_pb.js');
goog.object.extend(proto, vivacity_core_detector_tracker_frame_pb);
var vivacity_core_hardware_heartbeat_pb = require('../vivacity/core/hardware_heartbeat_pb.js');
goog.object.extend(proto, vivacity_core_hardware_heartbeat_pb);
var vivacity_core_hardware_reboot_pb = require('../vivacity/core/hardware_reboot_pb.js');
goog.object.extend(proto, vivacity_core_hardware_reboot_pb);
var vivacity_core_http_server_event_pb = require('../vivacity/core/http_server_event_pb.js');
goog.object.extend(proto, vivacity_core_http_server_event_pb);
var vivacity_core_occupancy_based_turning_movement_pb = require('../vivacity/core/occupancy_based_turning_movement_pb.js');
goog.object.extend(proto, vivacity_core_occupancy_based_turning_movement_pb);
var vivacity_core_snappi_pb = require('../vivacity/core/snappi_pb.js');
goog.object.extend(proto, vivacity_core_snappi_pb);
var vivacity_core_tracing_context_pb = require('../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_track_head_pb = require('../vivacity/core/track_head_pb.js');
goog.object.extend(proto, vivacity_core_track_head_pb);
var vivacity_core_trigger_zone_crossing_pb = require('../vivacity/core/trigger_zone_crossing_pb.js');
goog.object.extend(proto, vivacity_core_trigger_zone_crossing_pb);
var vivacity_core_vision_program_windowed_features_pb = require('../vivacity/core/vision_program_windowed_features_pb.js');
goog.object.extend(proto, vivacity_core_vision_program_windowed_features_pb);
var vivacity_core_viu_message_pb = require('../vivacity/core/viu_message_pb.js');
goog.object.extend(proto, vivacity_core_viu_message_pb);
var vivacity_core_windowed_state_pb = require('../vivacity/core/windowed_state_pb.js');
goog.object.extend(proto, vivacity_core_windowed_state_pb);
var vivacity_core_zonal_windowed_features_pb = require('../vivacity/core/zonal_windowed_features_pb.js');
goog.object.extend(proto, vivacity_core_zonal_windowed_features_pb);
var vivacity_core_zonal_windowed_occupancy_pb = require('../vivacity/core/zonal_windowed_occupancy_pb.js');
goog.object.extend(proto, vivacity_core_zonal_windowed_occupancy_pb);
var vivacity_core_zonal_windowed_speed_pb = require('../vivacity/core/zonal_windowed_speed_pb.js');
goog.object.extend(proto, vivacity_core_zonal_windowed_speed_pb);
goog.exportSymbol('proto.vivacity.UniversalEnvelope', null, global);
goog.exportSymbol('proto.vivacity.UniversalEnvelope.MessageType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.UniversalEnvelope = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.UniversalEnvelope, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.UniversalEnvelope.displayName = 'proto.vivacity.UniversalEnvelope';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.UniversalEnvelope.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.UniversalEnvelope.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.UniversalEnvelope} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.UniversalEnvelope.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    viuMessage: (f = msg.getViuMessage()) && vivacity_core_viu_message_pb.VIUMessage.toObject(includeInstance, f),
    detectorTrackerFrame: (f = msg.getDetectorTrackerFrame()) && vivacity_core_detector_tracker_frame_pb.DetectorTrackerFrame.toObject(includeInstance, f),
    alertMessage: (f = msg.getAlertMessage()) && vivacity_core_alerts_pb.AlertMessage.toObject(includeInstance, f),
    alertNotificationBatch: (f = msg.getAlertNotificationBatch()) && vivacity_core_alerts_pb.AlertNotificationBatch.toObject(includeInstance, f),
    hardwareHeartbeat: (f = msg.getHardwareHeartbeat()) && vivacity_core_hardware_heartbeat_pb.HardwareHeartbeat.toObject(includeInstance, f),
    countlineCrossing: (f = msg.getCountlineCrossing()) && vivacity_core_countline_crossing_pb.CountlineCrossing.toObject(includeInstance, f),
    occupancyBasedTurningMovement: (f = msg.getOccupancyBasedTurningMovement()) && vivacity_core_occupancy_based_turning_movement_pb.OccupancyBasedTurningMovement.toObject(includeInstance, f),
    zonalWindowedOccupancy: (f = msg.getZonalWindowedOccupancy()) && vivacity_core_zonal_windowed_occupancy_pb.ZonalWindowedOccupancy.toObject(includeInstance, f),
    zonalWindowedSpeed: (f = msg.getZonalWindowedSpeed()) && vivacity_core_zonal_windowed_speed_pb.ZonalWindowedSpeed.toObject(includeInstance, f),
    zonalWindowedFeatures: (f = msg.getZonalWindowedFeatures()) && vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures.toObject(includeInstance, f),
    triggerZoneCrossing: (f = msg.getTriggerZoneCrossing()) && vivacity_core_trigger_zone_crossing_pb.TriggerZoneCrossing.toObject(includeInstance, f),
    windowedState: (f = msg.getWindowedState()) && vivacity_core_windowed_state_pb.WindowedState.toObject(includeInstance, f),
    nearMissEvent: (f = msg.getNearMissEvent()) && vivacity_core_track_head_pb.NearMissEvent.toObject(includeInstance, f),
    visionProgramWindowedFeatures: (f = msg.getVisionProgramWindowedFeatures()) && vivacity_core_vision_program_windowed_features_pb.VisionProgramWindowedFeatures.toObject(includeInstance, f),
    nearMissEventV2: (f = msg.getNearMissEventV2()) && vivacity_core_track_head_pb.NearMissEventV2.toObject(includeInstance, f),
    reconfigurePipeline: (f = msg.getReconfigurePipeline()) && vivacity_config_pipeline_pb.ReconfigurePipeline.toObject(includeInstance, f),
    pipelineReconfigured: (f = msg.getPipelineReconfigured()) && vivacity_config_pipeline_pb.PipelineReconfigured.toObject(includeInstance, f),
    zonesConfigChange: (f = msg.getZonesConfigChange()) && vivacity_config_zone_config_pb.ZonesConfigChange.toObject(includeInstance, f),
    configMessage: (f = msg.getConfigMessage()) && vivacity_config_config_message_pb.ConfigMessage.toObject(includeInstance, f),
    configError: (f = msg.getConfigError()) && vivacity_config_config_message_pb.ConfigError.toObject(includeInstance, f),
    transactionalCRUDOperations: (f = msg.getTransactionalCRUDOperations()) && vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.toObject(includeInstance, f),
    commandGroupRequest: (f = msg.getCommandGroupRequest()) && vivacity_config_config_message_pb.CommandGroupRequest.toObject(includeInstance, f),
    commandRequest: (f = msg.getCommandRequest()) && vivacity_config_config_message_pb.CommandRequest.toObject(includeInstance, f),
    hardwareReboot: (f = msg.getHardwareReboot()) && vivacity_core_hardware_reboot_pb.HardwareReboot.toObject(includeInstance, f),
    snappiVideoCapture: (f = msg.getSnappiVideoCapture()) && vivacity_core_snappi_pb.SnappiVideoCapture.toObject(includeInstance, f),
    anprJourney: (f = msg.getAnprJourney()) && vivacity_core_anpr_journey_pb.AnprJourney.toObject(includeInstance, f),
    httpServerEvent: (f = msg.getHttpServerEvent()) && vivacity_core_http_server_event_pb.HttpServerEvent.toObject(includeInstance, f),
    authenticationMessage: (f = msg.getAuthenticationMessage()) && vivacity_core_authentication_pb.AuthenticationMessage.toObject(includeInstance, f),
    authorizationMessage: (f = msg.getAuthorizationMessage()) && vivacity_core_authorization_pb.AuthorizationMessage.toObject(includeInstance, f),
    rawBytesMessage: msg.getRawBytesMessage_asB64(),
    rawStringMessage: jspb.Message.getFieldWithDefault(msg, 101, ""),
    jsonMessage: jspb.Message.getFieldWithDefault(msg, 102, ""),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    lastConsumedKafkaOffset: jspb.Message.getFieldWithDefault(msg, 120, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.UniversalEnvelope}
 */
proto.vivacity.UniversalEnvelope.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.UniversalEnvelope;
  return proto.vivacity.UniversalEnvelope.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.UniversalEnvelope} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.UniversalEnvelope}
 */
proto.vivacity.UniversalEnvelope.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.UniversalEnvelope.MessageType} */ (reader.readEnum());
      msg.setMessageType(value);
      break;
    case 11:
      var value = new vivacity_core_viu_message_pb.VIUMessage;
      reader.readMessage(value,vivacity_core_viu_message_pb.VIUMessage.deserializeBinaryFromReader);
      msg.setViuMessage(value);
      break;
    case 12:
      var value = new vivacity_core_detector_tracker_frame_pb.DetectorTrackerFrame;
      reader.readMessage(value,vivacity_core_detector_tracker_frame_pb.DetectorTrackerFrame.deserializeBinaryFromReader);
      msg.setDetectorTrackerFrame(value);
      break;
    case 13:
      var value = new vivacity_core_alerts_pb.AlertMessage;
      reader.readMessage(value,vivacity_core_alerts_pb.AlertMessage.deserializeBinaryFromReader);
      msg.setAlertMessage(value);
      break;
    case 14:
      var value = new vivacity_core_alerts_pb.AlertNotificationBatch;
      reader.readMessage(value,vivacity_core_alerts_pb.AlertNotificationBatch.deserializeBinaryFromReader);
      msg.setAlertNotificationBatch(value);
      break;
    case 15:
      var value = new vivacity_core_hardware_heartbeat_pb.HardwareHeartbeat;
      reader.readMessage(value,vivacity_core_hardware_heartbeat_pb.HardwareHeartbeat.deserializeBinaryFromReader);
      msg.setHardwareHeartbeat(value);
      break;
    case 20:
      var value = new vivacity_core_countline_crossing_pb.CountlineCrossing;
      reader.readMessage(value,vivacity_core_countline_crossing_pb.CountlineCrossing.deserializeBinaryFromReader);
      msg.setCountlineCrossing(value);
      break;
    case 21:
      var value = new vivacity_core_occupancy_based_turning_movement_pb.OccupancyBasedTurningMovement;
      reader.readMessage(value,vivacity_core_occupancy_based_turning_movement_pb.OccupancyBasedTurningMovement.deserializeBinaryFromReader);
      msg.setOccupancyBasedTurningMovement(value);
      break;
    case 22:
      var value = new vivacity_core_zonal_windowed_occupancy_pb.ZonalWindowedOccupancy;
      reader.readMessage(value,vivacity_core_zonal_windowed_occupancy_pb.ZonalWindowedOccupancy.deserializeBinaryFromReader);
      msg.setZonalWindowedOccupancy(value);
      break;
    case 23:
      var value = new vivacity_core_zonal_windowed_speed_pb.ZonalWindowedSpeed;
      reader.readMessage(value,vivacity_core_zonal_windowed_speed_pb.ZonalWindowedSpeed.deserializeBinaryFromReader);
      msg.setZonalWindowedSpeed(value);
      break;
    case 24:
      var value = new vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures;
      reader.readMessage(value,vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures.deserializeBinaryFromReader);
      msg.setZonalWindowedFeatures(value);
      break;
    case 25:
      var value = new vivacity_core_trigger_zone_crossing_pb.TriggerZoneCrossing;
      reader.readMessage(value,vivacity_core_trigger_zone_crossing_pb.TriggerZoneCrossing.deserializeBinaryFromReader);
      msg.setTriggerZoneCrossing(value);
      break;
    case 26:
      var value = new vivacity_core_windowed_state_pb.WindowedState;
      reader.readMessage(value,vivacity_core_windowed_state_pb.WindowedState.deserializeBinaryFromReader);
      msg.setWindowedState(value);
      break;
    case 27:
      var value = new vivacity_core_track_head_pb.NearMissEvent;
      reader.readMessage(value,vivacity_core_track_head_pb.NearMissEvent.deserializeBinaryFromReader);
      msg.setNearMissEvent(value);
      break;
    case 28:
      var value = new vivacity_core_vision_program_windowed_features_pb.VisionProgramWindowedFeatures;
      reader.readMessage(value,vivacity_core_vision_program_windowed_features_pb.VisionProgramWindowedFeatures.deserializeBinaryFromReader);
      msg.setVisionProgramWindowedFeatures(value);
      break;
    case 29:
      var value = new vivacity_core_track_head_pb.NearMissEventV2;
      reader.readMessage(value,vivacity_core_track_head_pb.NearMissEventV2.deserializeBinaryFromReader);
      msg.setNearMissEventV2(value);
      break;
    case 30:
      var value = new vivacity_config_pipeline_pb.ReconfigurePipeline;
      reader.readMessage(value,vivacity_config_pipeline_pb.ReconfigurePipeline.deserializeBinaryFromReader);
      msg.setReconfigurePipeline(value);
      break;
    case 31:
      var value = new vivacity_config_pipeline_pb.PipelineReconfigured;
      reader.readMessage(value,vivacity_config_pipeline_pb.PipelineReconfigured.deserializeBinaryFromReader);
      msg.setPipelineReconfigured(value);
      break;
    case 50:
      var value = new vivacity_config_zone_config_pb.ZonesConfigChange;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonesConfigChange.deserializeBinaryFromReader);
      msg.setZonesConfigChange(value);
      break;
    case 51:
      var value = new vivacity_config_config_message_pb.ConfigMessage;
      reader.readMessage(value,vivacity_config_config_message_pb.ConfigMessage.deserializeBinaryFromReader);
      msg.setConfigMessage(value);
      break;
    case 52:
      var value = new vivacity_config_config_message_pb.ConfigError;
      reader.readMessage(value,vivacity_config_config_message_pb.ConfigError.deserializeBinaryFromReader);
      msg.setConfigError(value);
      break;
    case 55:
      var value = new vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations;
      reader.readMessage(value,vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.deserializeBinaryFromReader);
      msg.setTransactionalCRUDOperations(value);
      break;
    case 61:
      var value = new vivacity_config_config_message_pb.CommandGroupRequest;
      reader.readMessage(value,vivacity_config_config_message_pb.CommandGroupRequest.deserializeBinaryFromReader);
      msg.setCommandGroupRequest(value);
      break;
    case 62:
      var value = new vivacity_config_config_message_pb.CommandRequest;
      reader.readMessage(value,vivacity_config_config_message_pb.CommandRequest.deserializeBinaryFromReader);
      msg.setCommandRequest(value);
      break;
    case 71:
      var value = new vivacity_core_hardware_reboot_pb.HardwareReboot;
      reader.readMessage(value,vivacity_core_hardware_reboot_pb.HardwareReboot.deserializeBinaryFromReader);
      msg.setHardwareReboot(value);
      break;
    case 80:
      var value = new vivacity_core_snappi_pb.SnappiVideoCapture;
      reader.readMessage(value,vivacity_core_snappi_pb.SnappiVideoCapture.deserializeBinaryFromReader);
      msg.setSnappiVideoCapture(value);
      break;
    case 81:
      var value = new vivacity_core_anpr_journey_pb.AnprJourney;
      reader.readMessage(value,vivacity_core_anpr_journey_pb.AnprJourney.deserializeBinaryFromReader);
      msg.setAnprJourney(value);
      break;
    case 90:
      var value = new vivacity_core_http_server_event_pb.HttpServerEvent;
      reader.readMessage(value,vivacity_core_http_server_event_pb.HttpServerEvent.deserializeBinaryFromReader);
      msg.setHttpServerEvent(value);
      break;
    case 91:
      var value = new vivacity_core_authentication_pb.AuthenticationMessage;
      reader.readMessage(value,vivacity_core_authentication_pb.AuthenticationMessage.deserializeBinaryFromReader);
      msg.setAuthenticationMessage(value);
      break;
    case 92:
      var value = new vivacity_core_authorization_pb.AuthorizationMessage;
      reader.readMessage(value,vivacity_core_authorization_pb.AuthorizationMessage.deserializeBinaryFromReader);
      msg.setAuthorizationMessage(value);
      break;
    case 100:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRawBytesMessage(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawStringMessage(value);
      break;
    case 102:
      var value = /** @type {string} */ (reader.readString());
      msg.setJsonMessage(value);
      break;
    case 110:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 120:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastConsumedKafkaOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.UniversalEnvelope.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.UniversalEnvelope.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.UniversalEnvelope} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.UniversalEnvelope.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getViuMessage();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_core_viu_message_pb.VIUMessage.serializeBinaryToWriter
    );
  }
  f = message.getDetectorTrackerFrame();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_core_detector_tracker_frame_pb.DetectorTrackerFrame.serializeBinaryToWriter
    );
  }
  f = message.getAlertMessage();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_core_alerts_pb.AlertMessage.serializeBinaryToWriter
    );
  }
  f = message.getAlertNotificationBatch();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_core_alerts_pb.AlertNotificationBatch.serializeBinaryToWriter
    );
  }
  f = message.getHardwareHeartbeat();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_core_hardware_heartbeat_pb.HardwareHeartbeat.serializeBinaryToWriter
    );
  }
  f = message.getCountlineCrossing();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_core_countline_crossing_pb.CountlineCrossing.serializeBinaryToWriter
    );
  }
  f = message.getOccupancyBasedTurningMovement();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_core_occupancy_based_turning_movement_pb.OccupancyBasedTurningMovement.serializeBinaryToWriter
    );
  }
  f = message.getZonalWindowedOccupancy();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_core_zonal_windowed_occupancy_pb.ZonalWindowedOccupancy.serializeBinaryToWriter
    );
  }
  f = message.getZonalWindowedSpeed();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_core_zonal_windowed_speed_pb.ZonalWindowedSpeed.serializeBinaryToWriter
    );
  }
  f = message.getZonalWindowedFeatures();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures.serializeBinaryToWriter
    );
  }
  f = message.getTriggerZoneCrossing();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_core_trigger_zone_crossing_pb.TriggerZoneCrossing.serializeBinaryToWriter
    );
  }
  f = message.getWindowedState();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_core_windowed_state_pb.WindowedState.serializeBinaryToWriter
    );
  }
  f = message.getNearMissEvent();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      vivacity_core_track_head_pb.NearMissEvent.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramWindowedFeatures();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      vivacity_core_vision_program_windowed_features_pb.VisionProgramWindowedFeatures.serializeBinaryToWriter
    );
  }
  f = message.getNearMissEventV2();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      vivacity_core_track_head_pb.NearMissEventV2.serializeBinaryToWriter
    );
  }
  f = message.getReconfigurePipeline();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      vivacity_config_pipeline_pb.ReconfigurePipeline.serializeBinaryToWriter
    );
  }
  f = message.getPipelineReconfigured();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      vivacity_config_pipeline_pb.PipelineReconfigured.serializeBinaryToWriter
    );
  }
  f = message.getZonesConfigChange();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      vivacity_config_zone_config_pb.ZonesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getConfigMessage();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      vivacity_config_config_message_pb.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = message.getConfigError();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      vivacity_config_config_message_pb.ConfigError.serializeBinaryToWriter
    );
  }
  f = message.getTransactionalCRUDOperations();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations.serializeBinaryToWriter
    );
  }
  f = message.getCommandGroupRequest();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      vivacity_config_config_message_pb.CommandGroupRequest.serializeBinaryToWriter
    );
  }
  f = message.getCommandRequest();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      vivacity_config_config_message_pb.CommandRequest.serializeBinaryToWriter
    );
  }
  f = message.getHardwareReboot();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      vivacity_core_hardware_reboot_pb.HardwareReboot.serializeBinaryToWriter
    );
  }
  f = message.getSnappiVideoCapture();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      vivacity_core_snappi_pb.SnappiVideoCapture.serializeBinaryToWriter
    );
  }
  f = message.getAnprJourney();
  if (f != null) {
    writer.writeMessage(
      81,
      f,
      vivacity_core_anpr_journey_pb.AnprJourney.serializeBinaryToWriter
    );
  }
  f = message.getHttpServerEvent();
  if (f != null) {
    writer.writeMessage(
      90,
      f,
      vivacity_core_http_server_event_pb.HttpServerEvent.serializeBinaryToWriter
    );
  }
  f = message.getAuthenticationMessage();
  if (f != null) {
    writer.writeMessage(
      91,
      f,
      vivacity_core_authentication_pb.AuthenticationMessage.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizationMessage();
  if (f != null) {
    writer.writeMessage(
      92,
      f,
      vivacity_core_authorization_pb.AuthorizationMessage.serializeBinaryToWriter
    );
  }
  f = message.getRawBytesMessage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      100,
      f
    );
  }
  f = message.getRawStringMessage();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getJsonMessage();
  if (f.length > 0) {
    writer.writeString(
      102,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getLastConsumedKafkaOffset();
  if (f !== 0) {
    writer.writeUint64(
      120,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.UniversalEnvelope.MessageType = {
  UNKNOWN: 0,
  VIU_MESSAGE: 11,
  DETECTOR_TRACKER_FRAME: 12,
  ALERT_MESSAGE: 13,
  ALERT_NOTIFICATION_BATCH: 14,
  HARDWARE_HEARTBEAT: 15,
  COUNTLINE_CROSSING: 20,
  OCCUPANCY_BASED_TURNING_MOVEMENT: 21,
  ZONAL_WINDOWED_OCCUPANCY: 22,
  ZONAL_WINDOWED_SPEED: 23,
  ZONAL_WINDOWED_FEATURES: 24,
  TRIGGER_ZONE_CROSSING: 25,
  WINDOWED_STATE: 26,
  NEAR_MISS_EVENT: 27,
  VISION_PROGRAM_WINDOWED_FEATURES: 28,
  NEAR_MISS_EVENT_V2: 29,
  RECONFIGURE_PIPELINE: 30,
  PIPELINE_RECONFIGURED: 31,
  ZONES_CONFIG_CHANGE: 50,
  CONFIG_MESSAGE: 51,
  CONFIG_ERROR: 52,
  TRANSACTIONAL_CRUD_OPERATIONS: 55,
  COMMAND_GROUP_REQUEST: 61,
  COMMAND_REQUEST: 62,
  HARDWARE_REBOOT: 71,
  SNAPPI_VIDEO_CAPTURE: 80,
  ANPR_JOURNEY: 81,
  HTTP_SERVER_EVENT: 90,
  AUTHENTICATION_MESSAGE: 91,
  AUTHORIZATION_MESSAGE: 92,
  RAW_STRING_MESSAGE: 100,
  RAW_BYTES_MESSAGE: 101,
  JSON_MESSAGE: 102
};

/**
 * optional MessageType message_type = 1;
 * @return {!proto.vivacity.UniversalEnvelope.MessageType}
 */
proto.vivacity.UniversalEnvelope.prototype.getMessageType = function() {
  return /** @type {!proto.vivacity.UniversalEnvelope.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.UniversalEnvelope.MessageType} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional core.VIUMessage viu_message = 11;
 * @return {?proto.vivacity.core.VIUMessage}
 */
proto.vivacity.UniversalEnvelope.prototype.getViuMessage = function() {
  return /** @type{?proto.vivacity.core.VIUMessage} */ (
    jspb.Message.getWrapperField(this, vivacity_core_viu_message_pb.VIUMessage, 11));
};


/**
 * @param {?proto.vivacity.core.VIUMessage|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setViuMessage = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearViuMessage = function() {
  return this.setViuMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasViuMessage = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional core.DetectorTrackerFrame detector_tracker_frame = 12;
 * @return {?proto.vivacity.core.DetectorTrackerFrame}
 */
proto.vivacity.UniversalEnvelope.prototype.getDetectorTrackerFrame = function() {
  return /** @type{?proto.vivacity.core.DetectorTrackerFrame} */ (
    jspb.Message.getWrapperField(this, vivacity_core_detector_tracker_frame_pb.DetectorTrackerFrame, 12));
};


/**
 * @param {?proto.vivacity.core.DetectorTrackerFrame|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setDetectorTrackerFrame = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearDetectorTrackerFrame = function() {
  return this.setDetectorTrackerFrame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasDetectorTrackerFrame = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional core.AlertMessage alert_message = 13;
 * @return {?proto.vivacity.core.AlertMessage}
 */
proto.vivacity.UniversalEnvelope.prototype.getAlertMessage = function() {
  return /** @type{?proto.vivacity.core.AlertMessage} */ (
    jspb.Message.getWrapperField(this, vivacity_core_alerts_pb.AlertMessage, 13));
};


/**
 * @param {?proto.vivacity.core.AlertMessage|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setAlertMessage = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearAlertMessage = function() {
  return this.setAlertMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasAlertMessage = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional core.AlertNotificationBatch alert_notification_batch = 14;
 * @return {?proto.vivacity.core.AlertNotificationBatch}
 */
proto.vivacity.UniversalEnvelope.prototype.getAlertNotificationBatch = function() {
  return /** @type{?proto.vivacity.core.AlertNotificationBatch} */ (
    jspb.Message.getWrapperField(this, vivacity_core_alerts_pb.AlertNotificationBatch, 14));
};


/**
 * @param {?proto.vivacity.core.AlertNotificationBatch|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setAlertNotificationBatch = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearAlertNotificationBatch = function() {
  return this.setAlertNotificationBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasAlertNotificationBatch = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional core.HardwareHeartbeat hardware_heartbeat = 15;
 * @return {?proto.vivacity.core.HardwareHeartbeat}
 */
proto.vivacity.UniversalEnvelope.prototype.getHardwareHeartbeat = function() {
  return /** @type{?proto.vivacity.core.HardwareHeartbeat} */ (
    jspb.Message.getWrapperField(this, vivacity_core_hardware_heartbeat_pb.HardwareHeartbeat, 15));
};


/**
 * @param {?proto.vivacity.core.HardwareHeartbeat|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setHardwareHeartbeat = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearHardwareHeartbeat = function() {
  return this.setHardwareHeartbeat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasHardwareHeartbeat = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional core.CountlineCrossing countline_crossing = 20;
 * @return {?proto.vivacity.core.CountlineCrossing}
 */
proto.vivacity.UniversalEnvelope.prototype.getCountlineCrossing = function() {
  return /** @type{?proto.vivacity.core.CountlineCrossing} */ (
    jspb.Message.getWrapperField(this, vivacity_core_countline_crossing_pb.CountlineCrossing, 20));
};


/**
 * @param {?proto.vivacity.core.CountlineCrossing|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setCountlineCrossing = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearCountlineCrossing = function() {
  return this.setCountlineCrossing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasCountlineCrossing = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional core.OccupancyBasedTurningMovement occupancy_based_turning_movement = 21;
 * @return {?proto.vivacity.core.OccupancyBasedTurningMovement}
 */
proto.vivacity.UniversalEnvelope.prototype.getOccupancyBasedTurningMovement = function() {
  return /** @type{?proto.vivacity.core.OccupancyBasedTurningMovement} */ (
    jspb.Message.getWrapperField(this, vivacity_core_occupancy_based_turning_movement_pb.OccupancyBasedTurningMovement, 21));
};


/**
 * @param {?proto.vivacity.core.OccupancyBasedTurningMovement|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setOccupancyBasedTurningMovement = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearOccupancyBasedTurningMovement = function() {
  return this.setOccupancyBasedTurningMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasOccupancyBasedTurningMovement = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional core.ZonalWindowedOccupancy zonal_windowed_occupancy = 22;
 * @return {?proto.vivacity.core.ZonalWindowedOccupancy}
 */
proto.vivacity.UniversalEnvelope.prototype.getZonalWindowedOccupancy = function() {
  return /** @type{?proto.vivacity.core.ZonalWindowedOccupancy} */ (
    jspb.Message.getWrapperField(this, vivacity_core_zonal_windowed_occupancy_pb.ZonalWindowedOccupancy, 22));
};


/**
 * @param {?proto.vivacity.core.ZonalWindowedOccupancy|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setZonalWindowedOccupancy = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearZonalWindowedOccupancy = function() {
  return this.setZonalWindowedOccupancy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasZonalWindowedOccupancy = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional core.ZonalWindowedSpeed zonal_windowed_speed = 23;
 * @return {?proto.vivacity.core.ZonalWindowedSpeed}
 */
proto.vivacity.UniversalEnvelope.prototype.getZonalWindowedSpeed = function() {
  return /** @type{?proto.vivacity.core.ZonalWindowedSpeed} */ (
    jspb.Message.getWrapperField(this, vivacity_core_zonal_windowed_speed_pb.ZonalWindowedSpeed, 23));
};


/**
 * @param {?proto.vivacity.core.ZonalWindowedSpeed|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setZonalWindowedSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearZonalWindowedSpeed = function() {
  return this.setZonalWindowedSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasZonalWindowedSpeed = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional core.ZonalWindowedFeatures zonal_windowed_features = 24;
 * @return {?proto.vivacity.core.ZonalWindowedFeatures}
 */
proto.vivacity.UniversalEnvelope.prototype.getZonalWindowedFeatures = function() {
  return /** @type{?proto.vivacity.core.ZonalWindowedFeatures} */ (
    jspb.Message.getWrapperField(this, vivacity_core_zonal_windowed_features_pb.ZonalWindowedFeatures, 24));
};


/**
 * @param {?proto.vivacity.core.ZonalWindowedFeatures|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setZonalWindowedFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearZonalWindowedFeatures = function() {
  return this.setZonalWindowedFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasZonalWindowedFeatures = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional core.TriggerZoneCrossing trigger_zone_crossing = 25;
 * @return {?proto.vivacity.core.TriggerZoneCrossing}
 */
proto.vivacity.UniversalEnvelope.prototype.getTriggerZoneCrossing = function() {
  return /** @type{?proto.vivacity.core.TriggerZoneCrossing} */ (
    jspb.Message.getWrapperField(this, vivacity_core_trigger_zone_crossing_pb.TriggerZoneCrossing, 25));
};


/**
 * @param {?proto.vivacity.core.TriggerZoneCrossing|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setTriggerZoneCrossing = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearTriggerZoneCrossing = function() {
  return this.setTriggerZoneCrossing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasTriggerZoneCrossing = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional core.WindowedState windowed_state = 26;
 * @return {?proto.vivacity.core.WindowedState}
 */
proto.vivacity.UniversalEnvelope.prototype.getWindowedState = function() {
  return /** @type{?proto.vivacity.core.WindowedState} */ (
    jspb.Message.getWrapperField(this, vivacity_core_windowed_state_pb.WindowedState, 26));
};


/**
 * @param {?proto.vivacity.core.WindowedState|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setWindowedState = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearWindowedState = function() {
  return this.setWindowedState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasWindowedState = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional core.NearMissEvent near_miss_event = 27;
 * @return {?proto.vivacity.core.NearMissEvent}
 */
proto.vivacity.UniversalEnvelope.prototype.getNearMissEvent = function() {
  return /** @type{?proto.vivacity.core.NearMissEvent} */ (
    jspb.Message.getWrapperField(this, vivacity_core_track_head_pb.NearMissEvent, 27));
};


/**
 * @param {?proto.vivacity.core.NearMissEvent|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setNearMissEvent = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearNearMissEvent = function() {
  return this.setNearMissEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasNearMissEvent = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional core.VisionProgramWindowedFeatures vision_program_windowed_features = 28;
 * @return {?proto.vivacity.core.VisionProgramWindowedFeatures}
 */
proto.vivacity.UniversalEnvelope.prototype.getVisionProgramWindowedFeatures = function() {
  return /** @type{?proto.vivacity.core.VisionProgramWindowedFeatures} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vision_program_windowed_features_pb.VisionProgramWindowedFeatures, 28));
};


/**
 * @param {?proto.vivacity.core.VisionProgramWindowedFeatures|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setVisionProgramWindowedFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearVisionProgramWindowedFeatures = function() {
  return this.setVisionProgramWindowedFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasVisionProgramWindowedFeatures = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional core.NearMissEventV2 near_miss_event_v2 = 29;
 * @return {?proto.vivacity.core.NearMissEventV2}
 */
proto.vivacity.UniversalEnvelope.prototype.getNearMissEventV2 = function() {
  return /** @type{?proto.vivacity.core.NearMissEventV2} */ (
    jspb.Message.getWrapperField(this, vivacity_core_track_head_pb.NearMissEventV2, 29));
};


/**
 * @param {?proto.vivacity.core.NearMissEventV2|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setNearMissEventV2 = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearNearMissEventV2 = function() {
  return this.setNearMissEventV2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasNearMissEventV2 = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional config.ReconfigurePipeline reconfigure_pipeline = 30;
 * @return {?proto.vivacity.config.ReconfigurePipeline}
 */
proto.vivacity.UniversalEnvelope.prototype.getReconfigurePipeline = function() {
  return /** @type{?proto.vivacity.config.ReconfigurePipeline} */ (
    jspb.Message.getWrapperField(this, vivacity_config_pipeline_pb.ReconfigurePipeline, 30));
};


/**
 * @param {?proto.vivacity.config.ReconfigurePipeline|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setReconfigurePipeline = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearReconfigurePipeline = function() {
  return this.setReconfigurePipeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasReconfigurePipeline = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional config.PipelineReconfigured pipeline_reconfigured = 31;
 * @return {?proto.vivacity.config.PipelineReconfigured}
 */
proto.vivacity.UniversalEnvelope.prototype.getPipelineReconfigured = function() {
  return /** @type{?proto.vivacity.config.PipelineReconfigured} */ (
    jspb.Message.getWrapperField(this, vivacity_config_pipeline_pb.PipelineReconfigured, 31));
};


/**
 * @param {?proto.vivacity.config.PipelineReconfigured|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setPipelineReconfigured = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearPipelineReconfigured = function() {
  return this.setPipelineReconfigured(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasPipelineReconfigured = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional config.ZonesConfigChange zones_config_change = 50;
 * @return {?proto.vivacity.config.ZonesConfigChange}
 */
proto.vivacity.UniversalEnvelope.prototype.getZonesConfigChange = function() {
  return /** @type{?proto.vivacity.config.ZonesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonesConfigChange, 50));
};


/**
 * @param {?proto.vivacity.config.ZonesConfigChange|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setZonesConfigChange = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearZonesConfigChange = function() {
  return this.setZonesConfigChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasZonesConfigChange = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional config.ConfigMessage config_message = 51;
 * @return {?proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.UniversalEnvelope.prototype.getConfigMessage = function() {
  return /** @type{?proto.vivacity.config.ConfigMessage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_config_message_pb.ConfigMessage, 51));
};


/**
 * @param {?proto.vivacity.config.ConfigMessage|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setConfigMessage = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearConfigMessage = function() {
  return this.setConfigMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasConfigMessage = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional config.ConfigError config_error = 52;
 * @return {?proto.vivacity.config.ConfigError}
 */
proto.vivacity.UniversalEnvelope.prototype.getConfigError = function() {
  return /** @type{?proto.vivacity.config.ConfigError} */ (
    jspb.Message.getWrapperField(this, vivacity_config_config_message_pb.ConfigError, 52));
};


/**
 * @param {?proto.vivacity.config.ConfigError|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setConfigError = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearConfigError = function() {
  return this.setConfigError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasConfigError = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional config_v2.TransactionalCRUDOperations transactional_c_r_u_d_operations = 55;
 * @return {?proto.vivacity.config_v2.TransactionalCRUDOperations}
 */
proto.vivacity.UniversalEnvelope.prototype.getTransactionalCRUDOperations = function() {
  return /** @type{?proto.vivacity.config_v2.TransactionalCRUDOperations} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_crud_operation_message_pb.TransactionalCRUDOperations, 55));
};


/**
 * @param {?proto.vivacity.config_v2.TransactionalCRUDOperations|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setTransactionalCRUDOperations = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearTransactionalCRUDOperations = function() {
  return this.setTransactionalCRUDOperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasTransactionalCRUDOperations = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional config.CommandGroupRequest command_group_request = 61;
 * @return {?proto.vivacity.config.CommandGroupRequest}
 */
proto.vivacity.UniversalEnvelope.prototype.getCommandGroupRequest = function() {
  return /** @type{?proto.vivacity.config.CommandGroupRequest} */ (
    jspb.Message.getWrapperField(this, vivacity_config_config_message_pb.CommandGroupRequest, 61));
};


/**
 * @param {?proto.vivacity.config.CommandGroupRequest|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setCommandGroupRequest = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearCommandGroupRequest = function() {
  return this.setCommandGroupRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasCommandGroupRequest = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional config.CommandRequest command_request = 62;
 * @return {?proto.vivacity.config.CommandRequest}
 */
proto.vivacity.UniversalEnvelope.prototype.getCommandRequest = function() {
  return /** @type{?proto.vivacity.config.CommandRequest} */ (
    jspb.Message.getWrapperField(this, vivacity_config_config_message_pb.CommandRequest, 62));
};


/**
 * @param {?proto.vivacity.config.CommandRequest|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setCommandRequest = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearCommandRequest = function() {
  return this.setCommandRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasCommandRequest = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional core.HardwareReboot hardware_reboot = 71;
 * @return {?proto.vivacity.core.HardwareReboot}
 */
proto.vivacity.UniversalEnvelope.prototype.getHardwareReboot = function() {
  return /** @type{?proto.vivacity.core.HardwareReboot} */ (
    jspb.Message.getWrapperField(this, vivacity_core_hardware_reboot_pb.HardwareReboot, 71));
};


/**
 * @param {?proto.vivacity.core.HardwareReboot|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setHardwareReboot = function(value) {
  return jspb.Message.setWrapperField(this, 71, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearHardwareReboot = function() {
  return this.setHardwareReboot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasHardwareReboot = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional core.SnappiVideoCapture snappi_video_capture = 80;
 * @return {?proto.vivacity.core.SnappiVideoCapture}
 */
proto.vivacity.UniversalEnvelope.prototype.getSnappiVideoCapture = function() {
  return /** @type{?proto.vivacity.core.SnappiVideoCapture} */ (
    jspb.Message.getWrapperField(this, vivacity_core_snappi_pb.SnappiVideoCapture, 80));
};


/**
 * @param {?proto.vivacity.core.SnappiVideoCapture|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setSnappiVideoCapture = function(value) {
  return jspb.Message.setWrapperField(this, 80, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearSnappiVideoCapture = function() {
  return this.setSnappiVideoCapture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasSnappiVideoCapture = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional core.AnprJourney anpr_journey = 81;
 * @return {?proto.vivacity.core.AnprJourney}
 */
proto.vivacity.UniversalEnvelope.prototype.getAnprJourney = function() {
  return /** @type{?proto.vivacity.core.AnprJourney} */ (
    jspb.Message.getWrapperField(this, vivacity_core_anpr_journey_pb.AnprJourney, 81));
};


/**
 * @param {?proto.vivacity.core.AnprJourney|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setAnprJourney = function(value) {
  return jspb.Message.setWrapperField(this, 81, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearAnprJourney = function() {
  return this.setAnprJourney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasAnprJourney = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional core.HttpServerEvent http_server_event = 90;
 * @return {?proto.vivacity.core.HttpServerEvent}
 */
proto.vivacity.UniversalEnvelope.prototype.getHttpServerEvent = function() {
  return /** @type{?proto.vivacity.core.HttpServerEvent} */ (
    jspb.Message.getWrapperField(this, vivacity_core_http_server_event_pb.HttpServerEvent, 90));
};


/**
 * @param {?proto.vivacity.core.HttpServerEvent|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setHttpServerEvent = function(value) {
  return jspb.Message.setWrapperField(this, 90, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearHttpServerEvent = function() {
  return this.setHttpServerEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasHttpServerEvent = function() {
  return jspb.Message.getField(this, 90) != null;
};


/**
 * optional core.AuthenticationMessage authentication_message = 91;
 * @return {?proto.vivacity.core.AuthenticationMessage}
 */
proto.vivacity.UniversalEnvelope.prototype.getAuthenticationMessage = function() {
  return /** @type{?proto.vivacity.core.AuthenticationMessage} */ (
    jspb.Message.getWrapperField(this, vivacity_core_authentication_pb.AuthenticationMessage, 91));
};


/**
 * @param {?proto.vivacity.core.AuthenticationMessage|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setAuthenticationMessage = function(value) {
  return jspb.Message.setWrapperField(this, 91, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearAuthenticationMessage = function() {
  return this.setAuthenticationMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasAuthenticationMessage = function() {
  return jspb.Message.getField(this, 91) != null;
};


/**
 * optional core.AuthorizationMessage authorization_message = 92;
 * @return {?proto.vivacity.core.AuthorizationMessage}
 */
proto.vivacity.UniversalEnvelope.prototype.getAuthorizationMessage = function() {
  return /** @type{?proto.vivacity.core.AuthorizationMessage} */ (
    jspb.Message.getWrapperField(this, vivacity_core_authorization_pb.AuthorizationMessage, 92));
};


/**
 * @param {?proto.vivacity.core.AuthorizationMessage|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setAuthorizationMessage = function(value) {
  return jspb.Message.setWrapperField(this, 92, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearAuthorizationMessage = function() {
  return this.setAuthorizationMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasAuthorizationMessage = function() {
  return jspb.Message.getField(this, 92) != null;
};


/**
 * optional bytes raw_bytes_message = 100;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.UniversalEnvelope.prototype.getRawBytesMessage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * optional bytes raw_bytes_message = 100;
 * This is a type-conversion wrapper around `getRawBytesMessage()`
 * @return {string}
 */
proto.vivacity.UniversalEnvelope.prototype.getRawBytesMessage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRawBytesMessage()));
};


/**
 * optional bytes raw_bytes_message = 100;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawBytesMessage()`
 * @return {!Uint8Array}
 */
proto.vivacity.UniversalEnvelope.prototype.getRawBytesMessage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRawBytesMessage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.setRawBytesMessage = function(value) {
  return jspb.Message.setProto3BytesField(this, 100, value);
};


/**
 * optional string raw_string_message = 101;
 * @return {string}
 */
proto.vivacity.UniversalEnvelope.prototype.getRawStringMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.setRawStringMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional string json_message = 102;
 * @return {string}
 */
proto.vivacity.UniversalEnvelope.prototype.getJsonMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 102, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.setJsonMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 102, value);
};


/**
 * optional core.TracingContext tracing_ctx = 110;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.UniversalEnvelope.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 110));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
*/
proto.vivacity.UniversalEnvelope.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.UniversalEnvelope.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional uint64 last_consumed_kafka_offset = 120;
 * @return {number}
 */
proto.vivacity.UniversalEnvelope.prototype.getLastConsumedKafkaOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 120, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.UniversalEnvelope} returns this
 */
proto.vivacity.UniversalEnvelope.prototype.setLastConsumedKafkaOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 120, value);
};


goog.object.extend(exports, proto.vivacity);
