import { CustomPIXIComponent } from "react-pixi-fiber";
import * as PIXI from "pixi.js";

export interface RectangleProperties extends Partial<PIXI.Graphics> {
    x: number;
    y: number;
    width: number;
    height: number;
    fillColor: number;
    lineColor: number;
    lineWidth: number;
}

export default CustomPIXIComponent({
    customDisplayObject: (props: RectangleProperties) => new PIXI.Graphics(),
    customApplyProps: function(instance: PIXI.Graphics, oldProps: RectangleProperties, newProps: RectangleProperties) {
        const { fillColor, x, y, width, height, lineColor, lineWidth, ...newPropsRest } = newProps;
        const { fillColor: oldFill, x: oldX, y: oldY, width: oldWidth, height: oldHeight, lineColor: oldLineColour, lineWidth: oldLineWidth, ...oldPropsRest } = oldProps;
        if (oldProps !== undefined) {
            instance.clear();
        }

        instance.beginFill(fillColor);
        instance.lineStyle(lineWidth, lineColor, 1, 0);
        instance.drawRect(x, y, width, height);
        instance.endFill();

        // TODO - figure out how to fix this instead of ignoring
        // @ts-ignore
        this.applyDisplayObjectProps(oldPropsRest, newPropsRest);
    },
}, "Rectangle");