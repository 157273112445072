/* eslint-disable */
// source: vivacity/core/trigger_zone_crossing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_movement_pb = require('../../vivacity/core/movement_pb.js');
goog.object.extend(proto, vivacity_core_movement_pb);
var vivacity_core_anpr_confidence_pb = require('../../vivacity/core/anpr_confidence_pb.js');
goog.object.extend(proto, vivacity_core_anpr_confidence_pb);
goog.exportSymbol('proto.vivacity.core.TriggerZoneCrossing', null, global);
goog.exportSymbol('proto.vivacity.core.TriggerZoneCrossing.Direction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TriggerZoneCrossing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.TriggerZoneCrossing.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.TriggerZoneCrossing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TriggerZoneCrossing.displayName = 'proto.vivacity.core.TriggerZoneCrossing';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.TriggerZoneCrossing.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TriggerZoneCrossing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TriggerZoneCrossing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TriggerZoneCrossing.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0),
    firstTimestampMicrosecondsEntered: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastTimestampMicrosecondsLeft: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    movement: (f = msg.getMovement()) && vivacity_core_movement_pb.Movement.toObject(includeInstance, f),
    cumulativeAnprConfidenceList: jspb.Message.toObjectList(msg.getCumulativeAnprConfidenceList(),
    vivacity_core_anpr_confidence_pb.AnprConfidence.toObject, includeInstance),
    anprVehicleClass: jspb.Message.getFieldWithDefault(msg, 13, 0),
    anprAssociatedTrackNumber: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cumulativeStoppedMicrosecondsByZoneIdMap: (f = msg.getCumulativeStoppedMicrosecondsByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeTotalMicrosecondsByZoneIdMap: (f = msg.getCumulativeTotalMicrosecondsByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    firstTimestampMicrosecondsEnteredZoneByZoneIdMap: (f = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    lastTimestampMicrosecondsLeftZoneByZoneIdMap: (f = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    trackEndTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 19, 0),
    cumulativeStoppedInZoneMicroseconds: jspb.Message.getFieldWithDefault(msg, 20, 0),
    cumulativeTotalInZoneMicroseconds: jspb.Message.getFieldWithDefault(msg, 21, 0),
    cumulativeStoppedAcrossTrackMicroseconds: jspb.Message.getFieldWithDefault(msg, 22, 0),
    cumulativeTotalAcrossTrackMicroseconds: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TriggerZoneCrossing}
 */
proto.vivacity.core.TriggerZoneCrossing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TriggerZoneCrossing;
  return proto.vivacity.core.TriggerZoneCrossing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TriggerZoneCrossing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TriggerZoneCrossing}
 */
proto.vivacity.core.TriggerZoneCrossing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.TriggerZoneCrossing.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFirstTimestampMicrosecondsEntered(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastTimestampMicrosecondsLeft(value);
      break;
    case 5:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 9:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClassId(value);
      break;
    case 11:
      var value = new vivacity_core_movement_pb.Movement;
      reader.readMessage(value,vivacity_core_movement_pb.Movement.deserializeBinaryFromReader);
      msg.setMovement(value);
      break;
    case 12:
      var value = new vivacity_core_anpr_confidence_pb.AnprConfidence;
      reader.readMessage(value,vivacity_core_anpr_confidence_pb.AnprConfidence.deserializeBinaryFromReader);
      msg.addCumulativeAnprConfidence(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setAnprVehicleClass(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAnprAssociatedTrackNumber(value);
      break;
    case 15:
      var value = msg.getCumulativeStoppedMicrosecondsByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 16:
      var value = msg.getCumulativeTotalMicrosecondsByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 17:
      var value = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 18:
      var value = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTrackEndTimestampMicroseconds(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCumulativeStoppedInZoneMicroseconds(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCumulativeTotalInZoneMicroseconds(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCumulativeStoppedAcrossTrackMicroseconds(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCumulativeTotalAcrossTrackMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TriggerZoneCrossing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TriggerZoneCrossing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TriggerZoneCrossing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFirstTimestampMicrosecondsEntered();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getLastTimestampMicrosecondsLeft();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMovement();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_core_movement_pb.Movement.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeAnprConfidenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      vivacity_core_anpr_confidence_pb.AnprConfidence.serializeBinaryToWriter
    );
  }
  f = message.getAnprVehicleClass();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getAnprAssociatedTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCumulativeStoppedMicrosecondsByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getCumulativeTotalMicrosecondsByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(17, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getLastTimestampMicrosecondsLeftZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(18, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getTrackEndTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = message.getCumulativeStoppedInZoneMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getCumulativeTotalInZoneMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getCumulativeStoppedAcrossTrackMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getCumulativeTotalAcrossTrackMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.TriggerZoneCrossing.Direction = {
  UNKNOWN_DIRECTION: 0,
  TO_FRONT_OF_ZONE: 1,
  AWAY_FROM_FRONT_OF_ZONE: 2,
  NO_DIRECTION: 3
};

/**
 * optional uint32 zone_id = 1;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Direction direction = 2;
 * @return {!proto.vivacity.core.TriggerZoneCrossing.Direction}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getDirection = function() {
  return /** @type {!proto.vivacity.core.TriggerZoneCrossing.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.TriggerZoneCrossing.Direction} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 first_timestamp_microseconds_entered = 3;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getFirstTimestampMicrosecondsEntered = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setFirstTimestampMicrosecondsEntered = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 last_timestamp_microseconds_left = 4;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getLastTimestampMicrosecondsLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setLastTimestampMicrosecondsLeft = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional TracingContext tracing_ctx = 5;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 5));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
*/
proto.vivacity.core.TriggerZoneCrossing.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 restart_number = 6;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 track_number = 7;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 vision_program_id = 8;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional ClassifyingDetectorClassTypes class_id = 9;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getClassId = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setClassId = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional Movement movement = 11;
 * @return {?proto.vivacity.core.Movement}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getMovement = function() {
  return /** @type{?proto.vivacity.core.Movement} */ (
    jspb.Message.getWrapperField(this, vivacity_core_movement_pb.Movement, 11));
};


/**
 * @param {?proto.vivacity.core.Movement|undefined} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
*/
proto.vivacity.core.TriggerZoneCrossing.prototype.setMovement = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.clearMovement = function() {
  return this.setMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.hasMovement = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated AnprConfidence cumulative_anpr_confidence = 12;
 * @return {!Array<!proto.vivacity.core.AnprConfidence>}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getCumulativeAnprConfidenceList = function() {
  return /** @type{!Array<!proto.vivacity.core.AnprConfidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_anpr_confidence_pb.AnprConfidence, 12));
};


/**
 * @param {!Array<!proto.vivacity.core.AnprConfidence>} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
*/
proto.vivacity.core.TriggerZoneCrossing.prototype.setCumulativeAnprConfidenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.vivacity.core.AnprConfidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AnprConfidence}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.addCumulativeAnprConfidence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.vivacity.core.AnprConfidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.clearCumulativeAnprConfidenceList = function() {
  return this.setCumulativeAnprConfidenceList([]);
};


/**
 * optional ClassifyingDetectorClassTypes anpr_vehicle_class = 13;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getAnprVehicleClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setAnprVehicleClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional uint32 anpr_associated_track_number = 14;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getAnprAssociatedTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setAnprAssociatedTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * map<uint32, uint64> cumulative_stopped_microseconds_by_zone_id = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getCumulativeStoppedMicrosecondsByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.clearCumulativeStoppedMicrosecondsByZoneIdMap = function() {
  this.getCumulativeStoppedMicrosecondsByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, uint64> cumulative_total_microseconds_by_zone_id = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getCumulativeTotalMicrosecondsByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.clearCumulativeTotalMicrosecondsByZoneIdMap = function() {
  this.getCumulativeTotalMicrosecondsByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, uint64> first_timestamp_microseconds_entered_zone_by_zone_id = 17;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 17, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.clearFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function() {
  this.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, uint64> last_timestamp_microseconds_left_zone_by_zone_id = 18;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getLastTimestampMicrosecondsLeftZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 18, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.clearLastTimestampMicrosecondsLeftZoneByZoneIdMap = function() {
  this.getLastTimestampMicrosecondsLeftZoneByZoneIdMap().clear();
  return this;};


/**
 * optional uint64 track_end_timestamp_microseconds = 19;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getTrackEndTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setTrackEndTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint64 cumulative_stopped_in_zone_microseconds = 20;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getCumulativeStoppedInZoneMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setCumulativeStoppedInZoneMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 cumulative_total_in_zone_microseconds = 21;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getCumulativeTotalInZoneMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setCumulativeTotalInZoneMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 cumulative_stopped_across_track_microseconds = 22;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getCumulativeStoppedAcrossTrackMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setCumulativeStoppedAcrossTrackMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 cumulative_total_across_track_microseconds = 23;
 * @return {number}
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.getCumulativeTotalAcrossTrackMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TriggerZoneCrossing} returns this
 */
proto.vivacity.core.TriggerZoneCrossing.prototype.setCumulativeTotalAcrossTrackMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


goog.object.extend(exports, proto.vivacity.core);
