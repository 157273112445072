import EventEmitter from "events";
import {action, observable, toJS} from "mobx";
import {
    WebRTCAddPeerConnection,
    WebRTCConnectionError,
    WebRTCDataChannelRequest,
    WebRTCICECandidate, WebRTCICEServer,
    WebRTCRequestPeerByPrincipalID,
    WebRTCSDP,
    WebRTCSessionNegotiation,
    WebRTCSignalling,
    WebRTCStateUpdate,
    WebRTCTrackRequest
} from "../vivacity/core/webrtc_peer_connector_pb";
import transposeMap from "../utils/proto-enum-helper";
import SignallingServerConnection from "./SignallingServerConnection";
import {VideoID, VideoIDKey, PeerID, PrincipalID} from "../components/common/types";
import {DataChannelSelectOption} from "../HomePage";

export type TrackLabel = string;
export type DataChannelLabel = string;
export type WebRTCError = {
    type: string;
    message: string;
    label: string;
}
export type ConnectionStateEntry = {
    time: Date;
    state: string;
}

export default class WebRTCPeerConnectionState extends EventEmitter {
    private signallingServer: SignallingServerConnection;
    private reconnectThresholdSecs: number = 30;
    private connectionCheckInterval = 10000;
    private failureCheckCount = 12;  // How many failures to wait for before deciding if connection is unusable
    private failureCheckThreshold = 0.75; // % of time VP must be in a failure state to be considered unusuable

    @observable public peerConnections: Map<PeerID, RTCPeerConnection> = new Map<PeerID, RTCPeerConnection>();

    @observable public streams: Map<PeerID, Map<TrackLabel, MediaStream[]>> = new Map<PeerID, Map<TrackLabel, MediaStream[]>>();

    @observable public streamsByID: Map<VideoIDKey, MediaStream> = new Map<VideoIDKey, MediaStream>();

    @observable public dataChannels: Map<PeerID, Map<DataChannelLabel, RTCDataChannel>> = new Map<PeerID, Map<DataChannelLabel, RTCDataChannel>>();

    @observable public globalErrors: WebRTCError[] = [];

    @observable public remoteErrors: Map<PeerID, WebRTCError[]> = new Map<PeerID, WebRTCError[]>();

    @observable public remoteDataChannelState: Map<PeerID, Map<DataChannelLabel, string>> = new Map<PeerID, Map<DataChannelLabel, string>>();

    @observable public remoteIceGathererState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public remotePeerConnectionState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public remoteSignallingState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public remoteIceConnectionState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public localErrors: Map<PeerID, WebRTCError[]> = new Map<PeerID, WebRTCError[]>();

    @observable public localDataChannelState: Map<PeerID, Map<DataChannelLabel, string>> = new Map<PeerID, Map<DataChannelLabel, string>>();

    @observable public localIceGathererState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public localPeerConnectionState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public localPeerConnectionStateHistory: Map<PeerID, ConnectionStateEntry[]> = new Map<PeerID, ConnectionStateEntry[]>();

    @observable public localSignallingState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public localIceConnectionState: Map<PeerID, string> = new Map<PeerID, string>();

    @observable public wasOfferer: Map<PeerID, boolean> = new Map<PeerID, boolean>();

    @observable public currentPeerIdByPrincipalId: Map<PrincipalID, PeerID> = new Map<PrincipalID, PeerID>();

    @observable public autoReconnectEnabled: boolean = false;

    constructor(signallingServer: SignallingServerConnection) {
        super();
        this.signallingServer = signallingServer;
        this.signallingServer.on("message", action((signalMsg: WebRTCSignalling) => {
            this.handleSignallingMsg(signalMsg);
        }));

        this.signallingServer.on("close", action((closeEvent: CloseEvent) => {
            const error: WebRTCError = { type: closeEvent.code.toString(), message: closeEvent.reason, label: "" };
            this.globalErrors.push(error);
            this.peerConnections.forEach((connection, peerId) => {
                this.removePeerConnection(peerId)
            })
        }));

        const peerConnectionFailureTimes: Map<PrincipalID, Date[]> = new Map<PrincipalID, Date[]>();
        const storePeerConnectionFailureTime = (principalID: PrincipalID, peerID: PeerID) => {
            let peerConnectionFailureTimesForPrincipalID = peerConnectionFailureTimes.get(principalID)
            if (!peerConnectionFailureTimesForPrincipalID) {
                peerConnectionFailureTimes.set(principalID, [])
                peerConnectionFailureTimesForPrincipalID = [];
            }
            peerConnectionFailureTimes.set(principalID, [...peerConnectionFailureTimesForPrincipalID, new Date()]);
        }

        setInterval(action(() => {
            if (!this.autoReconnectEnabled) {
                return;
            }
            Object.entries(toJS(this.currentPeerIdByPrincipalId)).forEach(([principalID, peerID]) => {
                const connectionStateHistory = this.localPeerConnectionStateHistory.get(peerID);
                if (connectionStateHistory === undefined || connectionStateHistory.length === 0) {
                    return;
                }

                const currentState = connectionStateHistory[connectionStateHistory.length - 1];
                if (!["disconnected", "failed", "closed"].includes(currentState.state)) {
                    return;
                }
                

                let hasFailed = false;
                if (["failed", "closed"].includes(currentState.state)) {
                    // Connection is definitely dead, so we can reconnect regardless of how long it's been in this state
                    hasFailed = true;
                } else {
                    // Connection may succeed (if still connecting) or recover itself (if disconnected)
                    const lastStateTimestamp = connectionStateHistory[connectionStateHistory.length - 1].time;
                    const stuckOrDisconnectedFor = (new Date()).getTime() - lastStateTimestamp.getTime() / 1000;
                    hasFailed = stuckOrDisconnectedFor > this.reconnectThresholdSecs;
                }

                if (!hasFailed) {
                    return;
                }

                storePeerConnectionFailureTime(principalID, peerID)

                let inPermanentFailureState = false;
                const failureTimes = peerConnectionFailureTimes.get(principalID) || [];
                if (failureTimes.length >= this.failureCheckCount) {
                    const earliestFailureTime = failureTimes[failureTimes.length - this.failureCheckCount]
                    const latestFailureTime = failureTimes[failureTimes.length - 1];
                    const timeDelta = latestFailureTime.getTime() - earliestFailureTime.getTime();
                    const failuresPerCheck = ((this.failureCheckCount - 1) * this.connectionCheckInterval) / timeDelta;
                    inPermanentFailureState = failuresPerCheck > this.failureCheckThreshold;
                }
                if (!inPermanentFailureState) {
                    this.emit("peer-connection-failure", principalID, peerID);
                } else {
                    this.emit("peer-connection-permanent-failure", principalID, peerID);
                }
            });
        }), this.connectionCheckInterval)
    }

    @action sendSignal(signalMsg: WebRTCSignalling) {
        this.signallingServer.sendMessage(signalMsg)
    }

    buildStateUpdateMsg(peerId: string, senderId: string, stateType: WebRTCStateUpdate.TypeMap[keyof WebRTCStateUpdate.TypeMap], state: string, label: string): WebRTCSignalling {
        const stateUpdate = new WebRTCStateUpdate();
        stateUpdate.setType(stateType);
        stateUpdate.setState(state);
        stateUpdate.setDataChannelLabel(label);

        const signalMsg = new WebRTCSignalling();
        signalMsg.setType(WebRTCSignalling.Type.STATE_UPDATE);
        signalMsg.setPeerId(peerId);
        signalMsg.setSenderId(senderId);
        signalMsg.setStateUpdate(stateUpdate);
        return signalMsg
    }

    buildIceCandidateMsg(peerId: string, senderId: string, candidate: RTCIceCandidate): WebRTCSignalling {
        const msgIce = new WebRTCICECandidate();
        msgIce.setCandidate(candidate.candidate);
        if (candidate.sdpMLineIndex) {
            msgIce.setSdpmlineindex(candidate.sdpMLineIndex);
        }

        const msgSession = new WebRTCSessionNegotiation();
        msgSession.setIceCandidate(msgIce);

        const signalMsg = new WebRTCSignalling();
        signalMsg.setType(WebRTCSignalling.Type.SESSION_NEGOTIATION);
        signalMsg.setPeerId(peerId);
        signalMsg.setSenderId(senderId);
        signalMsg.setSessionNegotiation(msgSession);

        return signalMsg
    }

    buildSdpMsg(peerId: string, senderId: string, sdp: string, type: RTCSdpType): WebRTCSignalling {
        const msgSDP = new WebRTCSDP();
        msgSDP.setSdp(sdp);

        switch (type) {
            case "answer":
                msgSDP.setType(WebRTCSDP.Type.SDPTYPEANSWER);
                break;
            case "offer":
                msgSDP.setType(WebRTCSDP.Type.SDPTYPEOFFER);
                break;
            case "pranswer":
                msgSDP.setType(WebRTCSDP.Type.SDPTYPEPRANSWER);
                break;
            case "rollback":
                msgSDP.setType(WebRTCSDP.Type.SDPTYPEROLLBACK);
                break;
        }

        const msgSession = new WebRTCSessionNegotiation();
        msgSession.setSdp(msgSDP);

        const signalMsg = new WebRTCSignalling();
        signalMsg.setType(WebRTCSignalling.Type.SESSION_NEGOTIATION);
        signalMsg.setPeerId(peerId);
        signalMsg.setSenderId(senderId);
        signalMsg.setSessionNegotiation(msgSession);

        return signalMsg
    }

    buildErrorMsg(peerId: string, senderId: string, errMsg: string, errorType: WebRTCConnectionError.TypeMap[keyof WebRTCConnectionError.TypeMap], label: string): WebRTCSignalling {
        const msgErr = new WebRTCConnectionError();
        msgErr.setError(errMsg);
        msgErr.setLabel(label);
        msgErr.setType(errorType);
        const signalMsg = new WebRTCSignalling();
        signalMsg.setType(WebRTCSignalling.Type.ERROR);
        signalMsg.setError(msgErr);
        signalMsg.setPeerId(peerId);
        signalMsg.setSenderId(senderId);
        return signalMsg
    }

    @action connectDataChannel(channel: RTCDataChannel, peerId: string, senderId: string) {
        channel.addEventListener("open", action(() => {
            const state = this.localDataChannelState.get(peerId);
            if (state === undefined) {
                this.localDataChannelState.set(peerId, new Map<DataChannelLabel, string>());
            } else {
                state.set(channel.label, "open")
            }

            const stateSignal = this.buildStateUpdateMsg(peerId, senderId, WebRTCStateUpdate.Type.DATA_CHANNEL_STATE, "open", channel.label);

            this.sendSignal(stateSignal);
            const stateUpdate = stateSignal.getStateUpdate();

            if (stateUpdate !== undefined) {
                this.handleLocalStateUpdate(stateUpdate, peerId);
            }

            this.emit("data-channel-open", channel, peerId, channel.label);
        }));

        channel.addEventListener("close", action(() => {
            const stateSignal = this.buildStateUpdateMsg(peerId, senderId, WebRTCStateUpdate.Type.DATA_CHANNEL_STATE, "closed", channel.label);

            this.sendSignal(stateSignal);
            const stateUpdate = stateSignal.getStateUpdate();

            if (stateUpdate !== undefined) {
                this.handleLocalStateUpdate(stateUpdate, peerId);
            }
            this.emit("data-channel-closed", channel, peerId, channel.label);
        }));

        channel.addEventListener("error", action((errorEvent: RTCErrorEvent) => {
            const error = `data channel error: ${errorEvent.error}`;
            const errSignal = this.buildErrorMsg(peerId, senderId, error, WebRTCConnectionError.Type.DATA_CHANNEL_ERROR, channel.label);
            this.sendSignal(errSignal);
            this.handleLocalError(errSignal);
            this.emit("data-channel-error", channel, peerId, channel.label);
        }));

        channel.addEventListener("message", action((msgEvent: MessageEvent) => {
            this.emit("data-channel-message", msgEvent.data, peerId, channel.label);
        }));

        if (!this.dataChannels.has(peerId)) {
            this.dataChannels.set(peerId, new Map<DataChannelLabel, RTCDataChannel>());
        }

        const peerDataChannels = this.dataChannels.get(peerId);
        if (peerDataChannels) {
            peerDataChannels.set(channel.label, channel);
        }
    }

    pushConnectionStateToHistory(peerID: PeerID, connectionState: string) {
        if (!this.localPeerConnectionStateHistory.get(peerID)) {
            this.localPeerConnectionStateHistory.set(peerID, []);
        }
        const history = this.localPeerConnectionStateHistory.get(peerID);
        if (history !== undefined) {
            history.push({time: new Date(), state: connectionState});
        } else {
            console.error(`Got undefined history for peer ${peerID}`);
        }
    }

    @action addPeerConnection(addMsg: WebRTCAddPeerConnection, peerId: string, senderId: string) {
        let peerConnection: RTCPeerConnection;
        if (this.peerConnections.has(peerId)) {
            this.removePeerConnection(peerId)
        }

        const iceServers = addMsg.getIceServersList().map((iceServer): RTCIceServer => {
            const oAuth = iceServer.getOauth();
            if (iceServer.getCredentialCase() === WebRTCICEServer.CredentialCase.PASSWORD &&
                iceServer.getCredentialType() === WebRTCICEServer.ICECredentialType.PASSWORD){
                return {
                    urls: iceServer.getUrlsList(),
                    username: iceServer.getUsername(),
                    credential: iceServer.getPassword(),
                }
            } else if (iceServer.getCredentialCase() === WebRTCICEServer.CredentialCase.OAUTH &&
                iceServer.getCredentialType() === WebRTCICEServer.ICECredentialType.OAUTH && oAuth){
                const oAuthCredential: RTCOAuthCredential = {
                    accessToken: oAuth.getAccessToken(),
                    macKey: oAuth.getMacKey(),
                };
                return {
                    urls: iceServer.getUrlsList(),
                    username: iceServer.getUsername(),
                    credential: oAuthCredential,
                }
            } else {
                return {
                    urls: iceServer.getUrlsList(),
                }
            }
        });

        if (iceServers.length === 0) {
            iceServers.push({
                urls: "stun:stun.l.google.com:19302",
            })
        }

        peerConnection = new RTCPeerConnection({
            iceServers: iceServers,
        });

        this.peerConnections.set(peerId, peerConnection);

        peerConnection.addEventListener("iceconnectionstatechange", action((() => {
            this.sendSignal(this.buildStateUpdateMsg(peerId, senderId, WebRTCStateUpdate.Type.ICE_CONNECTION_STATE, peerConnection.iceConnectionState, ""));
            this.localIceConnectionState.set(peerId, peerConnection.iceConnectionState);
        })));

        peerConnection.addEventListener("signalingstatechange", action((() => {
            this.sendSignal(this.buildStateUpdateMsg(peerId, senderId, WebRTCStateUpdate.Type.SIGNALLING_STATE, peerConnection.signalingState, ""));
            this.localSignallingState.set(peerId, peerConnection.signalingState);
        })));

        peerConnection.addEventListener("connectionstatechange", action((() => {
            this.sendSignal(this.buildStateUpdateMsg(peerId, senderId, WebRTCStateUpdate.Type.PEER_CONNECTION_STATE, peerConnection.connectionState, ""));
            this.localPeerConnectionState.set(peerId, peerConnection.connectionState);
            this.pushConnectionStateToHistory(peerId, peerConnection.connectionState);
        })));

        peerConnection.addEventListener("icegatheringstatechange", action((() => {
            this.sendSignal(this.buildStateUpdateMsg(peerId, senderId, WebRTCStateUpdate.Type.ICE_GATHERER_STATE, peerConnection.iceGatheringState, ""));
            this.localIceGathererState.set(peerId, peerConnection.iceGatheringState);
        })));

        peerConnection.addEventListener("icecandidate", ((e: RTCPeerConnectionIceEvent) => {
            if (e.candidate && e.candidate.candidate) {
                this.sendSignal(this.buildIceCandidateMsg(peerId, senderId, e.candidate));
            } else {
                console.log("Got NULL ICE candidate...");
            }
        }));

        peerConnection.addEventListener("icecandidateerror", ((e: RTCPeerConnectionIceErrorEvent) => {
            const errMsg = `Ice candidate error for Peer ID '${peerId}'. Error code '${e.errorCode}'. URL: '${e.url}'. Host candidate: '${e.hostCandidate}'. Error message: ${e.errorText}`;
            const errSignal = this.buildErrorMsg(peerId, senderId, errMsg, WebRTCConnectionError.Type.FAILED_TO_ADD_ICE_CANDIDATE, "");
            this.sendSignal(errSignal);
            this.handleLocalError(errSignal);
        }));

        peerConnection.addEventListener("track", action((e: RTCTrackEvent) => {
            if (!this.streams.has(peerId)) {
                this.streams.set(peerId, new Map<TrackLabel, MediaStream[]>());
            }

            const peerStreams = this.streams.get(peerId);
            const streams: MediaStream[] = [];
            if (peerStreams) {
                const existingStreams = peerStreams.get(e.track.label);
                if (existingStreams) {
                    streams.concat(existingStreams);
                }
                e.streams.forEach((stream) => {
                    const videoID = new VideoID(peerId, stream.id);
                    this.streamsByID.set(videoID.toString(), stream);
                    streams.push(stream);
                });

                peerStreams.set(e.track.label, streams);

                this.streams.set(peerId, peerStreams);
            }
        }));

        peerConnection.addEventListener("datachannel", action(((e: RTCDataChannelEvent) => {
            this.connectDataChannel(e.channel, peerId, senderId);
        })));

        peerConnection.addEventListener("negotiationneeded", (() => {
            // Not yet supported by pion/webrtc
        }));

        for (let dataChannelRequest of addMsg.getDataChannelsList()) {
            const channel = peerConnection.createDataChannel(dataChannelRequest.getChannelLabel(), {protocol: dataChannelRequest.getProtocol()});
            this.connectDataChannel(channel, peerId, senderId);
        }

        this.wasOfferer.set(peerId, addMsg.getCreateOffer());

        if (addMsg.getCreateOffer()) {
            peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            }).then((offer: RTCSessionDescriptionInit) => {
                if (offer.sdp) {
                    peerConnection.setLocalDescription(offer).then(() => {
                        if (offer.sdp) {
                            this.sendSignal(this.buildSdpMsg(peerId, senderId, offer.sdp, offer.type));
                        }
                    }).catch((err) => {
                        console.log("Failed to set SDP Local Description: ", err);
                        const errSignal = this.buildErrorMsg(peerId, senderId, err, WebRTCConnectionError.Type.FAILED_TO_SET_LOCAL_DESCRIPTION, "");
                        this.sendSignal(errSignal);
                        this.handleLocalError(errSignal);
                    });
                } else {
                    console.log("got an undefined offer SDP");
                    const errSignal = this.buildErrorMsg(peerId, senderId, 'got an undefined offer SDP', WebRTCConnectionError.Type.FAILED_TO_CREATE_SDP_OFFER, "");
                    this.sendSignal(errSignal);
                    this.handleLocalError(errSignal);
                }
            }).catch((err) => {
                console.log("failed to make an offer:" + err);
                const errSignal = this.buildErrorMsg(peerId, senderId, "failed to make an offer:" + err, WebRTCConnectionError.Type.FAILED_TO_CREATE_SDP_OFFER, "");
                this.sendSignal(errSignal);
                this.handleLocalError(errSignal);
            });
        }
    }

    @action removePeerConnection(peerId: string) {
        let peerConnection: RTCPeerConnection;
        const pc = this.peerConnections.get(peerId);
        if (!pc) {
            return
        } else {
            peerConnection = pc;
        }
        peerConnection.close();
        this.streams.delete(peerId);
        this.dataChannels.delete(peerId);
        this.peerConnections.delete(peerId);
        this.remoteErrors.delete(peerId);
        this.remoteDataChannelState.delete(peerId);
        this.remoteIceGathererState.delete(peerId);
        this.remotePeerConnectionState.delete(peerId);
        this.remoteSignallingState.delete(peerId);
        this.remoteIceConnectionState.delete(peerId);

        this.localErrors.delete(peerId);
        this.localDataChannelState.delete(peerId);
        this.localIceGathererState.delete(peerId);
        this.localPeerConnectionState.delete(peerId);
        // this.localPeerConnectionStateHistory.delete(peerId);
        this.localSignallingState.delete(peerId);
        this.localIceConnectionState.delete(peerId);
    }

    @action setAutoReconnectEnabled(enabled: boolean) {
        this.autoReconnectEnabled = enabled;
    }

    @action handleLocalError(signalMsg: WebRTCSignalling) {
        this.sendSignal(signalMsg);

        const err = signalMsg.getError();
        if (err !== undefined) {
            const existingErrors = this.localErrors.get(signalMsg.getPeerId());
            const errors: WebRTCError[] = [];
            if (existingErrors) {
                errors.concat(existingErrors);
            }
            this.localErrors.set(signalMsg.getPeerId(), errors)
        }
    }

    @action handleSessionNegotiation(sessMsg: WebRTCSessionNegotiation, peerId: string, senderId: string) {
        if (this.peerConnections.has(peerId)) {
            const pc = this.peerConnections.get(peerId);
            if (!pc) {
                return
            }

            if (sessMsg.hasIceCandidate()) {
                const iceMsg = sessMsg.getIceCandidate();
                if (iceMsg) {
                    const candidate = new RTCIceCandidate({
                        candidate: iceMsg.getCandidate(),
                        sdpMLineIndex: iceMsg.getSdpmlineindex(),
                    });

                    pc.addIceCandidate(candidate).catch((err) => {
                        const errSignal = this.buildErrorMsg(peerId, senderId, err, WebRTCConnectionError.Type.FAILED_TO_ADD_ICE_CANDIDATE, "");
                        this.sendSignal(errSignal);
                        this.handleLocalError(errSignal);
                    })
                }
            }
            if (sessMsg.hasSdp()) {
                const sdpMsg = sessMsg.getSdp();
                if (sdpMsg) {
                    let sdpType: RTCSdpType = "offer";
                    switch (sdpMsg.getType()) {
                        case WebRTCSDP.Type.SDPTYPEOFFER:
                            sdpType = "offer";
                            break;
                        case WebRTCSDP.Type.SDPTYPEANSWER:
                            sdpType = "answer";
                            break;
                        case WebRTCSDP.Type.SDPTYPEPRANSWER:
                            sdpType = "pranswer";
                            break;
                        case WebRTCSDP.Type.SDPTYPEROLLBACK:
                            sdpType = "rollback";
                            break;
                    }

                    pc.setRemoteDescription({
                        sdp: sdpMsg.getSdp(),
                        type: sdpType,
                    }).catch((err) => {
                        const errSignal = this.buildErrorMsg(peerId, senderId, err, WebRTCConnectionError.Type.FAILED_TO_SET_REMOTE_DESCRIPTION, "");
                        this.sendSignal(errSignal);
                        this.handleLocalError(errSignal);
                    });

                    if (sdpType === "offer") {
                        pc.createAnswer({
                            offerToReceiveAudio: true,
                            offerToReceiveVideo: true
                        }).then((answer: RTCSessionDescriptionInit) => {
                            if (answer.sdp) {
                                pc.setLocalDescription(answer).then(() => {
                                    if (answer.sdp) {
                                        this.sendSignal(this.buildSdpMsg(peerId, senderId, answer.sdp, answer.type));
                                    }
                                }).catch((err) => {
                                    console.log("Failed to set SDP Local Description: ", err);
                                    const errSignal = this.buildErrorMsg(peerId, senderId, err, WebRTCConnectionError.Type.FAILED_TO_SET_LOCAL_DESCRIPTION, "");
                                    this.sendSignal(errSignal);
                                    this.handleLocalError(errSignal);
                                });
                            } else {
                                console.log("got an undefined answer SDP");
                                const errSignal = this.buildErrorMsg(peerId, senderId, 'got an undefined answer SDP', WebRTCConnectionError.Type.FAILED_TO_CREATE_SDP_ANSWER, "");
                                this.sendSignal(errSignal);
                                this.handleLocalError(errSignal);
                            }
                        }).catch((err) => {
                            const errSignal = this.buildErrorMsg(peerId, senderId, err, WebRTCConnectionError.Type.FAILED_TO_CREATE_SDP_ANSWER, "");
                            this.sendSignal(errSignal);
                            this.handleLocalError(errSignal);
                        });
                    }
                }
            }
        } else {
            const errSignal = this.buildErrorMsg(peerId, senderId, "got WebRTCSessionNegotiation but have no such peer ID: " + peerId, WebRTCConnectionError.Type.NO_SUCH_PEER_ID, "");
            this.sendSignal(errSignal);
            this.handleLocalError(errSignal);
        }
    }

    @action handleLocalStateUpdate(stateMsg: WebRTCStateUpdate, peerId: string) {
        switch (stateMsg.getType()) {
            case WebRTCStateUpdate.Type.DATA_CHANNEL_STATE:
                if (!this.localDataChannelState.has(peerId)) {
                    this.localDataChannelState.set(peerId, new Map<DataChannelLabel, string>());
                }
                const peerDataChannelState = this.localDataChannelState.get(peerId);
                if (peerDataChannelState) {
                    peerDataChannelState.set(stateMsg.getDataChannelLabel(), stateMsg.getState());
                    this.localDataChannelState.set(peerId, peerDataChannelState)
                }
                break;
            case WebRTCStateUpdate.Type.ICE_GATHERER_STATE:
                this.localIceGathererState.set(peerId, stateMsg.getState());
                break;
            case WebRTCStateUpdate.Type.PEER_CONNECTION_STATE:
                const connectionState = stateMsg.getState();
                this.localPeerConnectionState.set(peerId, connectionState);
                this.pushConnectionStateToHistory(peerId, connectionState);
                break;
            case WebRTCStateUpdate.Type.SIGNALLING_STATE:
                this.localSignallingState.set(peerId, stateMsg.getState());
                break;
            case WebRTCStateUpdate.Type.ICE_CONNECTION_STATE:
                this.localIceConnectionState.set(peerId, stateMsg.getState());
                break;
        }
    }

    @action handleRemoteStateUpdate(stateMsg: WebRTCStateUpdate, peerId: string) {
        switch (stateMsg.getType()) {
            case WebRTCStateUpdate.Type.DATA_CHANNEL_STATE:
                if (!this.remoteDataChannelState.has(peerId)) {
                    this.remoteDataChannelState.set(peerId, new Map<DataChannelLabel, string>());
                }
                const peerDataChannelState = this.remoteDataChannelState.get(peerId);
                if (peerDataChannelState) {
                    peerDataChannelState.set(stateMsg.getDataChannelLabel(), stateMsg.getState());
                    this.remoteDataChannelState.set(peerId, peerDataChannelState)
                }
                break;
            case WebRTCStateUpdate.Type.ICE_GATHERER_STATE:
                this.remoteIceGathererState.set(peerId, stateMsg.getState());
                break;
            case WebRTCStateUpdate.Type.PEER_CONNECTION_STATE:
                this.remotePeerConnectionState.set(peerId, stateMsg.getState());
                break;
            case WebRTCStateUpdate.Type.SIGNALLING_STATE:
                this.remoteSignallingState.set(peerId, stateMsg.getState());
                break;
            case WebRTCStateUpdate.Type.ICE_CONNECTION_STATE:
                this.remoteIceConnectionState.set(peerId, stateMsg.getState());
                break;
        }
    }

    @action handleRemoteError(errMsg: WebRTCConnectionError, peerId: string) {

        console.log("GOT ERROR", errMsg.getError(), errMsg.getType());
        const err: WebRTCError = {
            type: transposeMap(WebRTCConnectionError.Type)[errMsg.getType()],
            message: errMsg.getError(),
            label: errMsg.getLabel(),
        };

        if (peerId === "0") {
            // This means it was an error that isn't linked to any Peer ID, so just push and alert it for now
            this.globalErrors.push(err);
        } else {
            const existingErrors = this.remoteErrors.get(peerId) || [];
            this.remoteErrors.set(peerId, [...toJS(existingErrors), err]);
        }
    }

    @action handleSignallingMsg(signallingMsg: WebRTCSignalling) {
        const peerId = signallingMsg.getPeerId();
        const principalID = signallingMsg.getPeerName();
        const senderId = signallingMsg.getSenderId();

        // In the context of incoming signalling messages, the senderId is the ID given to us by the signalling server
        switch (signallingMsg.getType()) {
            case WebRTCSignalling.Type.ADD:
                const addMsg = signallingMsg.getAddPeerConnection();
                if (addMsg) {
                    this.addPeerConnection(addMsg, peerId, senderId);
                    this.currentPeerIdByPrincipalId.set(principalID, peerId);
                }
                break;
            case WebRTCSignalling.Type.REMOVE:
                // This happens both when peer loses connection to signalling server
                // as well as when we intentionally disconnnect (e.g. before reconnecting)
                this.pushConnectionStateToHistory(peerId, "failed");
                this.removePeerConnection(peerId);
                break;
            case WebRTCSignalling.Type.SESSION_NEGOTIATION:
                const sessMsg = signallingMsg.getSessionNegotiation();
                if (sessMsg) {
                    this.handleSessionNegotiation(sessMsg, peerId, senderId);
                }
                break;
            case WebRTCSignalling.Type.STATE_UPDATE:
                const stateMsg = signallingMsg.getStateUpdate();
                if (stateMsg) {
                    this.handleRemoteStateUpdate(stateMsg, peerId);
                }
                break;
            case WebRTCSignalling.Type.ERROR:
                const errMsg = signallingMsg.getError();
                if (errMsg) {
                    this.handleRemoteError(errMsg, peerId);
                }
                break;
        }
    }

    @action sendRequestPeerConnectionMessage(principalID: string, trackRequests: string[], dataChannelRequests: DataChannelSelectOption[]) {
        const reqMsg = new WebRTCRequestPeerByPrincipalID();
        reqMsg.setPrincipalId(principalID);

        trackRequests.map((trackLabel) => {
            const tracksRequest = new WebRTCTrackRequest();
            tracksRequest.setPayloadType(WebRTCTrackRequest.PayloadType.H264);
            tracksRequest.setTrackId(trackLabel);
            tracksRequest.setTrackLabel(trackLabel);
            return tracksRequest
        }).forEach((trackRequest) => {
            reqMsg.addTracks(trackRequest);
        });

        dataChannelRequests.map((dataChannelConfig) => {
            const dataChannelsRequest = new WebRTCDataChannelRequest();
            dataChannelsRequest.setChannelLabel(dataChannelConfig.value);
            dataChannelsRequest.setChunkSize(dataChannelConfig.chunkSize);
            dataChannelsRequest.setUnordered(dataChannelConfig.unordered);
            dataChannelsRequest.setMaxRetransmits(dataChannelConfig.maxRetransmits);
            dataChannelsRequest.setMaxPacketLifetimeMs(dataChannelConfig.maxPacketLifetimeMs);
            dataChannelsRequest.setProtocol(dataChannelConfig.protocol);
            return dataChannelsRequest
        }).forEach((dataChannelRequest) => {
            reqMsg.addDataChannels(dataChannelRequest);
        });

        const signalMsg = new WebRTCSignalling();
        signalMsg.setType(WebRTCSignalling.Type.REQUEST_PEER_BY_PRINCIPAL_ID);
        signalMsg.setRequestPeer(reqMsg);
        this.sendSignal(signalMsg);
    }

}