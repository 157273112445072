/* eslint-disable */
// source: vivacity/config/hardware_position.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
goog.exportSymbol('proto.vivacity.config.HardwarePosition', null, global);
goog.exportSymbol('proto.vivacity.config.HardwarePosition.HardwarePositionStatus', null, global);
goog.exportSymbol('proto.vivacity.config.HardwarePosition.SiteApprovalStatus', null, global);
goog.exportSymbol('proto.vivacity.config.HardwarePositionStatusOnly', null, global);
goog.exportSymbol('proto.vivacity.config.HardwarePositionToContractedHardware', null, global);
goog.exportSymbol('proto.vivacity.config.HardwarePositionToHardware', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwarePosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.HardwarePosition.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.HardwarePosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwarePosition.displayName = 'proto.vivacity.config.HardwarePosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwarePositionStatusOnly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.HardwarePositionStatusOnly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwarePositionStatusOnly.displayName = 'proto.vivacity.config.HardwarePositionStatusOnly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwarePositionToHardware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.HardwarePositionToHardware.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.HardwarePositionToHardware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwarePositionToHardware.displayName = 'proto.vivacity.config.HardwarePositionToHardware';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.HardwarePositionToContractedHardware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.HardwarePositionToContractedHardware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.HardwarePositionToContractedHardware.displayName = 'proto.vivacity.config.HardwarePositionToContractedHardware';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.HardwarePosition.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwarePosition.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwarePosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwarePosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractedHardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: (f = msg.getLocation()) && vivacity_core_point_pb.PointF.toObject(includeInstance, f),
    lensNamesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    projectName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sensorNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deviceuid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    siteApprovalStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    siteApprovalNotes: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    assetId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    plannedInstallDate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    customGroupName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    totangoPhase: jspb.Message.getFieldWithDefault(msg, 18, ""),
    streetViewLink: jspb.Message.getFieldWithDefault(msg, 19, ""),
    powerType: jspb.Message.getFieldWithDefault(msg, 20, ""),
    supermarioImage: jspb.Message.getFieldWithDefault(msg, 21, ""),
    supermarioHttpPort: jspb.Message.getFieldWithDefault(msg, 22, 0),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 23, ""),
    autoincrementSensorNumber: jspb.Message.getBooleanFieldWithDefault(msg, 24, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.HardwarePosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwarePosition;
  return proto.vivacity.config.HardwarePosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwarePosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwarePosition}
 */
proto.vivacity.config.HardwarePosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContractedHardwareId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 4:
      var value = new vivacity_core_point_pb.PointF;
      reader.readMessage(value,vivacity_core_point_pb.PointF.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addLensNames(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSensorNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceuid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 10:
      var value = /** @type {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {!proto.vivacity.config.HardwarePosition.SiteApprovalStatus} */ (reader.readEnum());
      msg.setSiteApprovalStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteApprovalNotes(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlannedInstallDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomGroupName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotangoPhase(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetViewLink(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPowerType(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioImage(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSupermarioHttpPort(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoincrementSensorNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwarePosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwarePosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwarePosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getContractedHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_point_pb.PointF.serializeBinaryToWriter
    );
  }
  f = message.getLensNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSensorNumber();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getDeviceuid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSiteApprovalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSiteApprovalNotes();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPlannedInstallDate();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getCustomGroupName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTotangoPhase();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getStreetViewLink();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPowerType();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSupermarioImage();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSupermarioHttpPort();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAutoincrementSensorNumber();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.HardwarePosition.HardwarePositionStatus = {
  UNKNOWN: 0,
  SCOPING: 1,
  DROPPED_FROM_SCOPE: 2,
  READY_FOR_BUILD: 3,
  READY_FOR_INSTALL: 4,
  INSTALLED: 5,
  UNINSTALLED: 6,
  RETIRED: 7
};

/**
 * @enum {number}
 */
proto.vivacity.config.HardwarePosition.SiteApprovalStatus = {
  REQUIRE_SITE_APPROVAL: 0,
  SITE_APPROVED: 1,
  SITE_APPROVED_LIMITED: 2,
  SITE_APPROVED_SURVEY_REQUIRED: 3,
  SITE_REJECTED: 4
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.HardwarePosition.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 contracted_hardware_id = 2;
 * @return {number}
 */
proto.vivacity.config.HardwarePosition.prototype.getContractedHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setContractedHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 hardware_id = 3;
 * @return {number}
 */
proto.vivacity.config.HardwarePosition.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional vivacity.core.PointF location = 4;
 * @return {?proto.vivacity.core.PointF}
 */
proto.vivacity.config.HardwarePosition.prototype.getLocation = function() {
  return /** @type{?proto.vivacity.core.PointF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.PointF, 4));
};


/**
 * @param {?proto.vivacity.core.PointF|undefined} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
*/
proto.vivacity.config.HardwarePosition.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.HardwarePosition.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string lens_names = 5;
 * @return {!Array<string>}
 */
proto.vivacity.config.HardwarePosition.prototype.getLensNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setLensNamesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.addLensNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.clearLensNamesList = function() {
  return this.setLensNamesList([]);
};


/**
 * optional string project_name = 6;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 sensor_number = 7;
 * @return {number}
 */
proto.vivacity.config.HardwarePosition.prototype.getSensorNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setSensorNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string deviceuid = 8;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getDeviceuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setDeviceuid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string notes = 9;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional HardwarePositionStatus status = 10;
 * @return {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus}
 */
proto.vivacity.config.HardwarePosition.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional SiteApprovalStatus site_approval_status = 11;
 * @return {!proto.vivacity.config.HardwarePosition.SiteApprovalStatus}
 */
proto.vivacity.config.HardwarePosition.prototype.getSiteApprovalStatus = function() {
  return /** @type {!proto.vivacity.config.HardwarePosition.SiteApprovalStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.vivacity.config.HardwarePosition.SiteApprovalStatus} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setSiteApprovalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string site_approval_notes = 12;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getSiteApprovalNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setSiteApprovalNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string location_name = 13;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string asset_type = 14;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string asset_id = 15;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint64 planned_install_date = 16;
 * @return {number}
 */
proto.vivacity.config.HardwarePosition.prototype.getPlannedInstallDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setPlannedInstallDate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string custom_group_name = 17;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getCustomGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setCustomGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string totango_phase = 18;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getTotangoPhase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setTotangoPhase = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string street_view_link = 19;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getStreetViewLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setStreetViewLink = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string power_type = 20;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getPowerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setPowerType = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string supermario_image = 21;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getSupermarioImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setSupermarioImage = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional uint32 supermario_http_port = 22;
 * @return {number}
 */
proto.vivacity.config.HardwarePosition.prototype.getSupermarioHttpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setSupermarioHttpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string extra_values_json = 23;
 * @return {string}
 */
proto.vivacity.config.HardwarePosition.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool autoincrement_sensor_number = 24;
 * @return {boolean}
 */
proto.vivacity.config.HardwarePosition.prototype.getAutoincrementSensorNumber = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.HardwarePosition} returns this
 */
proto.vivacity.config.HardwarePosition.prototype.setAutoincrementSensorNumber = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwarePositionStatusOnly.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwarePositionStatusOnly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwarePositionStatusOnly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePositionStatusOnly.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwarePositionStatusOnly}
 */
proto.vivacity.config.HardwarePositionStatusOnly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwarePositionStatusOnly;
  return proto.vivacity.config.HardwarePositionStatusOnly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwarePositionStatusOnly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwarePositionStatusOnly}
 */
proto.vivacity.config.HardwarePositionStatusOnly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwarePositionStatusOnly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwarePositionStatusOnly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwarePositionStatusOnly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePositionStatusOnly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.HardwarePositionStatusOnly.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePositionStatusOnly} returns this
 */
proto.vivacity.config.HardwarePositionStatusOnly.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional HardwarePosition.HardwarePositionStatus status = 2;
 * @return {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus}
 */
proto.vivacity.config.HardwarePositionStatusOnly.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.HardwarePosition.HardwarePositionStatus} value
 * @return {!proto.vivacity.config.HardwarePositionStatusOnly} returns this
 */
proto.vivacity.config.HardwarePositionStatusOnly.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.HardwarePositionToHardware.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwarePositionToHardware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwarePositionToHardware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePositionToHardware.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwarePositionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    attachedVisionProgramIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwarePositionToHardware}
 */
proto.vivacity.config.HardwarePositionToHardware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwarePositionToHardware;
  return proto.vivacity.config.HardwarePositionToHardware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwarePositionToHardware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwarePositionToHardware}
 */
proto.vivacity.config.HardwarePositionToHardware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwarePositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAttachedVisionProgramIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwarePositionToHardware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwarePositionToHardware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePositionToHardware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwarePositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAttachedVisionProgramIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 hardware_position_id = 1;
 * @return {number}
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.getHardwarePositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePositionToHardware} returns this
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.setHardwarePositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 hardware_id = 2;
 * @return {number}
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePositionToHardware} returns this
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated uint32 attached_vision_program_ids = 3;
 * @return {!Array<number>}
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.getAttachedVisionProgramIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.config.HardwarePositionToHardware} returns this
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.setAttachedVisionProgramIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.HardwarePositionToHardware} returns this
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.addAttachedVisionProgramIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.HardwarePositionToHardware} returns this
 */
proto.vivacity.config.HardwarePositionToHardware.prototype.clearAttachedVisionProgramIdsList = function() {
  return this.setAttachedVisionProgramIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.HardwarePositionToContractedHardware.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.HardwarePositionToContractedHardware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.HardwarePositionToContractedHardware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePositionToContractedHardware.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwarePositionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractedHardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.HardwarePositionToContractedHardware}
 */
proto.vivacity.config.HardwarePositionToContractedHardware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.HardwarePositionToContractedHardware;
  return proto.vivacity.config.HardwarePositionToContractedHardware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.HardwarePositionToContractedHardware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.HardwarePositionToContractedHardware}
 */
proto.vivacity.config.HardwarePositionToContractedHardware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwarePositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContractedHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.HardwarePositionToContractedHardware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.HardwarePositionToContractedHardware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.HardwarePositionToContractedHardware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.HardwarePositionToContractedHardware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwarePositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getContractedHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 hardware_position_id = 1;
 * @return {number}
 */
proto.vivacity.config.HardwarePositionToContractedHardware.prototype.getHardwarePositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePositionToContractedHardware} returns this
 */
proto.vivacity.config.HardwarePositionToContractedHardware.prototype.setHardwarePositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 contracted_hardware_id = 2;
 * @return {number}
 */
proto.vivacity.config.HardwarePositionToContractedHardware.prototype.getContractedHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.HardwarePositionToContractedHardware} returns this
 */
proto.vivacity.config.HardwarePositionToContractedHardware.prototype.setContractedHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.vivacity.config);
