/* eslint-disable */
// source: vivacity/core/countline_crossing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_movement_pb = require('../../vivacity/core/movement_pb.js');
goog.object.extend(proto, vivacity_core_movement_pb);
var vivacity_core_anpr_confidence_pb = require('../../vivacity/core/anpr_confidence_pb.js');
goog.object.extend(proto, vivacity_core_anpr_confidence_pb);
goog.exportSymbol('proto.vivacity.core.Countline', null, global);
goog.exportSymbol('proto.vivacity.core.Countline.CountDirection', null, global);
goog.exportSymbol('proto.vivacity.core.CountlineCrossing', null, global);
goog.exportSymbol('proto.vivacity.core.CountlineCrossing.CrossingsDirection', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.CountlineCrossing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.CountlineCrossing.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.CountlineCrossing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.CountlineCrossing.displayName = 'proto.vivacity.core.CountlineCrossing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Countline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.Countline.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.Countline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Countline.displayName = 'proto.vivacity.core.Countline';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.CountlineCrossing.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.CountlineCrossing.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.CountlineCrossing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.CountlineCrossing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.CountlineCrossing.toObject = function(includeInstance, msg) {
  var f, obj = {
    countlineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    crossingsDirection: jspb.Message.getFieldWithDefault(msg, 2, 0),
    crossingTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    movement: (f = msg.getMovement()) && vivacity_core_movement_pb.Movement.toObject(includeInstance, f),
    cumulativeAnprConfidenceList: jspb.Message.toObjectList(msg.getCumulativeAnprConfidenceList(),
    vivacity_core_anpr_confidence_pb.AnprConfidence.toObject, includeInstance),
    anprVehicleClass: jspb.Message.getFieldWithDefault(msg, 13, 0),
    anprAssociatedTrackNumber: jspb.Message.getFieldWithDefault(msg, 14, 0),
    crossingPoint: (f = msg.getCrossingPoint()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    cumulativeStoppedMicroseconds: jspb.Message.getFieldWithDefault(msg, 15, 0),
    cumulativeTotalMicroseconds: jspb.Message.getFieldWithDefault(msg, 16, 0),
    cumulativeStoppedMicrosecondsByZoneIdMap: (f = msg.getCumulativeStoppedMicrosecondsByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeTotalMicrosecondsByZoneIdMap: (f = msg.getCumulativeTotalMicrosecondsByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    firstTimestampMicrosecondsEnteredZoneByZoneIdMap: (f = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    lastTimestampMicrosecondsLeftZoneByZoneIdMap: (f = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    trackEndTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.CountlineCrossing}
 */
proto.vivacity.core.CountlineCrossing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.CountlineCrossing;
  return proto.vivacity.core.CountlineCrossing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.CountlineCrossing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.CountlineCrossing}
 */
proto.vivacity.core.CountlineCrossing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCountlineId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.CountlineCrossing.CrossingsDirection} */ (reader.readEnum());
      msg.setCrossingsDirection(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCrossingTimestampMicroseconds(value);
      break;
    case 4:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 8:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClassId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameNumber(value);
      break;
    case 10:
      var value = new vivacity_core_movement_pb.Movement;
      reader.readMessage(value,vivacity_core_movement_pb.Movement.deserializeBinaryFromReader);
      msg.setMovement(value);
      break;
    case 11:
      var value = new vivacity_core_anpr_confidence_pb.AnprConfidence;
      reader.readMessage(value,vivacity_core_anpr_confidence_pb.AnprConfidence.deserializeBinaryFromReader);
      msg.addCumulativeAnprConfidence(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setAnprVehicleClass(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAnprAssociatedTrackNumber(value);
      break;
    case 12:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setCrossingPoint(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCumulativeStoppedMicroseconds(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCumulativeTotalMicroseconds(value);
      break;
    case 17:
      var value = msg.getCumulativeStoppedMicrosecondsByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 18:
      var value = msg.getCumulativeTotalMicrosecondsByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 19:
      var value = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 20:
      var value = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTrackEndTimestampMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.CountlineCrossing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.CountlineCrossing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.CountlineCrossing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.CountlineCrossing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountlineId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCrossingsDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCrossingTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getMovement();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_core_movement_pb.Movement.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeAnprConfidenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      vivacity_core_anpr_confidence_pb.AnprConfidence.serializeBinaryToWriter
    );
  }
  f = message.getAnprVehicleClass();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getAnprAssociatedTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCrossingPoint();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeStoppedMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getCumulativeTotalMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getCumulativeStoppedMicrosecondsByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(17, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getCumulativeTotalMicrosecondsByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(18, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(19, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getLastTimestampMicrosecondsLeftZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(20, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getTrackEndTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.CountlineCrossing.CrossingsDirection = {
  UNKNOWN_CROSSINGS_DIRECTION: 0,
  CLOCKWISE: 1,
  ANTICLOCKWISE: 2
};

/**
 * optional uint32 countline_id = 1;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCountlineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setCountlineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CrossingsDirection crossings_direction = 2;
 * @return {!proto.vivacity.core.CountlineCrossing.CrossingsDirection}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCrossingsDirection = function() {
  return /** @type {!proto.vivacity.core.CountlineCrossing.CrossingsDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.CountlineCrossing.CrossingsDirection} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setCrossingsDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 crossing_timestamp_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCrossingTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setCrossingTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TracingContext tracing_ctx = 4;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.CountlineCrossing.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 4));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
*/
proto.vivacity.core.CountlineCrossing.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.CountlineCrossing.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 restart_number = 5;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 track_number = 6;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 vision_program_id = 7;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional ClassifyingDetectorClassTypes class_id = 8;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.CountlineCrossing.prototype.getClassId = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setClassId = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional uint32 frame_number = 9;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional Movement movement = 10;
 * @return {?proto.vivacity.core.Movement}
 */
proto.vivacity.core.CountlineCrossing.prototype.getMovement = function() {
  return /** @type{?proto.vivacity.core.Movement} */ (
    jspb.Message.getWrapperField(this, vivacity_core_movement_pb.Movement, 10));
};


/**
 * @param {?proto.vivacity.core.Movement|undefined} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
*/
proto.vivacity.core.CountlineCrossing.prototype.setMovement = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearMovement = function() {
  return this.setMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.CountlineCrossing.prototype.hasMovement = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated AnprConfidence cumulative_anpr_confidence = 11;
 * @return {!Array<!proto.vivacity.core.AnprConfidence>}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCumulativeAnprConfidenceList = function() {
  return /** @type{!Array<!proto.vivacity.core.AnprConfidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_anpr_confidence_pb.AnprConfidence, 11));
};


/**
 * @param {!Array<!proto.vivacity.core.AnprConfidence>} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
*/
proto.vivacity.core.CountlineCrossing.prototype.setCumulativeAnprConfidenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.vivacity.core.AnprConfidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AnprConfidence}
 */
proto.vivacity.core.CountlineCrossing.prototype.addCumulativeAnprConfidence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.vivacity.core.AnprConfidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearCumulativeAnprConfidenceList = function() {
  return this.setCumulativeAnprConfidenceList([]);
};


/**
 * optional ClassifyingDetectorClassTypes anpr_vehicle_class = 13;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.CountlineCrossing.prototype.getAnprVehicleClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setAnprVehicleClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional uint32 anpr_associated_track_number = 14;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getAnprAssociatedTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setAnprAssociatedTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Point crossing_point = 12;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCrossingPoint = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 12));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
*/
proto.vivacity.core.CountlineCrossing.prototype.setCrossingPoint = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearCrossingPoint = function() {
  return this.setCrossingPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.CountlineCrossing.prototype.hasCrossingPoint = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint64 cumulative_stopped_microseconds = 15;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCumulativeStoppedMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setCumulativeStoppedMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint64 cumulative_total_microseconds = 16;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCumulativeTotalMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setCumulativeTotalMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * map<uint32, uint64> cumulative_stopped_microseconds_by_zone_id = 17;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCumulativeStoppedMicrosecondsByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 17, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearCumulativeStoppedMicrosecondsByZoneIdMap = function() {
  this.getCumulativeStoppedMicrosecondsByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, uint64> cumulative_total_microseconds_by_zone_id = 18;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.CountlineCrossing.prototype.getCumulativeTotalMicrosecondsByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 18, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearCumulativeTotalMicrosecondsByZoneIdMap = function() {
  this.getCumulativeTotalMicrosecondsByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, uint64> first_timestamp_microseconds_entered_zone_by_zone_id = 19;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.CountlineCrossing.prototype.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 19, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function() {
  this.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, uint64> last_timestamp_microseconds_left_zone_by_zone_id = 20;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.CountlineCrossing.prototype.getLastTimestampMicrosecondsLeftZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 20, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.clearLastTimestampMicrosecondsLeftZoneByZoneIdMap = function() {
  this.getLastTimestampMicrosecondsLeftZoneByZoneIdMap().clear();
  return this;};


/**
 * optional uint64 track_end_timestamp_microseconds = 21;
 * @return {number}
 */
proto.vivacity.core.CountlineCrossing.prototype.getTrackEndTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.CountlineCrossing} returns this
 */
proto.vivacity.core.CountlineCrossing.prototype.setTrackEndTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.Countline.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Countline.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Countline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Countline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Countline.toObject = function(includeInstance, msg) {
  var f, obj = {
    countlineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    vivacity_core_point_pb.Point.toObject, includeInstance),
    detectionBoxPoi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    countDirection: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ignoreAlternateLineSegments: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isTrackTerminatedAverageSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isTrackTerminatedCumulativeAnprConfidence: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Countline}
 */
proto.vivacity.core.Countline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Countline;
  return proto.vivacity.core.Countline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Countline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Countline}
 */
proto.vivacity.core.Countline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCountlineId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectionBoxPoi(value);
      break;
    case 6:
      var value = /** @type {!proto.vivacity.core.Countline.CountDirection} */ (reader.readEnum());
      msg.setCountDirection(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreAlternateLineSegments(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTrackTerminatedAverageSpeed(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTrackTerminatedCumulativeAnprConfidence(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Countline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Countline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Countline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Countline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountlineId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getDetectionBoxPoi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIgnoreAlternateLineSegments();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsTrackTerminatedAverageSpeed();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsTrackTerminatedCumulativeAnprConfidence();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.Countline.CountDirection = {
  UNKNOWN: 0,
  CLOCKWISE_ONLY: 1,
  ANTICLOCKWISE_ONLY: 2,
  CLOCKWISE_AND_ANTICLOCKWISE: 3
};

/**
 * optional uint32 countline_id = 1;
 * @return {number}
 */
proto.vivacity.core.Countline.prototype.getCountlineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setCountlineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.vivacity.core.Countline.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.vivacity.core.Countline.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Point points = 2;
 * @return {!Array<!proto.vivacity.core.Point>}
 */
proto.vivacity.core.Countline.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.vivacity.core.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_point_pb.Point, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.Point>} value
 * @return {!proto.vivacity.core.Countline} returns this
*/
proto.vivacity.core.Countline.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Point}
 */
proto.vivacity.core.Countline.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};


/**
 * optional string detection_box_poi = 3;
 * @return {string}
 */
proto.vivacity.core.Countline.prototype.getDetectionBoxPoi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setDetectionBoxPoi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CountDirection count_direction = 6;
 * @return {!proto.vivacity.core.Countline.CountDirection}
 */
proto.vivacity.core.Countline.prototype.getCountDirection = function() {
  return /** @type {!proto.vivacity.core.Countline.CountDirection} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.vivacity.core.Countline.CountDirection} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setCountDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool ignore_alternate_line_segments = 7;
 * @return {boolean}
 */
proto.vivacity.core.Countline.prototype.getIgnoreAlternateLineSegments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setIgnoreAlternateLineSegments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_track_terminated_average_speed = 8;
 * @return {boolean}
 */
proto.vivacity.core.Countline.prototype.getIsTrackTerminatedAverageSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setIsTrackTerminatedAverageSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_track_terminated_cumulative_anpr_confidence = 9;
 * @return {boolean}
 */
proto.vivacity.core.Countline.prototype.getIsTrackTerminatedCumulativeAnprConfidence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setIsTrackTerminatedCumulativeAnprConfidence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_trigger_for_loaded_track_terminated_cumulative_dwell_time_zones = 10;
 * @return {boolean}
 */
proto.vivacity.core.Countline.prototype.getIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.Countline} returns this
 */
proto.vivacity.core.Countline.prototype.setIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


goog.object.extend(exports, proto.vivacity.core);
