/* eslint-disable */
// source: vivacity/config_v2/primitive_wrappers.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var vivacity_config_v2_shared_metadata_class_configs_pb = require('../../vivacity/config_v2/shared_metadata_class_configs_pb.js');
goog.object.extend(proto, vivacity_config_v2_shared_metadata_class_configs_pb);
goog.exportSymbol('proto.vivacity.config_v2.PointInterface', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PointInterface.Double', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PointInterface.Integer', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.BoolValue', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.FloatValue', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.Geography', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.Int32Value', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.Int64Value', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.JsonValue', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.StringArray', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.StringValue', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue', null, global);
goog.exportSymbol('proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.FloatValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.FloatValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.Int32Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.Int32Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.Int64Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.Int64Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.BoolValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.BoolValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.StringValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.StringValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.StringValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.StringArray.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.StringArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.StringArray.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.StringArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.JsonValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.JsonValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.Geography, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.Geography';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.displayName = 'proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PointInterface = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PointInterface, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PointInterface.displayName = 'proto.vivacity.config_v2.PointInterface';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PointInterface.Integer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PointInterface.Integer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PointInterface.Integer.displayName = 'proto.vivacity.config_v2.PointInterface.Integer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.PointInterface.Double = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.PointInterface.Double, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.PointInterface.Double.displayName = 'proto.vivacity.config_v2.PointInterface.Double';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    primaryKeyId: (f = msg.getPrimaryKeyId()) && proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    referenceKeyId: (f = msg.getReferenceKeyId()) && proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.toObject(includeInstance, f),
    foreignKeyId: (f = msg.getForeignKeyId()) && proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.toObject(includeInstance, f),
    floatValue: (f = msg.getFloatValue()) && proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.toObject(includeInstance, f),
    doubleValue: (f = msg.getDoubleValue()) && proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.toObject(includeInstance, f),
    int32Value: (f = msg.getInt32Value()) && proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.toObject(includeInstance, f),
    int64Value: (f = msg.getInt64Value()) && proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.toObject(includeInstance, f),
    uInt32Value: (f = msg.getUInt32Value()) && proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    uInt32Array: (f = msg.getUInt32Array()) && proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.toObject(includeInstance, f),
    uInt64Value: (f = msg.getUInt64Value()) && proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.toObject(includeInstance, f),
    boolValue: (f = msg.getBoolValue()) && proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    stringValue: (f = msg.getStringValue()) && proto.vivacity.config_v2.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    stringArray: (f = msg.getStringArray()) && proto.vivacity.config_v2.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    timestampValue: (f = msg.getTimestampValue()) && proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    uuidValue: (f = msg.getUuidValue()) && proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    jsonValue: (f = msg.getJsonValue()) && proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    geometryIntegerPoint: (f = msg.getGeometryIntegerPoint()) && proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.toObject(includeInstance, f),
    geometryIntegerPath: (f = msg.getGeometryIntegerPath()) && proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.toObject(includeInstance, f),
    geometryIntegerPolygon: (f = msg.getGeometryIntegerPolygon()) && proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.toObject(includeInstance, f),
    geometryDoublePoint: (f = msg.getGeometryDoublePoint()) && proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.toObject(includeInstance, f),
    geometryDoublePath: (f = msg.getGeometryDoublePath()) && proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.toObject(includeInstance, f),
    geometryDoublePolygon: (f = msg.getGeometryDoublePolygon()) && proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.toObject(includeInstance, f),
    geographyPoint: (f = msg.getGeographyPoint()) && proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    geographyPath: (f = msg.getGeographyPath()) && proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.toObject(includeInstance, f),
    geographyPolygon: (f = msg.getGeographyPolygon()) && proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper}
 */
proto.vivacity.config_v2.PrimitiveWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper;
  return proto.vivacity.config_v2.PrimitiveWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper}
 */
proto.vivacity.config_v2.PrimitiveWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setPrimaryKeyId(value);
      break;
    case 4:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader);
      msg.setReferenceKeyId(value);
      break;
    case 5:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader);
      msg.setForeignKeyId(value);
      break;
    case 6:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.FloatValue;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.deserializeBinaryFromReader);
      msg.setFloatValue(value);
      break;
    case 8:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader);
      msg.setDoubleValue(value);
      break;
    case 10:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.Int32Value;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader);
      msg.setInt32Value(value);
      break;
    case 12:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.Int64Value;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.deserializeBinaryFromReader);
      msg.setInt64Value(value);
      break;
    case 14:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setUInt32Value(value);
      break;
    case 15:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.deserializeBinaryFromReader);
      msg.setUInt32Array(value);
      break;
    case 16:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.deserializeBinaryFromReader);
      msg.setUInt64Value(value);
      break;
    case 18:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setBoolValue(value);
      break;
    case 20:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.StringValue;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setStringValue(value);
      break;
    case 21:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.StringArray;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setStringArray(value);
      break;
    case 22:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    case 24:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setUuidValue(value);
      break;
    case 26:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setJsonValue(value);
      break;
    case 28:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.deserializeBinaryFromReader);
      msg.setGeometryIntegerPoint(value);
      break;
    case 29:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader);
      msg.setGeometryIntegerPath(value);
      break;
    case 30:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinaryFromReader);
      msg.setGeometryIntegerPolygon(value);
      break;
    case 31:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.deserializeBinaryFromReader);
      msg.setGeometryDoublePoint(value);
      break;
    case 32:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader);
      msg.setGeometryDoublePath(value);
      break;
    case 33:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinaryFromReader);
      msg.setGeometryDoublePolygon(value);
      break;
    case 34:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setGeographyPoint(value);
      break;
    case 35:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.deserializeBinaryFromReader);
      msg.setGeographyPath(value);
      break;
    case 36:
      var value = new proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon;
      reader.readMessage(value,proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.deserializeBinaryFromReader);
      msg.setGeographyPolygon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimaryKeyId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getReferenceKeyId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getForeignKeyId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getFloatValue();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getDoubleValue();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getInt32Value();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getInt64Value();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt32Value();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUInt32Array();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.serializeBinaryToWriter
    );
  }
  f = message.getUInt64Value();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getBoolValue();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStringValue();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStringArray();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getUuidValue();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getJsonValue();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPoint();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPath();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryIntegerPolygon();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePoint();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePath();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeometryDoublePolygon();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPoint();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPath();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.serializeBinaryToWriter
    );
  }
  f = message.getGeographyPolygon();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey;
  return proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id;
  return proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey;
  return proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    referenceClassType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    setFromBatchIndex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    referenceMustExist: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id;
  return proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} */ (reader.readEnum());
      msg.setReferenceClassType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSetFromBatchIndex(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReferenceMustExist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getReferenceClassType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getReferenceMustExist();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SharedMetadataClassConfig.ClassType reference_class_type = 2;
 * @return {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.getReferenceClassType = function() {
  return /** @type {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.setReferenceClassType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 set_from_batch_index = 3;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.getSetFromBatchIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.setSetFromBatchIndex = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.clearSetFromBatchIndex = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.hasSetFromBatchIndex = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool reference_must_exist = 4;
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.getReferenceMustExist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id.prototype.setReferenceMustExist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey;
  return proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    referenceClassType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    setFromBatchIndex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    referenceMustExist: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id;
  return proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} */ (reader.readEnum());
      msg.setReferenceClassType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSetFromBatchIndex(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReferenceMustExist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getReferenceClassType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getReferenceMustExist();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SharedMetadataClassConfig.ClassType reference_class_type = 2;
 * @return {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.getReferenceClassType = function() {
  return /** @type {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config_v2.SharedMetadataClassConfig.ClassType} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.setReferenceClassType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 set_from_batch_index = 3;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.getSetFromBatchIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.setSetFromBatchIndex = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.clearSetFromBatchIndex = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.hasSetFromBatchIndex = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool reference_must_exist = 4;
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.getReferenceMustExist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id.prototype.setReferenceMustExist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.FloatValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.FloatValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.FloatValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.FloatValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.Int32Value;
  return proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int32Value.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int64Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.Int64Value;
  return proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int64Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Int64Value.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value;
  return proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array;
  return proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 values = 1;
 * @return {!Array<number>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value;
  return proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.BoolValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool value = 1;
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.BoolValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.StringValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.StringValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.StringValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.StringValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.StringValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.StringValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.StringValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.StringArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.StringArray;
  return proto.vivacity.config_v2.PrimitiveWrapper.StringArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.StringArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.StringArray} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.StringArray.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp value = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.JsonValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.JsonValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.vivacity.config_v2.PointInterface.Integer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Integer;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Integer.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Integer.serializeBinaryToWriter
    );
  }
};


/**
 * optional PointInterface.Integer value = 1;
 * @return {?proto.vivacity.config_v2.PointInterface.Integer}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.prototype.getValue = function() {
  return /** @type{?proto.vivacity.config_v2.PointInterface.Integer} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PointInterface.Integer, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PointInterface.Integer|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.vivacity.config_v2.PointInterface.Integer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Integer;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Integer.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Integer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointInterface.Integer values = 1;
 * @return {!Array<!proto.vivacity.config_v2.PointInterface.Integer>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.PointInterface.Integer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.PointInterface.Integer, 1));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.PointInterface.Integer>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.config_v2.PointInterface.Integer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PointInterface.Integer}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.config_v2.PointInterface.Integer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.vivacity.config_v2.PointInterface.Integer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Integer;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Integer.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Integer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointInterface.Integer values = 1;
 * @return {!Array<!proto.vivacity.config_v2.PointInterface.Integer>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.PointInterface.Integer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.PointInterface.Integer, 1));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.PointInterface.Integer>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.config_v2.PointInterface.Integer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PointInterface.Integer}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.config_v2.PointInterface.Integer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.vivacity.config_v2.PointInterface.Double.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Double;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter
    );
  }
};


/**
 * optional PointInterface.Double value = 1;
 * @return {?proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.prototype.getValue = function() {
  return /** @type{?proto.vivacity.config_v2.PointInterface.Double} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PointInterface.Double, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PointInterface.Double|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.vivacity.config_v2.PointInterface.Double.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Double;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointInterface.Double values = 1;
 * @return {!Array<!proto.vivacity.config_v2.PointInterface.Double>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.PointInterface.Double>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.PointInterface.Double, 1));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.PointInterface.Double>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.config_v2.PointInterface.Double=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.config_v2.PointInterface.Double, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.vivacity.config_v2.PointInterface.Double.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon;
  return proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Double;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointInterface.Double values = 1;
 * @return {!Array<!proto.vivacity.config_v2.PointInterface.Double>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.PointInterface.Double>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.PointInterface.Double, 1));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.PointInterface.Double>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.config_v2.PointInterface.Double=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.config_v2.PointInterface.Double, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.Geography;
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.vivacity.config_v2.PointInterface.Double.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point;
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Double;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter
    );
  }
};


/**
 * optional PointInterface.Double value = 1;
 * @return {?proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.prototype.getValue = function() {
  return /** @type{?proto.vivacity.config_v2.PointInterface.Double} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PointInterface.Double, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PointInterface.Double|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.vivacity.config_v2.PointInterface.Double.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path;
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Double;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointInterface.Double values = 1;
 * @return {!Array<!proto.vivacity.config_v2.PointInterface.Double>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.PointInterface.Double>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.PointInterface.Double, 1));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.PointInterface.Double>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.config_v2.PointInterface.Double=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.config_v2.PointInterface.Double, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.vivacity.config_v2.PointInterface.Double.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon;
  return proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.PointInterface.Double;
      reader.readMessage(value,proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointInterface.Double values = 1;
 * @return {!Array<!proto.vivacity.config_v2.PointInterface.Double>}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.PointInterface.Double>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.PointInterface.Double, 1));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.PointInterface.Double>} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.config_v2.PointInterface.Double=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.config_v2.PointInterface.Double, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue;
  return proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PrimaryKey.Id primary_key__id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getPrimaryKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setPrimaryKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearPrimaryKeyId = function() {
  return this.setPrimaryKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasPrimaryKeyId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReferenceKey.Id reference_key__id = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getReferenceKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ReferenceKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setReferenceKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearReferenceKeyId = function() {
  return this.setReferenceKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasReferenceKeyId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ForeignKey.Id foreign_key__id = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getForeignKeyId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.ForeignKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setForeignKeyId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearForeignKeyId = function() {
  return this.setForeignKeyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasForeignKeyId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FloatValue float_value = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getFloatValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.FloatValue, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.FloatValue|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setFloatValue = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearFloatValue = function() {
  return this.setFloatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DoubleValue double_value = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getDoubleValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.DoubleValue|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setDoubleValue = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearDoubleValue = function() {
  return this.setDoubleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Int32Value int32_value = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.Int32Value, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int32Value|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearInt32Value = function() {
  return this.setInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasInt32Value = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Int64Value int64_value = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.Int64Value, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Int64Value|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearInt64Value = function() {
  return this.setInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasInt64Value = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional UInt32Value u_int32_value = 14;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getUInt32Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value, 14));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setUInt32Value = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearUInt32Value = function() {
  return this.setUInt32Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasUInt32Value = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional UInt32Array u_int32_array = 15;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getUInt32Array = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array, 15));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setUInt32Array = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearUInt32Array = function() {
  return this.setUInt32Array(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasUInt32Array = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional UInt64Value u_int64_value = 16;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getUInt64Value = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value, 16));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt64Value|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setUInt64Value = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearUInt64Value = function() {
  return this.setUInt64Value(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasUInt64Value = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional BoolValue bool_value = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getBoolValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.BoolValue, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setBoolValue = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearBoolValue = function() {
  return this.setBoolValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional StringValue string_value = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getStringValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.StringValue, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setStringValue = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearStringValue = function() {
  return this.setStringValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional StringArray string_array = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getStringArray = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.StringArray, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setStringArray = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearStringArray = function() {
  return this.setStringArray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasStringArray = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional TimestampValue timestamp_value = 22;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getTimestampValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue, 22));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setTimestampValue = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional UUIDValue uuid_value = 24;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getUuidValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue, 24));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setUuidValue = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearUuidValue = function() {
  return this.setUuidValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasUuidValue = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional JsonValue json_value = 26;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getJsonValue = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.JsonValue, 26));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setJsonValue = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearJsonValue = function() {
  return this.setJsonValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasJsonValue = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional GeometryInteger.Point geometry_integer__point = 28;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeometryIntegerPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point, 28));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Point|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeometryIntegerPoint = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeometryIntegerPoint = function() {
  return this.setGeometryIntegerPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeometryIntegerPoint = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional GeometryInteger.Path geometry_integer__path = 29;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeometryIntegerPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path, 29));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Path|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeometryIntegerPath = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeometryIntegerPath = function() {
  return this.setGeometryIntegerPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeometryIntegerPath = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional GeometryInteger.Polygon geometry_integer__polygon = 30;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeometryIntegerPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon, 30));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryInteger.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeometryIntegerPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeometryIntegerPolygon = function() {
  return this.setGeometryIntegerPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeometryIntegerPolygon = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional GeometryDouble.Point geometry_double__point = 31;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeometryDoublePoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point, 31));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Point|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeometryDoublePoint = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeometryDoublePoint = function() {
  return this.setGeometryDoublePoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeometryDoublePoint = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional GeometryDouble.Path geometry_double__path = 32;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeometryDoublePath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path, 32));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Path|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeometryDoublePath = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeometryDoublePath = function() {
  return this.setGeometryDoublePath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeometryDoublePath = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional GeometryDouble.Polygon geometry_double__polygon = 33;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeometryDoublePolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon, 33));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.GeometryDouble.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeometryDoublePolygon = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeometryDoublePolygon = function() {
  return this.setGeometryDoublePolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeometryDoublePolygon = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional Geography.Point geography__point = 34;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeographyPoint = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point, 34));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeographyPoint = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeographyPoint = function() {
  return this.setGeographyPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeographyPoint = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional Geography.Path geography__path = 35;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeographyPath = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path, 35));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Path|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeographyPath = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeographyPath = function() {
  return this.setGeographyPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeographyPath = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional Geography.Polygon geography__polygon = 36;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.getGeographyPolygon = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon, 36));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Polygon|undefined} value
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
*/
proto.vivacity.config_v2.PrimitiveWrapper.prototype.setGeographyPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.PrimitiveWrapper} returns this
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.clearGeographyPolygon = function() {
  return this.setGeographyPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.PrimitiveWrapper.prototype.hasGeographyPolygon = function() {
  return jspb.Message.getField(this, 36) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PointInterface.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PointInterface.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PointInterface} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PointInterface.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PointInterface}
 */
proto.vivacity.config_v2.PointInterface.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PointInterface;
  return proto.vivacity.config_v2.PointInterface.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PointInterface} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PointInterface}
 */
proto.vivacity.config_v2.PointInterface.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PointInterface.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PointInterface.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PointInterface} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PointInterface.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PointInterface.Integer.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PointInterface.Integer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PointInterface.Integer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PointInterface.Integer.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFieldWithDefault(msg, 1, 0),
    y: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PointInterface.Integer}
 */
proto.vivacity.config_v2.PointInterface.Integer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PointInterface.Integer;
  return proto.vivacity.config_v2.PointInterface.Integer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PointInterface.Integer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PointInterface.Integer}
 */
proto.vivacity.config_v2.PointInterface.Integer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PointInterface.Integer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PointInterface.Integer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PointInterface.Integer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PointInterface.Integer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 x = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PointInterface.Integer.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PointInterface.Integer} returns this
 */
proto.vivacity.config_v2.PointInterface.Integer.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 y = 2;
 * @return {number}
 */
proto.vivacity.config_v2.PointInterface.Integer.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PointInterface.Integer} returns this
 */
proto.vivacity.config_v2.PointInterface.Integer.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.PointInterface.Double.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.PointInterface.Double.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.PointInterface.Double} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PointInterface.Double.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PointInterface.Double.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.PointInterface.Double;
  return proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.PointInterface.Double} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.PointInterface.Double}
 */
proto.vivacity.config_v2.PointInterface.Double.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.PointInterface.Double.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.PointInterface.Double} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.PointInterface.Double.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.vivacity.config_v2.PointInterface.Double.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PointInterface.Double} returns this
 */
proto.vivacity.config_v2.PointInterface.Double.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.vivacity.config_v2.PointInterface.Double.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config_v2.PointInterface.Double} returns this
 */
proto.vivacity.config_v2.PointInterface.Double.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


goog.object.extend(exports, proto.vivacity.config_v2);
