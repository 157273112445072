import React, {PropsWithChildren, RefObject} from "react";
import StoreRoot from "../../../../stores/StoreRoot";
import {Container, Sprite} from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import {VideoIDKey} from "../../types";

type ContainerProps = {
    appState: StoreRoot
    imagePath?: string
    image?: HTMLImageElement
    videoID?: VideoIDKey
    videoRefs?: Map<VideoIDKey, RefObject<HTMLVideoElement>>
    forceVideoUpdate?: () => void
    backgroundBlur: number
    backgroundAlpha: number
    x: number
    y: number
    height: number
    width: number
}

const ContainerWithBackground: React.FunctionComponent<PropsWithChildren<ContainerProps>> = (props: PropsWithChildren<ContainerProps>) => {
    if (props.forceVideoUpdate) {
        props.forceVideoUpdate()
    }
    const backgroundFilters : PIXI.Filter[] = [
        new PIXI.filters.AlphaFilter(props.backgroundAlpha)
    ];

    if (props.backgroundBlur > 0) {
        const blurFilter = new PIXI.filters.BlurFilter(props.backgroundBlur, 2);
        backgroundFilters.push(blurFilter);
    }

    let maybeSprite;
    let maybeRef;
    if (props.videoID && props.videoRefs && props.videoRefs.has(props.videoID)) {
        maybeRef = props.videoRefs.get(props.videoID);
    }

    if (props.imagePath) {
        maybeSprite = (
            <Sprite
                texture={PIXI.Texture.from(props.imagePath)}
                filters={backgroundFilters}
                width={props.width}
                height={props.height}
            />
        );
    } else if (props.image) {
        maybeSprite = (
            <Sprite
                texture={PIXI.Texture.from(props.image)}
                filters={backgroundFilters}
                width={props.width}
                height={props.height}
            />
        )
    } else if (maybeRef && maybeRef.current) {
        maybeSprite = (
            <Sprite
                filters={backgroundFilters}
                texture={PIXI.Texture.from(maybeRef.current, {scaleMode: PIXI.SCALE_MODES.LINEAR}, false)}
                width={props.width}
                height={props.height}
            />
        );
    } else {
        maybeSprite = null;
    }
    return (
        <React.Fragment>
            <Container x={props.x} y={props.y}>
                {maybeSprite}
                {props.children}
            </Container>
        </React.Fragment>
    );
};

export default ContainerWithBackground