/* eslint-disable */
// source: vivacity/config/file_templates.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.vivacity.config.FileTemplate', null, global);
goog.exportSymbol('proto.vivacity.config.FileTemplateChildQuery', null, global);
goog.exportSymbol('proto.vivacity.config.RenderedFile', null, global);
goog.exportSymbol('proto.vivacity.config.RetrieveFile', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.FileTemplateChildQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.FileTemplateChildQuery.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.FileTemplateChildQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.FileTemplateChildQuery.displayName = 'proto.vivacity.config.FileTemplateChildQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.FileTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.FileTemplate.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.FileTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.FileTemplate.displayName = 'proto.vivacity.config.FileTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.RenderedFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.RenderedFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.RenderedFile.displayName = 'proto.vivacity.config.RenderedFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.RetrieveFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.RetrieveFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.RetrieveFile.displayName = 'proto.vivacity.config.RetrieveFile';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.FileTemplateChildQuery.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.FileTemplateChildQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.FileTemplateChildQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.FileTemplateChildQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, ""),
    childQueriesList: jspb.Message.toObjectList(msg.getChildQueriesList(),
    proto.vivacity.config.FileTemplateChildQuery.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.FileTemplateChildQuery}
 */
proto.vivacity.config.FileTemplateChildQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.FileTemplateChildQuery;
  return proto.vivacity.config.FileTemplateChildQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.FileTemplateChildQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.FileTemplateChildQuery}
 */
proto.vivacity.config.FileTemplateChildQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 3:
      var value = new proto.vivacity.config.FileTemplateChildQuery;
      reader.readMessage(value,proto.vivacity.config.FileTemplateChildQuery.deserializeBinaryFromReader);
      msg.addChildQueries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.FileTemplateChildQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.FileTemplateChildQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.FileTemplateChildQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChildQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.config.FileTemplateChildQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplateChildQuery} returns this
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplateChildQuery} returns this
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FileTemplateChildQuery child_queries = 3;
 * @return {!Array<!proto.vivacity.config.FileTemplateChildQuery>}
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.getChildQueriesList = function() {
  return /** @type{!Array<!proto.vivacity.config.FileTemplateChildQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.FileTemplateChildQuery, 3));
};


/**
 * @param {!Array<!proto.vivacity.config.FileTemplateChildQuery>} value
 * @return {!proto.vivacity.config.FileTemplateChildQuery} returns this
*/
proto.vivacity.config.FileTemplateChildQuery.prototype.setChildQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.config.FileTemplateChildQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.FileTemplateChildQuery}
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.addChildQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.config.FileTemplateChildQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.FileTemplateChildQuery} returns this
 */
proto.vivacity.config.FileTemplateChildQuery.prototype.clearChildQueriesList = function() {
  return this.setChildQueriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.FileTemplate.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.FileTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.FileTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.FileTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.FileTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    query: jspb.Message.getFieldWithDefault(msg, 3, ""),
    childQueriesList: jspb.Message.toObjectList(msg.getChildQueriesList(),
    proto.vivacity.config.FileTemplateChildQuery.toObject, includeInstance),
    hardwareIdQuery: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fileTemplate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pathTemplate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fsUser: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fsGroup: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fsPermissions: jspb.Message.getFieldWithDefault(msg, 10, ""),
    leftDelimiter: jspb.Message.getFieldWithDefault(msg, 11, ""),
    rightDelimiter: jspb.Message.getFieldWithDefault(msg, 12, ""),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    name: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.FileTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.FileTemplate;
  return proto.vivacity.config.FileTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.FileTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.FileTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 4:
      var value = new proto.vivacity.config.FileTemplateChildQuery;
      reader.readMessage(value,proto.vivacity.config.FileTemplateChildQuery.deserializeBinaryFromReader);
      msg.addChildQueries(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareIdQuery(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileTemplate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPathTemplate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsUser(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsGroup(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsPermissions(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftDelimiter(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightDelimiter(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.FileTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.FileTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.FileTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.FileTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChildQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vivacity.config.FileTemplateChildQuery.serializeBinaryToWriter
    );
  }
  f = message.getHardwareIdQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFileTemplate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPathTemplate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFsUser();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFsGroup();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFsPermissions();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLeftDelimiter();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRightDelimiter();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.FileTemplate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string entity_type = 2;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string query = 3;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FileTemplateChildQuery child_queries = 4;
 * @return {!Array<!proto.vivacity.config.FileTemplateChildQuery>}
 */
proto.vivacity.config.FileTemplate.prototype.getChildQueriesList = function() {
  return /** @type{!Array<!proto.vivacity.config.FileTemplateChildQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.FileTemplateChildQuery, 4));
};


/**
 * @param {!Array<!proto.vivacity.config.FileTemplateChildQuery>} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
*/
proto.vivacity.config.FileTemplate.prototype.setChildQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vivacity.config.FileTemplateChildQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.FileTemplateChildQuery}
 */
proto.vivacity.config.FileTemplate.prototype.addChildQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vivacity.config.FileTemplateChildQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.clearChildQueriesList = function() {
  return this.setChildQueriesList([]);
};


/**
 * optional string hardware_id_query = 5;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getHardwareIdQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setHardwareIdQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string file_template = 6;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getFileTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setFileTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string path_template = 7;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getPathTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setPathTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string fs_user = 8;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getFsUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setFsUser = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string fs_group = 9;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getFsGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setFsGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string fs_permissions = 10;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getFsPermissions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setFsPermissions = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string left_delimiter = 11;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getLeftDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setLeftDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string right_delimiter = 12;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getRightDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setRightDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string name = 14;
 * @return {string}
 */
proto.vivacity.config.FileTemplate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.FileTemplate} returns this
 */
proto.vivacity.config.FileTemplate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.RenderedFile.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.RenderedFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.RenderedFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.RenderedFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fileTemplateId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    renderedFile: jspb.Message.getFieldWithDefault(msg, 5, ""),
    renderedPath: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oldRenderedPath: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fsUser: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fsGroup: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fsPermissions: jspb.Message.getFieldWithDefault(msg, 10, ""),
    pb_delete: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    dryRun: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    dryRunFileTemplate: (f = msg.getDryRunFileTemplate()) && proto.vivacity.config.FileTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.RenderedFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.RenderedFile;
  return proto.vivacity.config.RenderedFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.RenderedFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.RenderedFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFileTemplateId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRenderedFile(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRenderedPath(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldRenderedPath(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsUser(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsGroup(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsPermissions(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelete(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDryRun(value);
      break;
    case 13:
      var value = new proto.vivacity.config.FileTemplate;
      reader.readMessage(value,proto.vivacity.config.FileTemplate.deserializeBinaryFromReader);
      msg.setDryRunFileTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.RenderedFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.RenderedFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.RenderedFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.RenderedFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFileTemplateId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRenderedFile();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRenderedPath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOldRenderedPath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFsUser();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFsGroup();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFsPermissions();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDelete();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getDryRun();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDryRunFileTemplate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.vivacity.config.FileTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.RenderedFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 hardware_id = 2;
 * @return {number}
 */
proto.vivacity.config.RenderedFile.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 entity_id = 3;
 * @return {number}
 */
proto.vivacity.config.RenderedFile.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 file_template_id = 4;
 * @return {number}
 */
proto.vivacity.config.RenderedFile.prototype.getFileTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setFileTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string rendered_file = 5;
 * @return {string}
 */
proto.vivacity.config.RenderedFile.prototype.getRenderedFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setRenderedFile = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string rendered_path = 6;
 * @return {string}
 */
proto.vivacity.config.RenderedFile.prototype.getRenderedPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setRenderedPath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string old_rendered_path = 7;
 * @return {string}
 */
proto.vivacity.config.RenderedFile.prototype.getOldRenderedPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setOldRenderedPath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string fs_user = 8;
 * @return {string}
 */
proto.vivacity.config.RenderedFile.prototype.getFsUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setFsUser = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string fs_group = 9;
 * @return {string}
 */
proto.vivacity.config.RenderedFile.prototype.getFsGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setFsGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string fs_permissions = 10;
 * @return {string}
 */
proto.vivacity.config.RenderedFile.prototype.getFsPermissions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setFsPermissions = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool delete = 11;
 * @return {boolean}
 */
proto.vivacity.config.RenderedFile.prototype.getDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool dry_run = 12;
 * @return {boolean}
 */
proto.vivacity.config.RenderedFile.prototype.getDryRun = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.setDryRun = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional FileTemplate dry_run_file_template = 13;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.RenderedFile.prototype.getDryRunFileTemplate = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.FileTemplate, 13));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.RenderedFile} returns this
*/
proto.vivacity.config.RenderedFile.prototype.setDryRunFileTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.RenderedFile} returns this
 */
proto.vivacity.config.RenderedFile.prototype.clearDryRunFileTemplate = function() {
  return this.setDryRunFileTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.RenderedFile.prototype.hasDryRunFileTemplate = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.RetrieveFile.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.RetrieveFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.RetrieveFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.RetrieveFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.RetrieveFile}
 */
proto.vivacity.config.RetrieveFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.RetrieveFile;
  return proto.vivacity.config.RetrieveFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.RetrieveFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.RetrieveFile}
 */
proto.vivacity.config.RetrieveFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.RetrieveFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.RetrieveFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.RetrieveFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.RetrieveFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 hardware_id = 1;
 * @return {number}
 */
proto.vivacity.config.RetrieveFile.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.RetrieveFile} returns this
 */
proto.vivacity.config.RetrieveFile.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.vivacity.config);
