import WebRTCPeerConnectionState, { WebRTCError } from "./WebRTCPeerConnectionState";
import SignallingServerConnection from "./SignallingServerConnection";
import DetectorTrackerFrameStore from "./DetectorTrackerFrameStore";
import { PeerID, PrincipalID, RemotePeer } from "../components/common/types";
import { action } from "mobx";

const signallingHostname =  window.location.hostname === "localhost" ? "webrtc-signalling.vivacitylabs.com" : window.location.hostname.replace("webrtc.", "webrtc-signalling.");
const signallingConn = new SignallingServerConnection(`wss://${signallingHostname}/webrtc_signalling/browser`);
const webRTCPeerConnState = new WebRTCPeerConnectionState(signallingConn);

const remotePeers: Map<PrincipalID, RemotePeer> = new Map<PrincipalID, RemotePeer>();



class StoreRoot {
    RemotePeers = remotePeers;
    SignallingServerConnection = signallingConn;
    RTC = webRTCPeerConnState;
    DetectorTrackerFrames = new DetectorTrackerFrameStore(webRTCPeerConnState);

    @action removeRemotePeerAndNotify(peerID: PeerID, principalID: PrincipalID, error: WebRTCError) {
        this.RTC.globalErrors.push(error);
        this.RTC.currentPeerIdByPrincipalId.delete(principalID);
        this.RemotePeers.delete(principalID);
    }
}

export default StoreRoot;