/* eslint-disable */
// source: vivacity/config/cv_model_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.vivacity.config.CVModel', null, global);
goog.exportSymbol('proto.vivacity.config.CVModelClass', null, global);
goog.exportSymbol('proto.vivacity.config.CVModelType', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramPreset', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CVModelType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.CVModelType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CVModelType.displayName = 'proto.vivacity.config.CVModelType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CVModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.CVModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CVModel.displayName = 'proto.vivacity.config.CVModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CVModelClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CVModelClass.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CVModelClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CVModelClass.displayName = 'proto.vivacity.config.CVModelClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramPreset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramPreset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramPreset.displayName = 'proto.vivacity.config.VisionProgramPreset';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CVModelType.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CVModelType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CVModelType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CVModelType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    prettyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.CVModelType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CVModelType;
  return proto.vivacity.config.CVModelType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CVModelType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CVModelType}
 */
proto.vivacity.config.CVModelType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrettyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CVModelType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CVModelType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CVModelType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CVModelType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrettyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.CVModelType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModelType} returns this
 */
proto.vivacity.config.CVModelType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correlation_id = 2;
 * @return {string}
 */
proto.vivacity.config.CVModelType.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModelType} returns this
 */
proto.vivacity.config.CVModelType.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vivacity.config.CVModelType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModelType} returns this
 */
proto.vivacity.config.CVModelType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string pretty_name = 4;
 * @return {string}
 */
proto.vivacity.config.CVModelType.prototype.getPrettyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModelType} returns this
 */
proto.vivacity.config.CVModelType.prototype.setPrettyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.vivacity.config.CVModelType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModelType} returns this
 */
proto.vivacity.config.CVModelType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CVModel.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CVModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CVModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CVModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    prettyName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    minimumSupermarioVersion: jspb.Message.getFieldWithDefault(msg, 7, 0),
    weightsPath: jspb.Message.getFieldWithDefault(msg, 8, ""),
    configPath: jspb.Message.getFieldWithDefault(msg, 9, ""),
    trainingResWidth: jspb.Message.getFieldWithDefault(msg, 10, 0),
    trainingResHeight: jspb.Message.getFieldWithDefault(msg, 11, 0),
    runtimeResWidth: jspb.Message.getFieldWithDefault(msg, 12, 0),
    runtimeResHeight: jspb.Message.getFieldWithDefault(msg, 13, 0),
    resolution: jspb.Message.getFieldWithDefault(msg, 14, ""),
    md5Hash: jspb.Message.getFieldWithDefault(msg, 15, ""),
    crc32c: jspb.Message.getFieldWithDefault(msg, 16, ""),
    operateOnModelTypeId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    nvInferConfig: jspb.Message.getFieldWithDefault(msg, 18, ""),
    labelsConfig: jspb.Message.getFieldWithDefault(msg, 19, ""),
    classConfusionMatrices: jspb.Message.getFieldWithDefault(msg, 20, ""),
    releaseDate: jspb.Message.getFieldWithDefault(msg, 21, 0),
    onnxPath: jspb.Message.getFieldWithDefault(msg, 22, ""),
    engineFilename: jspb.Message.getFieldWithDefault(msg, 23, ""),
    gieId: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CVModel}
 */
proto.vivacity.config.CVModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CVModel;
  return proto.vivacity.config.CVModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CVModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CVModel}
 */
proto.vivacity.config.CVModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTypeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrettyName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinimumSupermarioVersion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeightsPath(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigPath(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrainingResWidth(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrainingResHeight(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRuntimeResWidth(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRuntimeResHeight(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolution(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMd5Hash(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrc32c(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOperateOnModelTypeId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvInferConfig(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelsConfig(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassConfusionMatrices(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReleaseDate(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnnxPath(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngineFilename(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGieId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CVModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CVModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CVModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CVModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypeId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrettyName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMinimumSupermarioVersion();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getWeightsPath();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConfigPath();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTrainingResWidth();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getTrainingResHeight();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getRuntimeResWidth();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getRuntimeResHeight();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getResolution();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMd5Hash();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCrc32c();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getOperateOnModelTypeId();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getNvInferConfig();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLabelsConfig();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getClassConfusionMatrices();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getReleaseDate();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getOnnxPath();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getEngineFilename();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getGieId();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correlation_id = 2;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 type_id = 3;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pretty_name = 5;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getPrettyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setPrettyName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 minimum_supermario_version = 7;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getMinimumSupermarioVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setMinimumSupermarioVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string weights_path = 8;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getWeightsPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setWeightsPath = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string config_path = 9;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getConfigPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setConfigPath = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 training_res_width = 10;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getTrainingResWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setTrainingResWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 training_res_height = 11;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getTrainingResHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setTrainingResHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 runtime_res_width = 12;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getRuntimeResWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setRuntimeResWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 runtime_res_height = 13;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getRuntimeResHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setRuntimeResHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string resolution = 14;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getResolution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setResolution = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string md5_hash = 15;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getMd5Hash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setMd5Hash = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string crc32c = 16;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getCrc32c = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setCrc32c = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional uint32 operate_on_model_type_id = 17;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getOperateOnModelTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setOperateOnModelTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string nv_infer_config = 18;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getNvInferConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setNvInferConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string labels_config = 19;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getLabelsConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setLabelsConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string class_confusion_matrices = 20;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getClassConfusionMatrices = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setClassConfusionMatrices = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional uint64 release_date = 21;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getReleaseDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setReleaseDate = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string onnx_path = 22;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getOnnxPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setOnnxPath = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string engine_filename = 23;
 * @return {string}
 */
proto.vivacity.config.CVModel.prototype.getEngineFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setEngineFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional uint32 gie_id = 24;
 * @return {number}
 */
proto.vivacity.config.CVModel.prototype.getGieId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModel} returns this
 */
proto.vivacity.config.CVModel.prototype.setGieId = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CVModelClass.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CVModelClass.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CVModelClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CVModelClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CVModelClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    classIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.CVModelClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CVModelClass;
  return proto.vivacity.config.CVModelClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CVModelClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CVModelClass}
 */
proto.vivacity.config.CVModelClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addClassIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CVModelClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CVModelClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CVModelClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CVModelClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getClassIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 model_id = 1;
 * @return {number}
 */
proto.vivacity.config.CVModelClass.prototype.getModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CVModelClass} returns this
 */
proto.vivacity.config.CVModelClass.prototype.setModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated uint32 class_ids = 2;
 * @return {!Array<number>}
 */
proto.vivacity.config.CVModelClass.prototype.getClassIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.config.CVModelClass} returns this
 */
proto.vivacity.config.CVModelClass.prototype.setClassIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CVModelClass} returns this
 */
proto.vivacity.config.CVModelClass.prototype.addClassIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CVModelClass} returns this
 */
proto.vivacity.config.CVModelClass.prototype.clearClassIdsList = function() {
  return this.setClassIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramPreset.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramPreset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramPreset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramPreset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    version: jspb.Message.getFieldWithDefault(msg, 5, 0),
    doNotCollapse: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    primaryModelResolution: jspb.Message.getFieldWithDefault(msg, 7, ""),
    primaryModelId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    primaryPlateModelId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    secondary3dPoseModelId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    secondaryOcrDetectorModelId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    secondaryClassifierHumanoidModelId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.VisionProgramPreset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramPreset;
  return proto.vivacity.config.VisionProgramPreset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramPreset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramPreset}
 */
proto.vivacity.config.VisionProgramPreset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotCollapse(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryModelResolution(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrimaryModelId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrimaryPlateModelId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSecondary3dPoseModelId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSecondaryOcrDetectorModelId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSecondaryClassifierHumanoidModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramPreset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramPreset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramPreset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramPreset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDoNotCollapse();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPrimaryModelResolution();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrimaryModelId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getPrimaryPlateModelId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getSecondary3dPoseModelId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getSecondaryOcrDetectorModelId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getSecondaryClassifierHumanoidModelId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correlation_id = 2;
 * @return {string}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 version = 5;
 * @return {number}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool do_not_collapse = 6;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getDoNotCollapse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setDoNotCollapse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string primary_model_resolution = 7;
 * @return {string}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getPrimaryModelResolution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setPrimaryModelResolution = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 primary_model_id = 8;
 * @return {number}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getPrimaryModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setPrimaryModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 primary_plate_model_id = 9;
 * @return {number}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getPrimaryPlateModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setPrimaryPlateModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 secondary_3d_pose_model_id = 10;
 * @return {number}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getSecondary3dPoseModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setSecondary3dPoseModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 secondary_ocr_detector_model_id = 11;
 * @return {number}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getSecondaryOcrDetectorModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setSecondaryOcrDetectorModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 secondary_classifier_humanoid_model_id = 12;
 * @return {number}
 */
proto.vivacity.config.VisionProgramPreset.prototype.getSecondaryClassifierHumanoidModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramPreset} returns this
 */
proto.vivacity.config.VisionProgramPreset.prototype.setSecondaryClassifierHumanoidModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


goog.object.extend(exports, proto.vivacity.config);
