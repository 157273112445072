import ReconnectingWebSocket, {CloseEvent, ErrorEvent} from 'reconnecting-websocket';
import EventEmitter from "events";
import {action, observable} from "mobx";
import {WebRTCSignalling} from "../vivacity/core/webrtc_peer_connector_pb";
import {WebSocketsAuthentication} from "../vivacity/core/websockets_pb";
import base64ArrayBuffer from "../utils/base64";
import jwtDecode from "jwt-decode";

export default class SignallingServerConnection extends EventEmitter {

    private readonly url: string = "";

    public connection: ReconnectingWebSocket;

    @observable public signallingMessages: string = "";

    @observable public connectionStatus: string = "NOT STARTED";

    @observable public jwt: string = "";

    @observable public jwtName: string = ""

    constructor(url: string) {
        super();
        this.connection = new ReconnectingWebSocket(url, ["WebRTCSignalling+JWT", "WebRTCSignalling"], {
            maxReconnectionDelay: 30,
            minReconnectionDelay: 3,
            reconnectionDelayGrowFactor: 1.5,
            maxRetries: 50,
            startClosed: true,
        });

        this.url = url;
        this.connection.addEventListener('close', this.handleClose.bind(this));
        this.connection.addEventListener('open', this.handleConnect.bind(this));
        this.connection.addEventListener('error', this.handleConnectFailure.bind(this));
        this.connection.addEventListener('message', this.handleMessage.bind(this));
        this.connection.binaryType = 'arraybuffer';
    }

    @action setJwt(jwt: string) {
        try {
            let decoded: any = jwtDecode(jwt);
            this.jwtName = decoded["name"];
        } catch (e) {
            console.error(e);
            this.jwt = "";
            return
        }
        this.jwt = jwt;

        if (this.connection.readyState === this.connection.OPEN) {
            this.connection.reconnect(1000, "re-authenticating with new credential");
        } else {
            this.connection = new ReconnectingWebSocket(this.url, ["WebRTCSignalling+JWT", "WebRTCSignalling"], {
                maxReconnectionDelay: 30,
                minReconnectionDelay: 3,
                reconnectionDelayGrowFactor: 1.5,
                maxRetries: 50,
                startClosed: false,
            });
            this.connection.addEventListener('close', this.handleClose.bind(this));
            this.connection.addEventListener('open', this.handleConnect.bind(this));
            this.connection.addEventListener('error', this.handleConnectFailure.bind(this));
            this.connection.addEventListener('message', this.handleMessage.bind(this));
            this.connection.binaryType = 'arraybuffer';
        }
    }

    @action handleClose(e: CloseEvent) {
        this.updateConnectionStatus();
        console.log(e);
        // if (e.code !== 1000) {
        //     alert("Signalling Server Connection Closed ("+ e.code+"): " + e.reason);
        // }

        // Closing the connection prevents it from reconnecting automatically - only reconnect when setting the JWT
        this.connection.close(e.code, e.reason);

        this.emit("close", e);
    }

    @action handleConnect() {
        if (this.connection.protocol === "WebRTCSignalling+JWT") {
            try {
                jwtDecode(this.jwt);
            } catch (e) {
                this.jwt = "";
                console.log("ARSE", e);
                alert("Can't authenticate with JWT since JWT is not set");
                this.connection.close(4050, "no JWT available to auth with");
                return;
            }

            const msg = new WebSocketsAuthentication();
            const userId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
            msg.setUserId(this.jwtName + "-" + userId +"-browser@vivacitylabs.com");
            msg.setJwt(this.jwt);

            const data = msg.serializeBinary();
            this.connection.send(data);
        } else if (this.connection.protocol === "WebRTCSignalling") {
            // Do nothing on connection
        } else {
            console.log("unexpected WebSockets protocol:", this.connection.protocol);
            return;
        }

        this.emit("open");
        this.updateConnectionStatus();
    }

    @action handleConnectFailure(err: ErrorEvent) {
        console.error("Websockets connection failure: ", err.message);
        this.updateConnectionStatus();
    }

    @action handleMessage(message: MessageEvent) {
        try {
            let parsedMessage = WebRTCSignalling.deserializeBinary(new Uint8Array(message.data));
            this.emit("message", parsedMessage);
        } catch (e) {
            console.log("failed to parse WebRTCSignalling message: ", e);
        }
    }

    @action sendMessage(signalMsg: WebRTCSignalling) {
        if (this.connectionStatus === "OPEN") {
            this.connection.send((signalMsg.serializeBinary()))
        } else {
            // alert("Not connected to signalling server: "+ this.connectionStatus);
        }
        this.emit("signal", signalMsg);
    }

    @action updateConnectionStatus() {
        switch (this.connection.readyState) {
            case this.connection.CLOSED:
                this.connectionStatus = "CLOSED";
                break;
            case this.connection.CLOSING:
                this.connectionStatus = "CLOSING";
                break;
            case this.connection.OPEN:
                this.connectionStatus = "OPEN";
                break;
            case this.connection.CONNECTING:
                this.connectionStatus = "CONNECTING";
                break;
            default:
                this.connectionStatus = "UNKNOWN";
        }
    }

    @action forceReconnection() {
        this.connection.reconnect();
    }


    // These last 2 methods are mainly to allow the use of this class by the ManualSignallingServer
    @action emptySignallingMessages() {
        this.signallingMessages = "";
    }

    @action addSignallingMessage(signalMsg: WebRTCSignalling) {
        this.signallingMessages = this.signallingMessages + base64ArrayBuffer(signalMsg.serializeBinary()) + "\n";
    }

}