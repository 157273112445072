import React, {PropsWithChildren, Fragment} from "react";
import {inject, observer} from "mobx-react";
import StoreRoot from "../../../../stores/StoreRoot";
import {crc24} from "crc";
import Polygon from "../../../common/pixi/Polygon";
import {Text} from "react-pixi-fiber";
import * as PIXI from "pixi.js";

type OccupancyZoneProps = {
    appState: StoreRoot
    visionProgramId: number
    occupancyZoneId: number
}

const OccupancyZone: React.FunctionComponent<PropsWithChildren<OccupancyZoneProps>> = inject("appState")(observer((props: PropsWithChildren<OccupancyZoneProps>) => {
    const zonesForVisionProgram = props.appState.DetectorTrackerFrames.occupancyZoneDefinitions.get(props.visionProgramId);
    let polygon = null;
    if (zonesForVisionProgram && zonesForVisionProgram.has(props.occupancyZoneId)) {
        const zonePolygon = zonesForVisionProgram.get(props.occupancyZoneId);
        let occupancyCount = props.appState.DetectorTrackerFrames.latestOccupanciesByZoneID.get(props.occupancyZoneId);
        if (!occupancyCount) {
            occupancyCount = 0;
        }
        if (zonePolygon) {
            let x = 0;
            let y = 0;
            const zoneCentroids = props.appState.DetectorTrackerFrames.occupancyZoneCentroids.get(props.visionProgramId);
            if (zoneCentroids) {
                const centroid = zoneCentroids.get(props.occupancyZoneId);
                if (centroid) {
                    x = centroid[0];
                    y = centroid[1];
                }
            }

            polygon = (
                <Fragment>
                    <Polygon
                        points={zonePolygon}
                        fillColor={crc24(props.visionProgramId + "-" + props.occupancyZoneId)}
                        lineColor={0x000000}
                        lineWidth={2}
                        alpha={Math.min(0.25 + 0.05 * occupancyCount, 0.7)}
                        interactive
                        mousedown={() => {
                            // do something?
                        }}/>
                    <Text
                        text={occupancyCount.toString()}
                        x={x}
                        y={y}
                        style={new PIXI.TextStyle({fill: 0x333333, fontSize: 20})}
                    />
                </Fragment>
            );
        }
    }
    return (
        <Fragment>
            {polygon}
            {props.children}
        </Fragment>
    );
}));

export default OccupancyZone