import React from 'react'
import './App.css';
import {configure} from 'mobx';
import StoreRoot from "./stores/StoreRoot";
import {Provider} from 'mobx-react';
import {HashRouter, Route} from "react-router-dom";
import HomePage from "./HomePage";
import 'bootstrap/dist/css/bootstrap.min.css';

configure({
    enforceActions: 'observed'
});

const stores = new StoreRoot();
(window as any).stores = stores;

class App extends React.Component {
    render() {
        return (
            <Provider appState={stores}>
                <HashRouter>
                    <div>
                        <Route exact path="/" component={HomePage}/>
                    </div>
                </HashRouter>
            </Provider>
        );
    }
}

export default App;
