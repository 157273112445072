import { CustomPIXIComponent } from "react-pixi-fiber";
import * as PIXI from "pixi.js";

export interface CircleProperties extends Partial<PIXI.Graphics> {
    x: number;
    y: number;
    radius: number;
    fillColor?: number;
    lineColor: number;
    lineWidth: number;
}

export default CustomPIXIComponent({
    customDisplayObject: (props: CircleProperties) => new PIXI.Graphics(),
    customApplyProps: function(instance: PIXI.Graphics, oldProps: CircleProperties, newProps: CircleProperties) {
        const { x, y, radius, fillColor, lineColor, lineWidth, ...newPropsRest } = newProps;
        const { x: oldX, y: oldY, radius: oldRadius, fillColor: oldFill, lineColor: oldColor, lineWidth: oldWidth, ...oldPropsRest } = oldProps;
        if (oldProps !== undefined) {
            instance.clear();
        }

        if (fillColor){
            instance.beginFill(fillColor);
        }
        instance.lineStyle(lineWidth, lineColor);
        instance.drawCircle(x, y, radius);
        if(fillColor){
            instance.endFill();
        }
        // TODO - figure out how to fix this instead of ignoring
        // @ts-ignore
        this.applyDisplayObjectProps(oldPropsRest, newPropsRest);
    },
}, "Circle");