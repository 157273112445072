import React from "react";
import {inject, observer} from "mobx-react";
import {intercept} from "mobx";
import {Alert} from "reactstrap";
import StoreRoot from "../../stores/StoreRoot";
import { WebRTCError } from "../../stores/WebRTCPeerConnectionState";

export interface NotificationsPanelState {
    messages: {
        [id: string]: {color: string, text: string}
    }
}

@inject("appState")
@observer
class NotificationsPanel extends React.Component<{ appState: StoreRoot }, NotificationsPanelState> {
    constructor(props: {appState: StoreRoot}) {
        super(props);
        this.state = {messages: {}}
    }

    randomId = () => Math.random().toString(36).substr(2, 9);

    addNotification = (change: WebRTCError) => {
        const message = {
            text: `${change.type}: ${change.message}`,
            color: "danger"
        }
        const id = this.randomId();
        this.setState({ messages: {...this.state.messages, [id]: message}});
        setTimeout(() => {
            const messages = {...this.state.messages};
            delete messages[id];
            this.setState({ messages });
        }, 4000);
    }

    componentDidMount(){
        intercept(this.props.appState.RTC.globalErrors, (change: any) => {
            if (change?.added) {
                change.added.forEach(this.addNotification);
            }
            return change;
        });

        intercept(this.props.appState.RTC.remoteErrors, (change: any) => {
            if (!["remove", "delete"].includes(change.type) && change.newValue.length > 0) {
                const error: WebRTCError = change.newValue[change.newValue.length - 1];
                this.addNotification(error);
            }
            return change;
        });
    }


    render() {
        return (
            Object.entries(this.state.messages).map(([id, err]) => {
                return <Alert key={id} color={err.color}>{err.text}</Alert>
            })
        );
    }
};

export default NotificationsPanel;
