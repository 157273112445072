import React from "react";
import {inject, observer} from "mobx-react";
import StoreRoot from "../stores/StoreRoot";
import {
    Badge,
    Button,
    Col,
    Container, CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import Select from 'react-select';
import PeerConnectionDebugInfo from "../components/PeerConnectionDebugInfo";
import GStreamerPipelineControl from "../components/GStreamerPipelineControl";
import {PeerID, PrincipalID, VideoID, RemotePeer} from "../components/common/types";
import DetectorTrackerStage from "../components/DetectorTrackerStage";
import NotificationsPanel from "../components/common/NotificationPanel";
import _ from "lodash";
import { WebRTCError } from "../stores/WebRTCPeerConnectionState";

import qs from "qs";

type VideoSelectOption = {
    label: string
    value: string
    pipelineDefinition: string
}

export type DataChannelSelectOption = {
    label: string
    value: string
    chunkSize: number
    unordered: boolean
    maxPacketLifetimeMs: number
    maxRetransmits: number
    protocol: string
}

type State = {
    selectedVideos: VideoSelectOption[]
    selectedDataChannels: DataChannelSelectOption[]
    visionProgramIDs: string
}

const videoOptions: VideoSelectOption[] = [
    {
        value: 'raw_video0',
        label: 'Raw Video Out (default)',
        pipelineDefinition: "src_video0 ! video/x-raw,format=YUY2,width=640,height=360,framerate=0/1 ! videoconvert ! WEBRTC_ENC_H264 ! raw_video0"
    },
    {
        value: 'tracking_video0',
        label: 'Tracking Video Out (default)',
        pipelineDefinition: "v4l2src device=/dev/video101 ! video/x-raw,format=YUY2,width=640,height=360 ! videorate ! videoconvert ! video/x-raw,format=I420,framerate=10/1 ! x264enc bframes=0 speed-preset=veryfast key-int-max=60 threads=2 tune=zerolatency ! video/x-h264,stream-format=byte-stream ! tracking_video0"
    },
    {
        value: 'blurred_raw_video0',
        label: 'Blurred Raw Video Out',
        pipelineDefinition: ""
    },
    {
        value: 'blurred_tracking_video0',
        label: 'Blurred Tracking Video Out',
        pipelineDefinition: ""
    },
];

const dataChannelOptions: DataChannelSelectOption[] = [
    {
        value: 'detector_tracker_frames',
        chunkSize: 0,
        // Allow DTFs to arrive out of order, and give up sending after 3 lost frames (since the data will be old anyway)
        unordered: true,
        maxRetransmits: 3,
        maxPacketLifetimeMs: 0,
        protocol: "DetectorTrackerFrame",
        label: 'Detector Tracker Frames'
    },
    {
        label: 'GStreamer control',
        value: 'gstreamer_control',
        chunkSize: 0,
        // The order of these matters
        unordered: false,
        maxRetransmits: 0,
        maxPacketLifetimeMs: 0,
        protocol: "GStreamerControl",
    },
    {
        label: 'Snapshots',
        value: 'snapshot',
        chunkSize: 8000,
        // The order of these matters
        unordered: true,
        maxRetransmits: 10,
        maxPacketLifetimeMs: 0,
        protocol: "WebRTCDataChannelDataChunk",
    },
];

@inject("appState")
@observer
class HomePage extends React.Component<{ appState: StoreRoot }, State> {

    constructor(props: { appState: StoreRoot }) {
        super(props);
        this.state = {
            selectedVideos: [],
            selectedDataChannels: [dataChannelOptions[0], dataChannelOptions[2]],
            visionProgramIDs: ""
        }
        this.props.appState.RTC.on("peer-connection-failure", (principalID: PrincipalID, peerID: PeerID) => {
            let remotePeerSettings = this.props.appState.RemotePeers.get(principalID);
            if (!remotePeerSettings) {
               remotePeerSettings = {
                   desiredDataChannels: this.state.selectedDataChannels,
                   desiredVideoTracks: this.state.selectedVideos.map(selected => selected.value)
               }
            }
            this.props.appState.RTC.sendRequestPeerConnectionMessage(principalID, remotePeerSettings.desiredVideoTracks, remotePeerSettings.desiredDataChannels);
        });
        this.props.appState.RTC.on("peer-connection-permanent-failure", (principalID: PrincipalID, peerID: PeerID) => {
            const msg = `Connection to ${principalID} is unavailable or too unstable and has been removed; try using snapshots or a lower quality video feed`;
            const error: WebRTCError = {
                type: "PEER_OFFLINE_OR_UNSTABLE",
                message: msg,
                label: ""
            }
            this.props.appState.RTC.globalErrors.push(error);
            this.props.appState.RemotePeers.delete(principalID);
            this.props.appState.RTC.currentPeerIdByPrincipalId.delete(principalID);
        });

    }

    handleSelectDataChannelChange = (selectedOption: any) => {
        if (selectedOption === null) {
            selectedOption = [];
        }
        this.setState(
            {selectedDataChannels: selectedOption},
        );
    };

    handleSelectMultiVideoChange = (selectedOption: any) => {
        if (selectedOption === null) {
            selectedOption = [];
        }
        this.setState(
            {selectedVideos: selectedOption},
        );
    };

    componentDidMount(){
        const search = window.location.search;
        var query = qs.parse(search, { ignoreQueryPrefix: true });
        const vpids = query.vpids || "";
        this.setState(
            { visionProgramIDs: vpids as string}
        );
    }

    render() {
        const zoomableStages: JSX.Element[] = [];
        const activePrincipalIds = [...this.props.appState.RTC.currentPeerIdByPrincipalId.keys()];
        activePrincipalIds.sort().forEach(principalId => {
            const RtcPeerId = this.props.appState.RTC.currentPeerIdByPrincipalId.get(principalId);
            if (!RtcPeerId)
                return;
            const peerConn = this.props.appState.RTC.peerConnections.get(RtcPeerId);
            if (!peerConn)
                return;

            let hasStream = false
            let hasDTF = false
            let hasSnappi = false
            let visionProgramID = -RtcPeerId // negative PeerID (to ensure no clashes with real VPID)
            const videoIDs: VideoID[] = [];
            
            this.props.appState.RTC.streams.forEach((peerStreams, streamPeerID) => {
                if (RtcPeerId === streamPeerID) {
                    peerStreams.forEach((mediaStreams) => {
                        mediaStreams.forEach((mediaStream) => {
                            videoIDs.push(new VideoID(RtcPeerId, mediaStream.id));
                        });
                    });
                    hasStream = true
                }
            })
            this.props.appState.DetectorTrackerFrames.detectorTrackerChannels.forEach((peerStreams, dtfChannelPeerID) => {
                if (RtcPeerId === dtfChannelPeerID) {
                    let maybeVisionProgramID = this.props.appState.DetectorTrackerFrames.visionProgramIDsByPeerID.get(RtcPeerId);
                    if (maybeVisionProgramID === undefined) {
                        maybeVisionProgramID = -RtcPeerId // negative PeerID (to ensure no clashes with real VPID)
                    }
                    visionProgramID = maybeVisionProgramID
                    hasDTF = true
                }
            })
            this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.forEach((peerStreams, snapshotChannelPeerID) => {
                if (RtcPeerId === snapshotChannelPeerID) {
                    hasSnappi = true
                }
            })

            if (this.props.appState.DetectorTrackerFrames.visionProgramIDsByPeerID.get(RtcPeerId) === undefined) {
                this.props.appState.DetectorTrackerFrames.visionProgramIDsByPeerID.set(RtcPeerId, visionProgramID);
                this.props.appState.DetectorTrackerFrames.peerIDsByVisionProgramID.set(visionProgramID, RtcPeerId);
            }

            if (hasStream || hasDTF || hasSnappi) {
                zoomableStages.push((
                    <DetectorTrackerStage
                        key={RtcPeerId}
                        availableVideoIDs={videoIDs.filter((videoID) => videoID.PeerConnectionID === RtcPeerId)}
                        appState={this.props.appState}
                        visionProgramID={visionProgramID}
                        width={640}
                        height={360}
                        stageWidth={this.props.appState.DetectorTrackerFrames.compactMode ? 500 : undefined}
                        stageHeight={this.props.appState.DetectorTrackerFrames.compactMode ? 282 : undefined}
                        showControls={this.props.appState.DetectorTrackerFrames.showControls}
                    />
                ));
            }
        });

        let stages;
        if (!this.props.appState.DetectorTrackerFrames.compactMode) {
            stages = (
                <Row >
                    <Col>
                        {zoomableStages.map(s => s)}
                    </Col>
                </Row>
            );
        } else {
            const chunks = _.chunk(zoomableStages.map(s => s), 2)
            let rowIndex = 0;
            let colIndex = 0;
            stages = chunks.map(chunk => {
                return (
                    <Row key={"row-index-"+rowIndex++}>
                        {chunk.map(s => (<Col key={"col-index-"+colIndex++}>{s}</Col>))}
                    </Row>
                );
            })
        }

        const pipelineControls: JSX.Element[] = [];

        const debugInfo: JSX.Element[] = [];

        this.props.appState.RTC.peerConnections.forEach((peerConn, peerId) => {
            const allPeerDataChannels = this.props.appState.RTC.dataChannels.get(peerId);
            if (allPeerDataChannels === undefined) {
                return
            }

            const dataChannel = allPeerDataChannels.get("gstreamer_control");
            if (dataChannel !== undefined && this.props.appState.SignallingServerConnection.jwtName !== "tfgm-guest" && this.props.appState.SignallingServerConnection.jwtName !== "demo-guest") {
                this.state.selectedVideos.forEach(({label, value, pipelineDefinition}) => {
                    pipelineControls.push(<GStreamerPipelineControl
                        initialPipelineDef={pipelineDefinition}
                        dataChannel={dataChannel} pipelineId={label} key={peerId + "-" + value} peerId={peerId}
                        appState={this.props.appState}/>);
                });

                // Show the GStreamer control for the input feed
                pipelineControls.push(<GStreamerPipelineControl
                    initialPipelineDef={"rtmpsrc location=rtmp://fms.105.net/live/rmc1 ! decodebin ! videoscale ! videoconvert ! videorate ! video/x-raw,format=YUY2, width=640, height=360 ! tee name=t ! queue ! v4l2sink device=/dev/video100 t. ! src_video_sink0 \n\n CHOOSE ONE OF THESE \n\n filesrc location=video.mp4 ! decodebin ! videoconvert ! videorate ! tee ! videoscale ! video/x-raw,format=YUY2, width=640, height=360 ! tee name=t ! queue ! tee ! v4l2sink device=/dev/video100  t. ! queue ! src_video_sink0"}
                    dataChannel={dataChannel} pipelineId={"Video Feed In"} key={peerId + "-video-feed-in"}
                    peerId={peerId}
                    appState={this.props.appState}/>);
            }

            if (this.props.appState.SignallingServerConnection.jwtName !== "tfgm-guest" && this.props.appState.SignallingServerConnection.jwtName !== "demo-guest") {
                debugInfo.push(<PeerConnectionDebugInfo key={peerId} peerId={peerId} appState={this.props.appState}/>);
            }

        });


        const peerConnectionStatuses = debugInfo.map(c => c);

        const peerConnectionPipelineControls = pipelineControls.map(p => p);

        const signallingStatus = this.props.appState.SignallingServerConnection.connectionStatus !== "OPEN" ? <Badge
            color={"danger"}>Signalling {this.props.appState.SignallingServerConnection.connectionStatus} </Badge> : null;

        return (
            <>
                <div style={{position: "fixed", top: 0, left: 0, width: "100%", maxWidth: "100%", height: "125px", background: "rgba(255, 255, 255, 0.9)", zIndex: 2}}>
                    <Container>
                        <Row style={{padding: "30px"}}>
                            <Col sm={6} md={8} lg={9}>
                                <NotificationsPanel appState={this.props.appState} />
                            </Col>
                            <Col sm={6} md={4} lg={3} className="d-flex flex-column align-content-end">
                                <CustomInput
                                    type="switch"
                                    id={"compactModeSwitch"}
                                    name="compactModeSwitch"
                                    label="Compact Mode"
                                    defaultChecked
                                    onChange={(e) => {
                                        this.props.appState.DetectorTrackerFrames.setCompactMode(!this.props.appState.DetectorTrackerFrames.compactMode);
                                    }}
                                />
                                <CustomInput
                                    type="switch"
                                    id={"showControlsSwitch"}
                                    name="showControlsSwitch"
                                    label="Show Controls"
                                    onChange={(e) => {
                                        this.props.appState.DetectorTrackerFrames.setShowControls(!this.props.appState.DetectorTrackerFrames.showControls);
                                    }}
                                />
                                <CustomInput
                                    type="switch"
                                    id={"autoReconnectSwitch"}
                                    name="autoReconnectSwitch"
                                    label="Auto-reconnect"
                                    onChange={(e) => {
                                        this.props.appState.RTC.setAutoReconnectEnabled(!this.props.appState.RTC.autoReconnectEnabled);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container style={{marginTop: "125px"}}>
                <Row>
                    <Col>
                        <Form>
                            <FormGroup>
                                <Label>
                                    JWT
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>JWT</InputGroupText>
                                        </InputGroupAddon>
                                        <Input type={"textarea"}
                                               value={this.props.appState.SignallingServerConnection.jwt}
                                               onChange={(e) => {
                                                   this.props.appState.SignallingServerConnection.setJwt(e.target.value);
                                               }} style={{width: "400px"}}/>
                                    </InputGroup>
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    <span>Vision Program IDs</span>
                                    <small> (Multiple values can be comma-separated)</small>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>ID</InputGroupText>
                                        </InputGroupAddon>
                                        <Input value={this.state.visionProgramIDs} onChange={(e) => {
                                            this.setState(
                                                {visionProgramIDs: e.target.value}
                                            );
                                        }} style={{width: "400px"}}/>
                                    </InputGroup>
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                Data Channels
                                <Select
                                    isMulti={true}
                                    value={this.state.selectedDataChannels}
                                    onChange={this.handleSelectDataChannelChange}
                                    options={dataChannelOptions}
                                    placeholder={"Select Data Channels"}
                                />

                                Video Tracks
                                <Select
                                    isMulti={true}
                                    value={this.state.selectedVideos}
                                    onChange={this.handleSelectMultiVideoChange}
                                    options={videoOptions}
                                    placeholder={"Select Videos"}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button onClick={() => {
                                    this.state.visionProgramIDs.split(",").forEach(visionProgramID => {
                                        visionProgramID = visionProgramID.trim()
                                        if (visionProgramID !== "") {
                                            const selectedVideoTracks = this.state.selectedVideos.map(selected => selected.value);
                                            const remotePeer: RemotePeer = {
                                                desiredDataChannels: this.state.selectedDataChannels,
                                                desiredVideoTracks: selectedVideoTracks
                                            }
                                            this.props.appState.RemotePeers.set(visionProgramID, remotePeer)
                                            this.props.appState.RTC.sendRequestPeerConnectionMessage(visionProgramID, selectedVideoTracks, this.state.selectedDataChannels);
                                        }
                                    })
                                }}
                                    disabled={this.props.appState.SignallingServerConnection.connectionStatus !== "OPEN"}
                                >
                                    Request Connection
                                    <br/>
                                    {signallingStatus}
                                </Button>
                            </FormGroup>
                        </Form>

                    </Col>

                </Row>
                <Row>
                    <Col>
                        {stages}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {peerConnectionPipelineControls}
                        {peerConnectionStatuses}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}

export default HomePage;
