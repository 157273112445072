import { CustomPIXIComponent } from "react-pixi-fiber";
import * as PIXI from "pixi.js";

export interface PolygonProperties extends Partial<PIXI.Graphics> {
    points: number[];
    fillColor?: number;
    lineColor: number;
    lineWidth: number;
}

export default CustomPIXIComponent({
    customDisplayObject: (props: PolygonProperties) => new PIXI.Graphics(),
    customApplyProps: function(instance: PIXI.Graphics, oldProps: PolygonProperties, newProps: PolygonProperties) {
        const { points, fillColor, lineColor, lineWidth, ...newPropsRest } = newProps;
        const { points: oldPoints, fillColor: oldFill, lineColor: oldLineColour, lineWidth: oldLineWidth, ...oldPropsRest } = oldProps;
        if (oldProps !== undefined) {
            instance.clear();
        }

        if (fillColor){
            instance.beginFill(fillColor);
        }
        instance.lineStyle(lineWidth, lineColor);
        instance.drawPolygon(points);
        if(fillColor){
            instance.endFill();
        }

        // TODO - figure out how to fix this instead of ignoring
        // @ts-ignore
        this.applyDisplayObjectProps(oldPropsRest, newPropsRest);
    },
}, "Polygon");